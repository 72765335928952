import React from 'react';
import cx from 'classnames';

import LoadingSpinner from '../../forms/LoadingSpinner/LoadingSpinner';

import styles from './PageLoader.scss';

type PageLoaderProps = {
  className?: string;
  hideLabel?: boolean;
  message?: string;
  showFooter?: 'full' | 'none';
}

const PageLoader = ({ className = '', hideLabel = false, message = '', showFooter }: PageLoaderProps) => (
  <div className={cx(styles.container, className, {
    [styles.contentHeight]: showFooter === 'full',
  })} data-testid="PageLoader">
    {/* @ts-expect-error */}
    <LoadingSpinner />
    { hideLabel || !message
      ? null
      : <h2>{message}</h2>}
  </div>
);

export default PageLoader;
