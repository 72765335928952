import React from 'react';
import cx from 'classnames';

import styles from './LogoPress.scss';

type Props = {
  publication: string,
}

const LogoPress:React.FunctionComponent<Props> = ({
  publication,
}) => {
  const classNames = cx(
    styles.logoPress,
    styles[publication],
  );

  return (
    <div className={classNames} />
  );
};

export default LogoPress;
