import React from 'react';
import { Link } from 'react-router-dom';

import { MDText } from 'i18n-react';

import { RedWarningTriangleIcon } from '@motorway/motorway-storybook-cra';
import { Button } from '@motorway/mw-highway-code';
import { BUTTON_AS_TYPE, SIZE } from '@motorway/mw-highway-code/enums';

import { VRM_CHECK_CODES } from 'Utilities/vrm';

import LocalTexts from './VrmErrorsText.json';

import styles from './VrmErrors.scss';

const LocalT = new MDText(LocalTexts);

type VrmErrorsProps = {
  error: string;
};

const VrmErrors = ({ error }: VrmErrorsProps) => {
  let title;
  let details;
  let additional = null;

  const refreshButton = (
    <Button
      data-thc-refresh-button
      fullWidth
      as={BUTTON_AS_TYPE.BUTTON}
      label={ LocalT.translate('refresh') }
      onClick={(e: React.MouseEvent) => {
        e.preventDefault();
        window.location.reload();
      }}
    />
  );

  switch (error) {
    case VRM_CHECK_CODES.TOO_MANY_REQUESTS: {
      title = LocalT.translate('too_many_requests.title');
      details = LocalT.translate('too_many_requests.detail');
      break;
    }

    case VRM_CHECK_CODES.VEHICLE_LOOKUP_FAILED: {
      title = LocalT.translate('vehicle_lookup_failed.title');
      details = LocalT.translate('vehicle_lookup_failed.detail');
      break;
    }

    case VRM_CHECK_CODES.NOT_AVAILABLE: {
      title = LocalT.translate('not_available.title');
      details = LocalT.translate('not_available.detail');
      break;
    }

    case VRM_CHECK_CODES.COMPLIANCE_SERVICE_ERROR: {
      title = LocalT.translate('compliance_service_error.title');
      details = LocalT.translate('compliance_service_error.detail');
      break;
    }

    case VRM_CHECK_CODES.EXCEEDED: {
      title = LocalT.translate('exceeded.title');
      details = LocalT.translate('exceeded.detail', {
        line: (
          <span className={styles.secondLine}>
            {LocalT.translate('exceeded.secondLine')}
          </span>
        ),
      });
      additional = (
        <Button
          data-thc-additional-button
          fullWidth
          as={Link}
          icon='chevron'
          label={ LocalT.translate('exceeded.goToAccount') }
          size={SIZE.LARGE}
          to='/account'
        />
      );
      break;
    }

    case VRM_CHECK_CODES.BAD_REQUEST: {
      title = LocalT.translate('bad_request.title');
      details = LocalT.translate('bad_request.detail');

      additional = refreshButton;
      break;
    }

    case VRM_CHECK_CODES.VEHICLE_GENERATION_FAILURE: {
      title = LocalT.translate('vehicle_generation_failure.title');
      details = LocalT.translate('vehicle_generation_failure.detail');

      additional = refreshButton;
      break;
    }

    default: {
      title = LocalT.translate('default.title');
      details = LocalT.translate('default.detail');
    }
  }

  return (
    <div className={styles.component}>
      <RedWarningTriangleIcon />
      <h2>{title}</h2>
      <p>{details}</p>
      { additional }
    </div>
  );
};

export default VrmErrors;
