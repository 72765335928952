export const toggleZendeskVisibility = (zendeskWidgetState) => {
  setTimeout(() => {
    try {
      globalThis?.zE?.('messenger', zendeskWidgetState?.show ? 'show' : 'hide');
      if (!zendeskWidgetState?.show) {
        globalThis?.zE?.('messenger', 'close');
      }
    } catch (e) {
      globalThis?.Sentry?.captureException?.(new Error(e));
    }
  }, 0);
};
