/* eslint-disable import/prefer-default-export */
import dayjs from 'dayjs';

export const excludeMileagePath = (url) => url.split('/').filter((it) => (it && it !== 'mileage'));

export const getURLPath = (str) => {
  const URLPathRegex = /http[s]*:\/\/[^/]+(\/.+)/;
  const matches = str.match(URLPathRegex);
  return matches[1];
};

export const isBlogURL = (url) => ((/^\/blog\/[0-9]{4}/).test(url));

export const isGuidesURL = (url) => ((/^\/sell-my-car\/guides\/(.*)/).test(url));

export const replaceHyphensWithSpace = (str) => str.replace(/-/g, ' ');

export const upperCaseString = (str = '') => str.charAt(0).toUpperCase() + str.slice(1);

export const getBlogTitle = (path) => {
  let blogPathName = 'Blog';
  const { params: { day, month, year } } = path;

  const formatDate = (date, end, pad) => {
    date = +date;
    const returnValue = (pad) ? '01' : null;
    return (!Number.isNaN(date) && date > 0 && date <= end) ? date.toString().padStart(2, 0) : returnValue;
  };

  const date = dayjs.utc([
    year || dayjs().year(),
    formatDate(month, 12, true), formatDate(day, 31, true),
  ].join('-'));

  if (year && !month) {
    blogPathName = year;
  } else if (month && !day) {
    blogPathName = date.format('MMMM');
  } else if (month && day) {
    blogPathName = date.format('dddd Do');
  }

  return blogPathName;
};
