/* eslint-disable no-console */
import React from 'react';
import { withRouter } from 'react-router';
import ReactRouterPropTypes from 'react-router-prop-types';
import PropTypes from 'prop-types';

import Status500 from './500';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);

    this.state = { hasError: props.forceShow || false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidUpdate(prevProps) {
    const { location: prevLocation } = prevProps;
    const { location } = this.props;

    if (prevLocation.pathname !== location.pathname) {
      this.setState({ hasError: false }); // eslint-disable-line react/no-did-update-set-state
    }
  }

  static componentDidCatch(error, info) {
    const { componentStack } = info;
    console.error(error);
    console.groupCollapsed('Component stack');
    console.log(info);
    console.groupEnd();
    window?.Sentry?.captureException?.(error, { contexts: { react: { componentStack } } });
  }

  render() {
    const { children, fallbackView = <Status500 /> } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return fallbackView;
    }
    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.element.isRequired,
  fallbackView: PropTypes.node,
  forceShow: PropTypes.bool,
  location: ReactRouterPropTypes.location.isRequired,
};

export default withRouter(ErrorBoundary);
