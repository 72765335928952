/* eslint-disable import/prefer-default-export */
import PropTypes from 'prop-types';

// This are the types of props we can pass to cx
export const classNamePropType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.object,
]);

export const vehiclePropType = PropTypes.shape({
  body: PropTypes.string,
  bodyCategory: PropTypes.string,
  colour: PropTypes.string,
  createdAt: PropTypes.string,
  engine_size: PropTypes.number,
  enquiry: PropTypes.shape({
    id: PropTypes.number,
    marketplaceEligible: PropTypes.shape({
      eligible: PropTypes.bool,
      ineligibleBy: PropTypes.string,
    }),
    mileage: PropTypes.number,
    status: PropTypes.string,
    updatedAt: PropTypes.string,
    vehicle_removed: PropTypes.bool,
    // eslint-disable-next-line react/sort-prop-types
    vehicleId: PropTypes.number,
  }),
  expired: PropTypes.bool,
  firstRegistered: PropTypes.string,
  fuel: PropTypes.string,
  height: PropTypes.number,
  id: PropTypes.number,
  keeperStartDate: PropTypes.string,
  last_check: PropTypes.string,
  last_mot_date: PropTypes.string,
  make: PropTypes.shape({
    createdAt: PropTypes.string,
    display_name: PropTypes.string,
    id: PropTypes.number,
    major: PropTypes.number,
    map_id: PropTypes.string,
    slug: PropTypes.string,
    updatedAt: PropTypes.string,
  }),
  makeId: PropTypes.number,
  manufactureYear: PropTypes.number,
  mileageEst: PropTypes.number,
  mileageLastMot: PropTypes.number,
  model: PropTypes.string,
  modelId: PropTypes.number,
  numberOfDoors: PropTypes.number,
  numberOfSeats: PropTypes.number,
  previousKeepersCount: PropTypes.number,
  transmission: PropTypes.string,
  updatedAt: PropTypes.string,
  vin: PropTypes.string,
  vrm: PropTypes.string,
  vrm_pretty: PropTypes.string,
  year: PropTypes.number,
});

const vehicleActionsPropType = PropTypes.shape({
  add: PropTypes.func,
  remove: PropTypes.func,
  replace: PropTypes.func,
});

const vehicleLookupActionsPropType = PropTypes.shape({
  add: PropTypes.func,
  remove: PropTypes.func,
  update: PropTypes.func,
});

export const vehicleLookupContextPropType = PropTypes.shape({
  vehicleLookupActions: vehicleLookupActionsPropType,
  vehicleLookupState: PropTypes.instanceOf(Object),
});

export const vehicleContextPropType = PropTypes.shape({
  vehicleActions: vehicleActionsPropType,
  vehicleState: PropTypes.arrayOf(vehiclePropType),
});

export const childrenPropType = PropTypes.oneOfType([
  PropTypes.object,
  PropTypes.node,
]);
