/* eslint-disable react/jsx-sort-default-props */
/* eslint-disable quote-props */
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import {
  AddIcon,
  ArrowRightIcon,
  CallClassicIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  LoadingIcon, LoadingSpinner,
  RestartIcon, TickFilledIcon,
  UploadIcon,
  WarningTriangle,
} from '@motorway/motorway-storybook-cra';

import RotateLeft from './icons/RotateLeft';
import ZoomOut from './icons/ZoomOut';

const ICON_MAP = {
  'arrow-right': ArrowRightIcon,
  'call': CallClassicIcon,
  'chevron-left': ChevronLeftIcon,
  'chevron-right': ChevronRightIcon,
  'error': WarningTriangle,
  'filled': TickFilledIcon,
  'loading': LoadingSpinner,
  'rotate-left': RotateLeft,
  'rotate-right': RestartIcon,
  'spinner': LoadingIcon,
  'upload': UploadIcon,
  'zoom-in': AddIcon,
  'zoom-out': ZoomOut,
};

const ButtonInnerWrapper = ({ children }) => children;

// eslint-disable-next-line react/prefer-stateless-function
class Button extends React.Component {
  render() {
    let {
      children,
      className = '',
      element,
      icon = '',
      iconColour = 'white',
      InnerWrapper = ButtonInnerWrapper,
      rightIcon = '',
      theme = null,
      ...p
    } = this.props;
    // eslint-disable-next-line react/destructuring-assignment
    theme = this.props.theme || this.context.theme;

    const Element = element || 'button';

    const IconLeft = ICON_MAP[icon];
    const IconRight = ICON_MAP[rightIcon];
    const buttonClass = cx(
      'mw-button',
      `mw-theme-${theme}`,
      { 'mw-icon-loading': icon === 'loading' },
      className,
    );

    return (
      <Element className={buttonClass} {...p}>
        <InnerWrapper>
          {IconLeft && (
            <IconLeft className={cx('mw-icon-left', `mw-icon-${icon}`, `mw-icon-fill-${iconColour}`)} />
          )}
          <span>{children}</span>
          {IconRight && (
            <IconRight className={cx('mw-icon-right', `mw-icon-${rightIcon}`, `mw-icon-fill-${iconColour}`)} />
          )}
        </InnerWrapper>
      </Element>
    );
  }
}

Button.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string,
  // eslint-disable-next-line react/sort-prop-types
  InnerWrapper: PropTypes.func,
  rightIcon: PropTypes.string,
  theme: PropTypes.string,
};

export default Button;
