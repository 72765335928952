import { useContext, useEffect, useRef } from 'react';

import { UserContext } from 'Context/user';
import { VehicleLookupContext } from 'Context/vehicleLookup';

import { SNOWPLOW_USER_CUSTOMER_CONTEXT, snowplowEvent } from 'Utilities/analytics';

export const useSnowplow = () => {
  const { vehicleLookupState: vehicle } = useContext(VehicleLookupContext);
  const { userState: user } = useContext(UserContext);

  const tokenLogin = useRef(new URLSearchParams(globalThis?.location?.search).get('token'));

  const { emailCrmTier, id } = user || {};

  useEffect(() => {
    if (Number.isInteger(user?.id)) {
      SNOWPLOW_USER_CUSTOMER_CONTEXT({
        emailCrmTier,
        id,
        login_persisted: tokenLogin.current,
      });
    }
  }, [vehicle?.enquiry?.id, emailCrmTier, id]);

  useEffect(() => {
    try {
      const observer = new PerformanceObserver((list) => {
        list.getEntries().forEach((entry) => {
          if (entry.loadEventEnd) {
            observer.disconnect();

            snowplowEvent({
              data: entry.toJSON(),
              schema: 'page_timing',
              version: '1-0-1',
            });
          }
        });
      });

      // Adding the `buffered: true` option will prevent safari from firing subsequent events
      observer.observe({ type: 'navigation' });

      return () => observer.disconnect();
    } catch (err) {
      window?.Sentry?.captureException?.(new Error(err), {
        level: 'warning',
      });
    }

    return () => {};
  }, []);
};
