import React from 'react';
import universal from 'react-universal-component';

const options = {
  ignoreBabelRename: true,
  loadingTransition: false,
  render: (props, Component) => {
    if (Component) { return (<Component {...props} />); }
    return (<svg width="176" height="56" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 176 56" {...props} />);
  },
};

export const CarTakeBackLogo = universal(() => import(/* webpackChunkName: 'CarTakeBackLogo' */ './CarTakeBackLogo'), options);
export const TheCarBuyingGroupLogo = universal(() => import(/* webpackChunkName: 'TheCarBuyingGroupLogo' */ './TheCarBuyingGroupLogo'), options);
