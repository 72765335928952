import React from 'react';

const RotateLeftSVG = (props) => (
  <svg height={24} viewBox="0 0 24 24" width={24} {...props}>
    <defs>
      <path
        d="M11.5 22C6.262 22 2 17.915 2 12.893a.661.661 0 0 1 .345-.585.721.721 0 0 1 .7 0 .661.661 0 0 1 .345.585c0 4.302 3.623 7.774 8.11 7.774 4.487 0 8.11-3.472 8.11-7.774 0-4.301-3.623-7.774-8.11-7.774a8.295 8.295 0 0 0-5.163 1.777h3.541a.711.711 0 0 1 .504.193c.133.127.208.3.206.48a.654.654 0 0 1-.212.472.712.712 0 0 1-.498.188H4.781c-.384 0-.696-.299-.696-.667V2.676a.661.661 0 0 1 .345-.585.721.721 0 0 1 .701 0 .661.661 0 0 1 .345.585v3.179A9.711 9.711 0 0 1 11.5 3.786c5.238 0 9.5 4.086 9.5 9.107C21 17.915 16.738 22 11.5 22z"
        id="RotateLeft_svg__a"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask fill="#fff" id="RotateLeft_svg__b">
        <use xlinkHref="#RotateLeft_svg__a" />
      </mask>
      <use
        fill="#1D1D1B"
        stroke="#1D1D1B"
        strokeWidth={0.578}
        xlinkHref="#RotateLeft_svg__a"
      />
      <g fill="#1D1D1B" mask="url(#RotateLeft_svg__b)">
        <path d="M0 0h24v24H0z" />
      </g>
    </g>
  </svg>
);

export default RotateLeftSVG;
