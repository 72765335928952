import { GAEvent } from './analytics';

const VALUE_TRACKER_PAGES = Object.freeze({
  MY_CARS: 'Valuation tracker my cars',
  RESULTS: 'Valuation tracker results',
  VALUE_TRACKER: 'Valuation tracker',
});

// eslint-disable-next-line import/prefer-default-export
export const VALUE_TRACKER_GA_TAGS = Object.freeze({
  TRACKER_ALERT_CLICKED: () => GAEvent({
    eventAction: 'Monthly Alerts CTA Clicked',
    eventCategory: VALUE_TRACKER_PAGES.RESULTS,
    eventLabel: 'alert CTA subscribe',
  }),

  TRACKER_BANNER_ALERT_CTA_CLICKED: (action) => GAEvent({
    eventAction: `${action} CTA clicked`,
    eventCategory: 'Tracker Monthly alert',
    eventLabel: 'Tracker Large banner signup',
  }),

  TRACKER_FIRST_VALUATION_CREATED: (trackerValuationId, userVehicleTrackerId) => GAEvent({
    eventAction: 'Tracker valuation created',
    eventCategory: VALUE_TRACKER_PAGES.VALUE_TRACKER,
    eventLabel: 'first valuation',
    tracker_valuation_id: trackerValuationId,
    user_vehicle_tracker_id: userVehicleTrackerId,
  }, true),

  TRACKER_FLOATING_SELL_CTA_CLICKED: (action) => GAEvent({
    eventAction: `${action} CTA clicked`,
    eventCategory: VALUE_TRACKER_PAGES.RESULTS,
    eventLabel: 'sell car floating CTA',
  }),

  TRACKER_INSIGHTS_CAROUSEL: (title) => GAEvent({
    eventAction: 'CVT insights carousel clicked',
    eventCategory: VALUE_TRACKER_PAGES.RESULTS,
    eventLabel: title,
  }),

  TRACKER_INSIGHTS_LOAD: (title) => GAEvent({
    eventAction: 'CVT insight loaded',
    eventCategory: VALUE_TRACKER_PAGES.RESULTS,
    eventLabel: title,
  }),

  TRACKER_MY_CARS_MILEAGE_SUBMITTED: () => GAEvent({
    eventAction: 'Mileage submitted',
    eventCategory: VALUE_TRACKER_PAGES.MY_CARS,
    eventLabel: 'submit mileage',
  }),

  TRACKER_REPEAT_VALUATION_CREATED: (trackerValuationId, userVehicleTrackerId) => GAEvent({
    eventAction: 'Tracker valuation created',
    eventCategory: VALUE_TRACKER_PAGES.VALUE_TRACKER,
    eventLabel: 'repeat valuation',
    tracker_valuation_id: trackerValuationId,
    user_vehicle_tracker_id: userVehicleTrackerId,
  }, true),

  TRACKER_SELL_CTA_CLICKED: (action) => GAEvent({
    eventAction: `${action} CTA clicked`,
    eventCategory: VALUE_TRACKER_PAGES.RESULTS,
    eventLabel: 'sell car CTA',
  }),

  TRACKER_SHARE_CLICKED: () => GAEvent({
    eventAction: 'Tracker share button clicked',
    eventCategory: VALUE_TRACKER_PAGES.RESULTS,
    eventLabel: 'share CVT page',
  }),

  TRACKER_SHARE_SOCIAL_PLATFORM_CLICKED: (platform) => GAEvent({
    eventAction: `${platform} share button clicked`,
    eventCategory: VALUE_TRACKER_PAGES.RESULTS,
    eventLabel: `${platform} share on CVT page`,
  }),
});
