import { CTA_CLICK_EVENT_PAYLOAD, GENERIC_ANALYTICS_EVENTS } from 'Utilities/analytics/analyticsGenericEvents';

export const CVT_CTA_LABEL = {
  FLOATING: 'floating_cta',
  MAIN: 'main_cta',
};

type ctaClickedProps = {
  label: CTA_CLICK_EVENT_PAYLOAD['label'];
  url: CTA_CLICK_EVENT_PAYLOAD['url'];
};

type valuationCreatedEventProps = {
  isApiSuccess: boolean;
  trackerValuationId: number;
};

const CVT_CATEGORIES = {
  RESULTS: 'cvt_results',
  TRACKER: 'cvt',
};

const CTA_NAMES = {
  CAROUSEL: 'insight_carousel',
  INSIGHT: 'insight',
  SELL: 'start_selling',
  VRM: 'vrm_submit',
};

export const CVT_EVENTS = {
  CVT_INSIGHT_CAROUSEL_CLICKED: ({ label }: {label: string}) =>
    GENERIC_ANALYTICS_EVENTS.CLICK_EVENT({
      category: CVT_CATEGORIES.RESULTS,
      label,
      name: CTA_NAMES.CAROUSEL,
    }),
  CVT_INSIGHT_LOADED: ({ label }: {label: string}) =>
    GENERIC_ANALYTICS_EVENTS.SECTION_LOAD_EVENT({
      category: CVT_CATEGORIES.RESULTS,
      label,
      name: CTA_NAMES.INSIGHT,
    }),
  MILEAGE_CTA_CLICKED: ({ vrm }: {vrm: string}) =>
    GENERIC_ANALYTICS_EVENTS.CTA_CLICK({
      button_label: 'Confirm mileage',
      category: CVT_CATEGORIES.TRACKER,
      name: 'mileage_submit',
      url: `/car-value-tracker/${vrm}`,
    }),
  MILEAGE_INPUT_FOCUSED: () =>
    GENERIC_ANALYTICS_EVENTS.CLICK_EVENT({
      category: CVT_CATEGORIES.TRACKER,
      name: 'mileage_input',
    }),
  REFERRAL_BANNER_CLICKED: (url: string) => GENERIC_ANALYTICS_EVENTS.CLICK_EVENT({
    category: CVT_CATEGORIES.RESULTS,
    name: 'refer_friends',
    url,
  }),
  START_SELLING_CTA_CLICKED: ({ label, url }: ctaClickedProps) =>
    GENERIC_ANALYTICS_EVENTS.CTA_CLICK({
      button_label: 'Start your profile',
      category: CVT_CATEGORIES.RESULTS,
      label,
      name: CTA_NAMES.SELL,
      url,
    }),
  TRACKER_VALUATION_CREATED: ({ isApiSuccess, trackerValuationId }: valuationCreatedEventProps) =>
    GENERIC_ANALYTICS_EVENTS.API_TRIGGERED({
      action: 'create',
      backend_entity: 'tracker_valuation',
      backend_service: 'N/A',
      success: isApiSuccess,
      value: trackerValuationId,
    }),
  VRM_CTA_CLICKED: () =>
    GENERIC_ANALYTICS_EVENTS.CTA_CLICK({
      button_label: 'Value my car',
      category: CVT_CATEGORIES.TRACKER,
      name: CTA_NAMES.VRM,
      url: '/car-value-tracker/mileage',
    }),
  VRM_INPUT_FOCUSED: () =>
    GENERIC_ANALYTICS_EVENTS.CLICK_EVENT({
      category: CVT_CATEGORIES.TRACKER,
      name: 'vrm_input',
    }),
};
