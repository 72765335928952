import { matchPath } from 'react-router';

import VRM from '@motorway/lib-vrm-node';

import {
  FULL_LANDING_PAGE_ROUTES,
  LANDING_PAGE_ROUTES,
  MOT_VALUATION_ROUTE,
  NEW_LANDING_PAGE_ROUTES,
  PART_EXCHANGE_PATH,
  SELL_YOUR_CAR_CITY_ROUTES,
  TAX_VALUATION_ROUTE,
} from './wordpress';

export const AFFILIATE_PAGE_ROUTES = Object.freeze(['/partner/:partner']);
export const BRANDS_SECTION_ROUTES = Object.freeze(['/sell-my-car/brands/:brand?']); // Includes `/brands` & `/brands/ford` etc
export const HOME_COMPONENT_ROUTES = Object.freeze(['/sell-my-car/brands/:brand', '/sell-my-car/brands/:brand/mileage']);
export const HOME_PAGE_ROUTES = Object.freeze(['/', '/mileage']);
export const RECENT_SALES_CAROUSEL_ROUTES = Object.freeze([
  ...AFFILIATE_PAGE_ROUTES,
  ...BRANDS_SECTION_ROUTES,
  ...HOME_COMPONENT_ROUTES,
  ...LANDING_PAGE_ROUTES,
  ...NEW_LANDING_PAGE_ROUTES,
  '/',
]);

export const BLOG_ROUTES = Object.freeze(['/blog/:year?/:month?/:day?/:post?']);
export const GUIDE_ROUTES = Object.freeze(['/sell-my-car/guides/:subdir?', '/guides/:subdir?']);
export const STORY_ROUTES = Object.freeze(['/stories/:subdir?']);
export const GUIDE_CAROUSEL_ROUTES = Object.freeze([
  '/',
  ...NEW_LANDING_PAGE_ROUTES,
  MOT_VALUATION_ROUTE,
  TAX_VALUATION_ROUTE,
  ...SELL_YOUR_CAR_CITY_ROUTES,
  PART_EXCHANGE_PATH,
]);

export const VEHICLE_COLLECTION_ROUTE = '/collection';

export const PATH_TOKEN_EXPIRED = '/token-expired';
export const PATH_UNSUBSCRIBE = '/unsubscribe/:sms?';

export const SITEMAP_HTML_URL = '/sitemap';

export const getVrm = (match) => match?.params?.vrm;

export default {
  getMatch: (url, routes) => routes.reduce((prev, route) => {
    route = { ...route };

    if (route.path) {
      let match = matchPath(url, route.path, { exact: true });

      if (match && match.isExact) {
        if (route.vrmPath) {
          // Verify VRM
          try {
            const vrmInfo = VRM.info(match.params.vrm.toUpperCase());
            match.meta = {
              title: vrmInfo.prettyVrm,
            };
          } catch (err) {
            match = null;
          }
        }

        if (match) {
          if (route.meta) {
            match.meta = {
              description: route.meta.description || (match.meta && match.meta.description),
              title: route.meta.title || (match.meta && match.meta.title),
            };

            if (route.meta.image) {
              match.meta.image = route.meta.image;
            }

            delete route.meta;
          }
          match = Object.assign(match, route);
        }
      }

      return match && match.isExact ? match : prev;
    }

    return prev;
  }),
  vrmPath: [
    '/:vrm(',
    '[A-Z]{2}[0-9]{2}[A-Z]{3}|',
    '[A-Z][0-9]{1,3}[A-Z]{3}|',
    '[A-Z]{3}[0-9]{1,3}[A-Z]|',
    '[0-9]{1,4}[A-Z]{1,2}|',
    '[0-9]{1,3}[A-Z]{1,3}|',
    '[A-Z]{1,2}[0-9]{1,4}|',
    '[A-Z]{1,3}[0-9]{1,3}|',
    '[A-Z]{1,3}[0-9]{1,4})',
  ].join(''),
};

export const assignSectionRoutes = (path) => {
  const specificRoutes = {
    isBlogRoute: BLOG_ROUTES,
    isGuideRoute: GUIDE_ROUTES,
    isLandingPageRoute: FULL_LANDING_PAGE_ROUTES,
    isStoryRoute: STORY_ROUTES,
  };

  const isSpecificRoute = Object.entries(specificRoutes)
    .find(([, paths]) => paths.includes(path));

  if (isSpecificRoute?.[0]) {
    return { [isSpecificRoute[0]]: true };
  }

  return {};
};
