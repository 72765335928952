import React, { useCallback, useMemo, useState } from 'react';

import { Modal } from '@motorway/motorway-storybook-cra';

import TrustpilotModal from './TrustpilotModal';

const useTrustpilotModal = ({ displayName, review }) => {
  const [openModal, setOpenModal] = useState(false);
  const closeModal = useCallback(() => setOpenModal(false), []);

  const triggerModalOpen = () => setOpenModal(true);

  const { body, title } = review;

  const modalWrapper = useMemo(() => (
    openModal && (
    <Modal centered={false} dismissModal={closeModal} size="small">
      <TrustpilotModal body={body} title={title} vehicleMake={displayName} />
    </Modal>
    )
  ), [body, closeModal, displayName, openModal, title]);

  return {
    modalWrapper,
    triggerModalOpen,
  };
};

export default useTrustpilotModal;
