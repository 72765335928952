import React, {
  useContext, useEffect, useRef,
  useState,
} from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import { MDText } from 'i18n-react';

import { CarSideWindowsIcon, ProfileIcon, SignOutIcon } from '@motorway/motorway-storybook-cra';

import cypressIds from 'CypressId';

import { removeUser } from 'Context/apiActions';
import { UserContext } from 'Context/user';

import { GA_TAGS } from 'Utilities/analytics';
import { SP_HEADER_EVENTS } from 'Utilities/analytics/analyticsHeader';
import { applyCypressData } from 'Utilities/index';
import { MOTORWAY_URL } from 'Utilities/urls';
import useMediaQuery from 'Utilities/useMediaQuery';

import LocalTexts from './HeaderTexts.json';

import styles from './Header.scss';

const LocalT = new MDText(LocalTexts);
const t = (key: string, props?: { [key:string]: unknown }) => LocalT.translate(key, props) as string;

export const texts = {
  account: t('navigation.accountLabel'),
  signIn: t('navigation.signInLabel'),
  signOut: t('navigation.signOutLabel'),
};

const CSS_IDS = Object.freeze({
  ACCOUNT_TOGGLE: 'header-account-toggle',
  NAV_MOBILE: 'userNavMobile',
  SHIFT_RIGHT: 'mw-shift-right',
});

export const PATHS = {
  ACCOUNT: '/account',
  SIGN_IN: '/sign-in',
};

const ConstantAccountLinks = () => {
  const handleLogoutClick = async (e:React.MouseEvent) => {
    e.preventDefault();
    SP_HEADER_EVENTS.HEADER_LINK_CLICKED({
      name: 'sign_out',
      url: MOTORWAY_URL,
    });
    removeUser();
  };

  const accountOnClick = () => {
    GA_TAGS.HEADER_ACCOUNT_CLICKED();
    SP_HEADER_EVENTS.HEADER_LINK_CLICKED({
      label: 'from_hover',
      name: 'account',
      url: PATHS.ACCOUNT,
    });
  };

  return (
    <ul>
      <li className={styles.navItem}>
        <Link
          onClick={accountOnClick}
          to={PATHS.ACCOUNT}
        >
          <CarSideWindowsIcon />
          { texts.account }
        </Link>
      </li>
      <li className={styles.navItem}>
        <a href='#signout' onClick={handleLogoutClick}>
          <SignOutIcon />
          { texts.signOut }
        </a>
      </li>
    </ul>
  );
};

export const AccountLinks = () => {
  let initials;

  const isMobile = useMediaQuery(`(max-width: ${styles.breakpointTablet}), (pointer: coarse)`);

  const [menuOpen, setMenuOpen] = useState(false);

  const menuElem = useRef<HTMLDivElement>(null);

  const { userState: user } = useContext(UserContext);
  const isUser = Number.isInteger(user?.id);

  if (isUser && user?.name) {
    initials = user.name.charAt(0);
    if (user.name.split(' ').length > 1) {
      initials += user.name.split(' ')[1].charAt(0);
    }
    initials = initials.toUpperCase();
  }

  const listenForCloseEvent: EventListenerOrEventListenerObject = (e) => {
    if (
      (e.target as HTMLElement).matches(`#${CSS_IDS.NAV_MOBILE}`) === false
      && (e.target as HTMLElement).matches(`#${CSS_IDS.ACCOUNT_TOGGLE}`) === false
    ) {
      setMenuOpen(false);

      document.body.removeEventListener('click', listenForCloseEvent);
      document.documentElement.classList.remove(CSS_IDS.SHIFT_RIGHT);
    }
  };

  const toggleMenu = (e:React.MouseEvent) => {
    const mobileMenuClosed = (isMobile && !menuOpen);

    if (!isMobile) {
      SP_HEADER_EVENTS.HEADER_LINK_CLICKED({ label: 'direct', name: 'account', url: PATHS.ACCOUNT });
    }

    if (mobileMenuClosed || !isMobile) {
      GA_TAGS.HEADER_USERNAME_CLICKED();
    }

    if (e) {
      const target = e.target as HTMLElement;
      if (isMobile && target.getAttribute('target') !== '_blank') {
        e.preventDefault(); // Open menu on mobile
      } else {
        return; // Let desktop go to account
      }
    }

    setMenuOpen(!menuOpen);

    const vector = mobileMenuClosed ? 'add' : 'remove';

    document.body[`${vector}EventListener`]('click', listenForCloseEvent);
    document.documentElement.classList[vector](CSS_IDS.SHIFT_RIGHT);
  };

  useEffect(() => {
    if (!isMobile) {
      setMenuOpen(false);
      document.documentElement.classList.remove(CSS_IDS.SHIFT_RIGHT);
    }
  }, [isMobile]);

  useEffect(() => {
    if (menuOpen) {
      window.scrollTo(0, 0);
    }
  }, [menuOpen]);

  useEffect(() => {
    if (menuElem.current && !menuOpen) {
      menuElem.current.scrollTop = 0;
    }
  }, [menuElem, menuOpen]);

  return (
    <div className={cx(styles.account, styles.toggle)}>
      {isUser ? (
        <>
          <Link
            className={styles.user}
            {...applyCypressData(cypressIds.links.accountLink)}
            id={CSS_IDS.ACCOUNT_TOGGLE}
            onClick={toggleMenu}
            onMouseEnter={() => SP_HEADER_EVENTS.HEADER_LINK_HOVERED({ message: 'Account menu', name: 'account' })}
            to={PATHS.ACCOUNT}
          >
            <div className={styles.initials}>{initials}</div>
            <div className={styles.name}>{user?.name?.split(' ')[0]}</div>
          </Link>
          <div className={cx(styles.nav, styles.navDesktop, styles.subMenu)}>
            <ConstantAccountLinks />
          </div>

          <div
            ref={menuElem}
            className={cx(styles.nav, styles.navMobile)}
            id={CSS_IDS.NAV_MOBILE}
            {...applyCypressData(cypressIds.sections.mobileNav)}
          >
            <ConstantAccountLinks />
          </div>
        </>
      ) : (
        <Link
          aria-label={texts.signIn}
          className={styles.signIn}
          data-cy='signInLink'
          onClick={() => SP_HEADER_EVENTS.HEADER_LINK_CLICKED({ name: 'sign_in', url: PATHS.SIGN_IN })}
          to={PATHS.SIGN_IN}
        >
          <ProfileIcon />
          <span className='signInLabel'>
            { texts.signIn }
          </span>
        </Link>
      )}
    </div>
  );
};
