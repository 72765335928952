import { MDText, MDTextOpts } from 'i18n-react';

import localTexts from './VehicleHeader.texts.json';
import { VehicleHeaderData, VehicleHeaderE2EProps } from './VehicleHeader.types';

export const createTexts = (mdOptions:MDTextOpts = {}) => {
  const md = new MDText(localTexts, mdOptions);
  const t = (key: string) => () => `${md.translate(key)}`;

  return {
    labelCollapse: t('toggle.label.collapse'),
    labelExpand: t('toggle.label.expand'),
  };
};

const HEADER_FIELDS = ['make', 'model'] as const;

const DETAIL_FIELDS = ['year', 'mileage', 'colour', 'body', 'fuel'] as const;

const createGetHeadingText = (fields: readonly (keyof VehicleHeaderData)[]) =>
  (data: Partial<VehicleHeaderData>): string => {
    const dataKeys = Object.keys(data);
    return fields
      .filter((key) => dataKeys.includes(key))
      .map((key) => data[key])
      .filter((value): value is string => value !== undefined)
      .join(' ');
  };

const getCapitalized = (value:string):string => {
  if (!value) {
    return value;
  }
  const [first, ...rest] = value;
  return [first.toLocaleUpperCase(), ...rest].join('');
};

const getDetailFieldValue = (value:string|undefined):string|undefined => {
  if (!value) {
    return undefined;
  }
  return getCapitalized(value);
};

const createGetDetailFields = (fields: readonly (keyof VehicleHeaderData)[]) =>
  (data: Partial<VehicleHeaderData>):
  (readonly [keyof VehicleHeaderData, string])[] => {
    const dataKeys = Object.keys(data);
    return fields
      .filter((key) => dataKeys.includes(key))
      .map((key) => [key, getDetailFieldValue(data[key])] as const)
      .filter(
        (entry): entry is readonly [keyof VehicleHeaderData, string] =>
          entry[1] !== undefined,
      );
  };

type E2EProps = {
  readonly [K in keyof VehicleHeaderE2EProps as string]: VehicleHeaderE2EProps[K];
};

const getMaybe = <T, >(value: T): T | undefined => value;

export const createGetE2EProps = (
  vehicleE2EProps: Partial<VehicleHeaderE2EProps> = {},
): {
    (key: keyof VehicleHeaderE2EProps): E2EProps[string];
    // eslint-disable-next-line function-paren-newline
    (key: string): E2EProps[string];
  } => {
  const e2eProps: E2EProps = vehicleE2EProps;
  return (key) => {
    const props = getMaybe(e2eProps[key]);
    return props || {};
  };
};

export const getHeadingText = createGetHeadingText(HEADER_FIELDS);

export const getDetailFields = createGetDetailFields(DETAIL_FIELDS);
