import { useCallback, useState } from 'react';
import { useHistory, useLocation } from 'react-router';

import { postUlezCheck } from 'PublicAPI';

import { GA_TAGS, ULEZ_CHECKER_EVENTS } from 'Utilities/analytics';
import { unprettyVRM } from 'Utilities/formValidators';
import { safeLocalStorage } from 'Utilities/storages';

import { ULEZ_URL_PATH, ULEZ_VEHICLE_KEY } from '../../../containers/app/contentPages/ulezPage/helper';

const getComplianceStatus = (vehicle) => {
  const complianceStatus = vehicle?.isCompliant ? 'compliant' : 'non_compliant';
  return vehicle?.euroStatus === '3' ? 'euro_3' : complianceStatus;
};

const useHandleVRMUlezSubmit = ({ gaEventLabel } = {}) => {
  const [disabled, setDisabled] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const { push, replace } = useHistory();
  const { pathname } = useLocation();

  const handleVRMUlezSubmit = useCallback(async (vrm, reset) => {
    try {
      setDisabled(true);
      const { data: ulezData } = await postUlezCheck(unprettyVRM(vrm));
      setDisabled(false);

      ULEZ_CHECKER_EVENTS.ENQUIRY_SUBMITTED({
        compliantStatus: getComplianceStatus(ulezData),
        isApiSuccess: true,
        vrm,
      });
      GA_TAGS.ULEZ_ENQUIRY_SUBMITTED(gaEventLabel);
      safeLocalStorage.setItem(ULEZ_VEHICLE_KEY, JSON.stringify(ulezData));
      push(`${ULEZ_URL_PATH}/results`);
    } catch (err) {
      setDisabled(false);
      setErrorMessage(err?.message);
      ULEZ_CHECKER_EVENTS.ENQUIRY_SUBMITTED({ compliantStatus: 'N/A', isApiSuccess: false, vrm });
      GA_TAGS.ULEZ_ENQUIRY_SUBMISSION_FAILED(err?.message);

      replace(pathname, { validVrm: false, vrm });
      reset();

      window?.Sentry?.captureException(new Error(err), {
        extra: { vrm },
        level: 'warning',
        tags: {
          fetch: 'ULEZ vehicle data',
        },
      });
    }
  }, [pathname, push, replace, gaEventLabel]);

  return {
    disabled,
    errorMessage,
    handleVRMUlezSubmit,
  };
};

export default useHandleVRMUlezSubmit;
