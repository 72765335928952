import { useRef, useState } from 'react';

import { ObserverEntryContext, useObserver } from 'Utilities/observer/useObserver';
import useInView from 'Utilities/useInView';

type RefEventsProps = {
  eventLabels?: string[];
  onRefTrigger: (eventLabel?: string) => void;
}

export const usePageRefEvents = ({ eventLabels, onRefTrigger }: RefEventsProps) => {
  const $wrapper = useRef<HTMLDivElement>(null);
  const $refs = useRef<readonly Element[]>([]);
  const [updateMarker, setUpdateMarker] = useState(0);

  const [entryMap, setEntryMap] = useState<
  Map<string, IntersectionObserverEntry>
  >(new Map());

  const isSectionVisible = useInView($wrapper, {
    disconnectOnVisible: true,
    rootMargin: '60px',
  });

  const setRef = <T extends Element>(node: T | null, index: number = 0) => {
    if (node) {
      $refs.current = [
        ...$refs.current.slice(0, index),
        node,
        ...$refs.current.slice(index + 1),
      ];
    }
  };

  const observerOnChange = (entry: IntersectionObserverEntry, context: ObserverEntryContext) => {
    const targetRefIndex = $refs.current.findIndex((ref) => ref === entry.target);
    const eventLabel = eventLabels?.[targetRefIndex];
    if (eventLabel) {
      setEntryMap((current) => new Map(current.set(eventLabel, entry)));
    }

    if (entry.isIntersecting) {
      onRefTrigger?.(eventLabel);
      context.freeze();
    }
  };

  useObserver(
    $refs,
    { threshold: 0.5 },
    (entry, context) => observerOnChange(entry, context),
    [isSectionVisible, updateMarker],
  );

  const update = () => setUpdateMarker((c) => 1 - c);

  return { $wrapper, entryMap, isSectionVisible, setRef, update };
};
