import React, { useContext, useEffect } from 'react';
import { Form } from 'react-final-form';
import { useLocation } from 'react-router';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { MDText } from 'i18n-react';

import { Button } from '@motorway/mw-highway-code';
import { Hyperlink } from '@motorway/mw-highway-code/alpha';
import { BUTTON_AS_TYPE, BUTTON_TYPE, SIZE, VARIANT } from '@motorway/mw-highway-code/enums';

import { VehicleLookupContext } from 'Context/vehicleLookup';

import { VALUATIONS_GA_TAGS } from 'Utilities/analytics';
import { vehicleType } from 'Utilities/helpers';
import { getMileage } from 'Utilities/mileage';

import { useHeroMileageInputHelpers } from '../hooks/useHeroMileageInputHelpers';
import MileageInput from '../mileageInput/MileageInput';
import MileageInputHigh from '../mileageInput/MileageInputHigh';

import LocalTexts from './VrmInputTexts.json';

import styles from './VrmInputMileage.scss';

const LocalT = new MDText(LocalTexts);

const t = (key) => String(LocalT.translate(key));

const TEXTS = {
  confirmMileage: t('cta.confirmMileage'),
  continue: t('cta.continue'),
};

const VrmInputMileage = ({
  analyticsEvents,
  loadingHandler = () => { },
  // Stop TS complaining about the unused parameters till converted to tsx
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  onSubmit = (mileage) => { },
  setShowMileage = () => { },
  showMileage,
  valuationHub: isCarValueTracker = false,
}) => {
  const location = useLocation();
  const { vehicleLookupState: lookupVehicle } = useContext(VehicleLookupContext);
  const vehicleLookupMileage = getMileage({ vehicle: lookupVehicle });

  const vrm = lookupVehicle?.vrm;

  const {
    confirmedMileage, confirmMileageHelpers, onFormSubmit, onNotMyCarClick,
  } = useHeroMileageInputHelpers({
    isCarValueTracker,
    loadingHandler,
    onSubmit,
    setShowMileage,
  });

  useEffect(() => {
    if (showMileage && isCarValueTracker) {
      VALUATIONS_GA_TAGS.HERO_MILEAGE_SHOWN(vrm);
      VALUATIONS_GA_TAGS.HERO_VEHICLE_LOOKUP_COMPLETE();
      VALUATIONS_GA_TAGS.VEHICLE_LOOKUP_COMPLETE(vrm);
    }
  }, [isCarValueTracker, showMileage, vrm]);

  const ctaText = isCarValueTracker ? TEXTS.confirmMileage : TEXTS.continue;

  return (
    <>
      <Form
        initialValues={{
          mileage: vehicleLookupMileage,
        }}
        onSubmit={ ({ mileage }) => {
          if (!isCarValueTracker) {
            VALUATIONS_GA_TAGS.HERO_MILEAGE_BUTTON_CLICKED();
          }
          onFormSubmit({ mileage });
        }}
        render={({ handleSubmit, submitting, values }) => (
          <form
            noValidate
            className={cx(styles.component, {
              [styles.valuationHub]: isCarValueTracker,
            })}
            onSubmit={handleSubmit}
          >
            <MileageInput
              isHero
              validateOnInit
              {...{ analyticsEvents, isCarValueTracker }}
              titleClassName={cx({ [styles.mileageTitle]: isCarValueTracker })}
            />
            <Button
              data-thc-button
              fullWidth
              data-cy={'continueButton'}
              icon={'chevron'}
              label={ctaText}
              loading={submitting}
              onClick={() => {
                analyticsEvents?.onButtonClick?.(vrm);
                if (!isCarValueTracker) {
                  VALUATIONS_GA_TAGS.HERO_MILEAGE_BUTTON_CLICKED_SNOWPLOW({ mileage: values?.mileage, vrm });
                }
              }}
              size={SIZE.LARGE}
              type={BUTTON_TYPE.SUBMIT}
              variant={VARIANT.PRIMARY}
            />
          </form>
        )}
      />
      <div className={ cx({ [styles.textCenter]: isCarValueTracker }) }>
        {<Hyperlink
          as={BUTTON_AS_TYPE.ANCHOR}
          data-cy={'notMyCar'}
          href={'/'}
          label={LocalT.translate('cta.changeCar', { vehicle: vehicleType(location.pathname) })}
          onClick={(e) => {
            e.preventDefault();
            onNotMyCarClick();
          }}
          variant={VARIANT.SECONDARY}
        />
        }
      </div>
      {
        confirmedMileage
        && (<MileageInputHigh
          {...{ isCarValueTracker }}
          confirmMileage={confirmMileageHelpers()}
          {...confirmedMileage}
        />)
      }
    </>
  );
};

VrmInputMileage.propTypes = {
  analyticsEvents: PropTypes.shape(),
  loadingHandler: PropTypes.func,
  onSubmit: PropTypes.func,
  setShowMileage: PropTypes.func,
  showMileage: PropTypes.bool,
  valuationHub: PropTypes.bool,
};

export default VrmInputMileage;
