import { ULEZ_PAGE_ROUTES } from 'Utilities/wordpress';

export type ACFType = {
  acf?: Record<string, unknown>;
};

export type FAQType = {
  answer?: string;
  question?: string;
};

export const ULEZ_URL_PATH = ULEZ_PAGE_ROUTES[0];
export const ULEZ_VEHICLE_KEY = 'ulezVehicleData';

export const ULEZ_STATUS = Object.freeze({
  COMPLIANT: {
    actionLabel: 'Compliant',
    text: 'compliant',
  },
  EURO_3: {
    actionLabel: 'Euro 3',
    text: 'euro3',
  },
  NON_COMPLIANT: {
    actionLabel: 'Non-Compliant',
    text: 'notCompliant',
  },
  UNAVAILABLE: {
    actionLabel: 'Not Available',
    text: 'notAvailable',
  },
});
