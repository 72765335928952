import React from 'react';

import { MDText } from 'i18n-react';

import Texts from '../../../texts.json';
import FooterReviews from '../footer/Components/FooterReviews/FooterReviews';

import Error from './Error';

const LocalT = new MDText(Texts);

export default () => (
  <>
    <Error
      buttonLabel="404.buttonLabel"
      detail={LocalT.translate('404.detail')}
      number="404"
      title="404.title"
    />
    <FooterReviews />
  </>
);
