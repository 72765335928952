import {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import _throttle from 'lodash-es/throttle';

import { ConfigContext } from 'Context/config';
import { UserContext } from 'Context/user';

import { isFunction } from './helpers';

export const useFullState = (initialState) => { // https://reactjs.org/docs/hooks-reference.html#functional-updates <-- yellow note
  const [masterState, setMasterState] = useState({
    callback: null,
    state: initialState,
  });

  const setState = useCallback((nextState, callback) => {
    setMasterState(({ state: prevState }) => ({
      callback,
      state: {
        ...prevState,
        ...(isFunction(nextState) ? nextState(prevState) : nextState),
      },
    }));
  }, []);

  useEffect(() => {
    if (isFunction(masterState.callback)) {
      masterState.callback(masterState.state);
    }
  }, [masterState]);

  return [masterState.state, setState];
};

export const usePrevious = (value) => {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
};

export const useScrollToTop = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
};

export const useRedirectToAccountIfUser = () => {
  const { userState: user } = useContext(UserContext);
  const { push } = useHistory();

  useEffect(() => {
    if (user?.id) {
      push('/account');
    }
  }, [push, user?.id]);
};

export const useWindowSize = (breakpoint = () => { }) => {
  // https://stackoverflow.com/a/66588540
  const [windowSize, setWindowSize] = useState(breakpoint());

  useEffect(() => {
    const handleResize = _throttle(() => {
      setWindowSize(breakpoint());
    }, 100);

    window.addEventListener('resize', handleResize, { passive: true });

    return () => window.removeEventListener('resize', handleResize, { passive: true });
  }, [breakpoint]);

  return windowSize;
};

export const useQuery = (param) => {
  const { search } = useLocation();
  const queryString = new URLSearchParams(search);

  return queryString.get(param);
};

export const useFeatureFlags = () => {
  const { configState: { featureFlags } } = useContext(ConfigContext);

  return featureFlags || {};
};
