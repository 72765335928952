import React, { createContext, useCallback, useReducer } from 'react';
import omit from 'lodash-es/omit';

import { FastlaneInitialState } from 'Utilities/fastlane';
import { isSSR } from 'Utilities/helpers';
import { safeLocalStorage } from 'Utilities/storages';

type AdditionalState = {
  appInit: number;
  zendeskWidgetState: {
    show: boolean;
  };
} & Partial<FastlaneInitialState>;

type ConfigState = FastlaneInitialState & AdditionalState;

export enum ConfigActionType {
  CONFIG_OVERIDE_FEATURE_FLAGS = 'CONFIG_OVERIDE_FEATURE_FLAGS',
  CONFIG_RESET_FEATURE_FLAG = 'CONFIG_RESET_FEATURE_FLAG',
  CONFIG_RESET_FEATURE_FLAGS = 'CONFIG_RESET_FEATURE_FLAGS',
  CONFIG_UPDATE = 'CONFIG_UPDATE',
  ZENDESK_UPDATE = 'ZENDESK_UPDATE'
}

type ConfigAction = {
  payload: Partial<ConfigState> | Partial<ConfigState['zendeskWidgetState']>;
  type: ConfigActionType;
}

type ConfigContext = {
  configActions: {
    overrideFeatureFlags: (fields: ConfigState['featureFlags']) => void;
    resetFeatureFlag: (fields: ConfigState['featureFlags']) => void;
    resetFeatureFlags: () => void;
    update: (fields: Partial<ConfigState>) => void;
    updateZendesk: (fields: Partial<ConfigState['zendeskWidgetState']>) => void;
  };
  configState: ConfigState | AdditionalState;
}

type ConfigProviderProps = {
  children: React.ReactNode;
  value: FastlaneInitialState;
}

// Export state available outside of React
const ConfigStateStatic = {
  state: {},
};

const initialActions = {
  overrideFeatureFlags: () => {},
  resetFeatureFlag: () => {},
  resetFeatureFlags: () => {},
  update: () => {},
  updateZendesk: () => {},
};

const initialState = {
  appInit: Date.now(),
  zendeskWidgetState: {
    show: false,
  },
};

const ConfigContext = createContext<ConfigContext>({ configActions: initialActions, configState: initialState });

export const reducer = (state: ConfigState, { payload, type }: ConfigAction) => {
  let newState: ConfigState;
  switch (type) {
    case ConfigActionType.CONFIG_UPDATE:
      newState = {
        ...state,
        ...payload,
      };
      break;
    case ConfigActionType.ZENDESK_UPDATE:
      newState = {
        ...state,
        zendeskWidgetState: {
          ...state.zendeskWidgetState,
          ...payload,
        },
      };
      break;
    case ConfigActionType.CONFIG_OVERIDE_FEATURE_FLAGS:
      newState = {
        ...state,
        overrideFlags: {
          ...state.overrideFlags,
          ...payload as ConfigState['overrideFlags'],
        },
      };
      break;

    case ConfigActionType.CONFIG_RESET_FEATURE_FLAGS:
      newState = {
        ...state,
        overrideFlags: {},
      };
      break;
    case ConfigActionType.CONFIG_RESET_FEATURE_FLAG:
      newState = {
        ...state,
        overrideFlags: {
          ...payload as ConfigState['overrideFlags'],
        },
      };
      break;
    default:
      window?.Sentry?.captureException?.(new Error('Unexpected use of default switch case'), {
        // @ts-ignore
        level: 'warning',
      });
      newState = state;
  }

  return newState;
};

export const saveFeatureFlagsToStorage = (featureFlags: ConfigState['featureFlags']) => {
  safeLocalStorage.setItem('featureFlags', featureFlags);
};

const ConfigProvider = ({ children, value }: ConfigProviderProps) => {
  const state = {
    ...value,
    ...initialState,
  };
  const [configState, dispatch] = useReducer(reducer, state);

  const hasOverrideFlags = process.env.APPLICATION_ENVIRONMENT !== 'production'
    && !isSSR()
    && safeLocalStorage.getItem('featureFlags');
  if (hasOverrideFlags) {
    state.overrideFlags = safeLocalStorage.getItem('featureFlags') || {};
  }

  const configActions = {
    overrideFeatureFlags: useCallback((fields: ConfigState['featureFlags']) => {
      if (fields) {
        const featureFlagsToBeSet = {
          ...safeLocalStorage.getItem('featureFlags'),
          ...fields,
        };
        saveFeatureFlagsToStorage(featureFlagsToBeSet);

        dispatch({ payload: fields, type: ConfigActionType.CONFIG_OVERIDE_FEATURE_FLAGS });
      }
    }, [dispatch]),
    resetFeatureFlag: useCallback((fields: ConfigState['featureFlags']) => {
      if (fields) {
        const featureFlagKey = Object.keys(fields)[0];
        const currentFeatureFlagsInStorage = safeLocalStorage.getItem('featureFlags');

        const newFeatureFlagsInStorage = omit(currentFeatureFlagsInStorage, featureFlagKey);
        saveFeatureFlagsToStorage(newFeatureFlagsInStorage);

        dispatch({ payload: newFeatureFlagsInStorage, type: ConfigActionType.CONFIG_RESET_FEATURE_FLAG });
      }
    }, [dispatch]),
    resetFeatureFlags: useCallback(() => {
      saveFeatureFlagsToStorage({});

      dispatch({ payload: {}, type: ConfigActionType.CONFIG_RESET_FEATURE_FLAGS });
    }, [dispatch]),
    update: useCallback((fields: Partial<ConfigState>) => {
      if (fields) {
        dispatch({ payload: fields, type: ConfigActionType.CONFIG_UPDATE });
      }
    }, [dispatch]),
    updateZendesk: useCallback((fields: Partial<ConfigState['zendeskWidgetState']>) => {
      if (fields) {
        // Stop the update from blocking the main thread with internal widget processing
        setTimeout(() => dispatch({ payload: fields, type: ConfigActionType.ZENDESK_UPDATE }), 0);
      }
    }, [dispatch]),
  };

  ConfigStateStatic.state = Object.freeze({
    ...configState,
  });

  // Had to do this because mutating object members is more permanent for useReducer
  // than reassigning the object. So updating configState.featureFlags directly would
  // persist the changes even after the overrideFlags were reset.

  const resolvedConfigState = hasOverrideFlags ? {
    ...configState,
    featureFlags: {
      ...configState.featureFlags,
      ...configState.overrideFlags,
    },
  } : configState;

  return (
    <ConfigContext.Provider
      value={{
        configActions,
        configState: resolvedConfigState,
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
};

export {
  ConfigContext,
  ConfigProvider,
  ConfigStateStatic,
};
