import DialogLoader from '../components/transitions/DialogLoader/DialogLoader';

export const DATE_FORMAT = {
  DEFAULT: 'YYYY-MM-DD',
};

export const CONFIG_ENGINE_FEATURE_FLAGS = {
  PROFILING_PUBLIC: 'mw-profiling-feature-flags-public',
  WEBAPP_PUBLIC: 'mw-webapp-feature-flags-public',
};

export const ENQUIRY_STATE_SLUG = {
  PART_DETAILS: 'part_details',
};

export const TEAM = Object.freeze({
  PAYMENTS_FE: 'payments-fe',
  POWER_ENGAGERS_FE: 'power-engagers-fe',
  THUNDERBIRDS_FE: 'thunderbirds-fe',
  TURBO_CHARGE_FE: 'turbo-charge-fe',
});

export const CUSTOMER_CHANNELS = {
  RETAIL: 'retail',
  TRADE: 'trade',
};

export enum CRM_TIER {
  MARKETING = 'marketing',
  SERVICE = 'service',
  TRANSACTION = 'transaction',
}

export const PROVIDERS_VERSIONS_KEY = 'x-mway-providers-versions';

export const CUSTOMER_CHANNEL_KEY = 'x-mway-customer-channel';

export const REFRESH_TOKENS = {
  ERROR: {
    EXPIRED: 'Failed to refresh tokens. Refresh Token has expired',
  },
  INTERVAL_MINUTES: 18,
  MAX_FAILED_ATTEMPTS: 2,
};

export const defaultLazyLoadOptions = {
  ignoreBabelRename: true,
  loading: <DialogLoader />,
  loadingTransition: false,
} as const;
