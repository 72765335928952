import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import breakpoints from './Breakpoints.scss';

const defaultValue = {};

const BreakpointContext = createContext(defaultValue);

const queries = Object.entries(breakpoints).filter(([k]) => /^breakpoint/.test(k)).sort((a, b) => parseInt(a[1]) - parseInt(b[1]));

const BreakpointProvider = ({ children }) => {
  const [queryMatch, setQueryMatch] = useState([]);

  useEffect(() => {
    const resize = (key, e) => {
      setQueryMatch((matches) => matches.map(([k, m]) => ((k === key) ? [k, e.matches] : [k, m])));
    };

    const match = [];
    const store = {};

    queries.forEach(([key, maxWidth]) => {
      store[key] = {
        listener: resize.bind(this, key),
        query: window.matchMedia(`(max-width: ${maxWidth})`),
      };
      store[key].query.addListener(store[key].listener);

      match.push([key, store[key].query.matches]);
    });

    setQueryMatch(match);

    return () => Object.values(store).forEach((val) => {
      val.query.removeListener(val.listener);
    });
  }, []);

  const processValue = useMemo(() => {
    const value = {
      maxWidth: {},
      minWidth: {},
    };

    queryMatch.forEach(([k, v]) => {
      value.maxWidth[k] = v;
      value.minWidth[k] = !v;
    });

    return value;
  }, [queryMatch]);

  return (
    <BreakpointContext.Provider value={processValue}>
      {children}
    </BreakpointContext.Provider>
  );
};

const useBreakpoint = () => {
  const context = useContext(BreakpointContext);

  if (context === defaultValue) {
    throw new Error('useBreakpoint must be used within BreakpointProvider');
  }
  return context;
};

export { useBreakpoint, BreakpointProvider };
