/* eslint-disable no-console */
import { useEffect } from 'react';

import useInView from 'Utilities/useInView';

const useTrustPilotWidgetLoader = ({ $container, observerSettings, onLoad, setFailedLoad }) => {
  const scriptId = 'trustPilotWidgetScript';

  const visible = useInView($container, observerSettings);

  useEffect(() => {
    const handleError = (message, error) => {
      setFailedLoad(true);
      console.warn(message);
      console.error(error);
    };

    if (!visible || !$container.current) {
      return;
    }

    onLoad?.();

    if (!document?.getElementById(scriptId)) {
      const s = document.createElement('script');
      s.src = '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js';
      s.id = scriptId;
      s.async = true;
      s.type = 'text/javascript';

      s.onerror = (e) => handleError('TrustPilot widget was not loaded', e);
      document.head.appendChild(s);
    }

    try {
      window?.Trustpilot?.loadFromElement($container?.current, true);
    } catch (e) {
      handleError('Captured TrustPilot widget error', e);
    }
  }, [$container, onLoad, setFailedLoad, visible]);

  return null;
};

export default useTrustPilotWidgetLoader;
