import { CTA_CLICK_EVENT_PAYLOAD, GENERIC_ANALYTICS_EVENTS } from 'Utilities/analytics/analyticsGenericEvents';

type UlezStatusCategories = 'compliant' | 'non_compliant';

type ctaClickedProps = {
  buttonLabel: CTA_CLICK_EVENT_PAYLOAD['button_label'];
  categoryType: UlezStatusCategories;
  label: CTA_CLICK_EVENT_PAYLOAD['label'];
  url: CTA_CLICK_EVENT_PAYLOAD['url'];
};

type enquirySubmittedProps = {
  compliantStatus: 'compliant' | 'non_compliant' | 'N/A';
  isApiSuccess: boolean;
  vrm: string;
};

type onwardJourneyProps = {
  label: string;
  url: string;
}

export const ULEZ_CATEGORIES = {
  CHECKER: 'ulez',
  RESULTS: 'ulez_results',
};

const CTA_NAMES = {
  RESULTS: 'value_my_car',
  VRM: 'vrm_submit',
};

export const ULEZ_CHECKER_EVENTS = {
  CTA_CLICKED: ({ buttonLabel, categoryType, label, url }: ctaClickedProps) =>
    GENERIC_ANALYTICS_EVENTS.CTA_CLICK({
      button_label: buttonLabel,
      category: `${ULEZ_CATEGORIES.RESULTS}_${categoryType}`,
      label,
      name: CTA_NAMES.RESULTS,
      url,
    }),
  ENQUIRY_SUBMITTED: ({ compliantStatus, isApiSuccess, vrm }: enquirySubmittedProps) =>
    GENERIC_ANALYTICS_EVENTS.API_TRIGGERED({
      action: 'ulez_compliance_check',
      backend_entity: vrm,
      backend_service: 'ulez_compliance',
      success: isApiSuccess,
      value: compliantStatus,
    }),
  ONWARD_JOURNEY_CLICKED: ({ label, url }: onwardJourneyProps) =>
    GENERIC_ANALYTICS_EVENTS.CLICK_EVENT({
      category: ULEZ_CATEGORIES.RESULTS,
      label,
      name: 'onward_journey',
      url,
    }),
  VRM_CTA_CLICKED: (label: string, ctaLabel: string) =>
    GENERIC_ANALYTICS_EVENTS.CTA_CLICK({
      button_label: ctaLabel,
      category: ULEZ_CATEGORIES.CHECKER,
      label,
      name: CTA_NAMES.VRM,
      url: '/results',
    }),
  VRM_INPUT_FOCUSED: (label: string) =>
    GENERIC_ANALYTICS_EVENTS.CLICK_EVENT({
      category: ULEZ_CATEGORIES.CHECKER,
      label,
      name: 'vrm_input',
    }),
};
