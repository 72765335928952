import { useContext, useEffect, useState } from 'react';

import { MDText } from 'i18n-react';

import { getReviews } from 'PublicAPI';

import { VehicleLookupContext } from 'Context/vehicleLookup';

import {
  REVIEW_BODY_MAX_LENGTH,
  SPECIFIC_MAKE_NAMES,
  truncate,
  TRUSTPILOT_REVIEW_NUMBER,
  TRUSTPILOT_REVIEW_SCORE,
} from 'Utilities/helpers';

import LocalTexts from './TrustpilotTexts.json';

const LocalT = new MDText(LocalTexts);

const useTrustpilot = ({
  brand,
  maxLength: maxLengthProp,
} = {}) => {
  const { vehicleLookupState: vehicle } = useContext(VehicleLookupContext);
  const { display_name: displayName, slug } = brand || vehicle?.make || {};

  const [loaded, setLoaded] = useState(false);
  const [title, setTitle] = useState((LocalT.translate('title', { make: displayName })));
  const [tpData, setTpData] = useState([]);
  const [tpReviews, setTpReviews] = useState([]);

  const fiveStarReviews = (arr) => arr.filter((r) => r.rating === 1);

  useEffect(() => {
    const getReviewsData = async (make = '') => {
      const response = await getReviews('motorway.co.uk', make);

      setTpData({
        rating: ((response?.trustpilot_api?.rating || TRUSTPILOT_REVIEW_SCORE) * 5).toFixed(1),
        totalReviews: (Math.floor(
          (TRUSTPILOT_REVIEW_NUMBER || response?.trustpilot_api?.totalReviews || TRUSTPILOT_REVIEW_NUMBER) / 250,
        ) * 250
        ).toLocaleString(),
      });

      return response?.trustpilot_api?.reviews || [];
    };

    const maxLength = maxLengthProp || REVIEW_BODY_MAX_LENGTH;
    const truncatedBodyReview = (text) => truncate(text.body.toLowerCase(), maxLength);

    const checkBodyReview = (text) => text.includes(displayName.toLowerCase()) || (
      SPECIFIC_MAKE_NAMES.some((name) => text.includes(name.toLowerCase()))
    );

    const sortedReviewsWithMakeNames = (arr) => arr.sort((a, b) => {
      const x = truncatedBodyReview(a);
      const y = truncatedBodyReview(b);

      if (checkBodyReview(x)) {
        return -1;
      }
      if (checkBodyReview(y)) {
        return 1;
      }
      return 0;
    });

    const loadReviews = async () => {
      try {
        const reviewsResponse = await getReviewsData(slug);

        if (reviewsResponse.length) {
          setTpReviews(sortedReviewsWithMakeNames(fiveStarReviews(reviewsResponse)));
        } else {
          // fetch generic reviews (no brand specific)
          const reviewsResponseGeneric = await getReviewsData();

          setTpReviews(fiveStarReviews(reviewsResponseGeneric));
          setTitle((LocalT.translate('title_generic', { tp: 'Trustpilot' })));
          setTpData([]);
        }

        setLoaded(true);
      } catch (err) {
        window?.Sentry?.captureException?.(err);
        console.error(err); // eslint-disable-line no-console
      }
    };

    loadReviews();
  }, [maxLengthProp, displayName, slug]);

  return {
    loaded,
    title,
    tpData,
    tpReviews,
  };
};

export default useTrustpilot;
