export const zendeskRetry = (cb) => setTimeout(cb, 500);

export const IS_PROD = process.env.APPLICATION_ENVIRONMENT === 'production';

export const zeKey = (IS_PROD)
  ? 'd5e79e18-889b-41cf-b200-1bb7535acc66'
  : 'ceb69e8d-3e65-44aa-9f25-ecf7133c75f1';

export const zendeskSnowplowSessionFieldId = (IS_PROD)
  ? '13689725328668'
  : '18308472438173';

export const zendeskSessionFieldId = (IS_PROD)
  ? '14978453258652'
  : '19489983432605';
