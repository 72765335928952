const motorwaySignInStr = 'motorway-sign-in';
let bc;

export const initBroadcast = () => {
  if ('BroadcastChannel' in window) {
    // Reload any hidden tabs when there's a sign in event on another
    bc = new BroadcastChannel('motorway-messages');

    bc.onmessage = (e) => {
      if (e.data && e.data.message) {
        if (e.data.message === motorwaySignInStr && document.hidden === true) {
          window.location.reload();
        }
      }
    };
  }
};

export const postBroadcast = () => {
  bc?.postMessage?.({
    message: motorwaySignInStr,
  });
};
