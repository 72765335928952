import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import calendar from 'dayjs/plugin/calendar';
import isoWeek from 'dayjs/plugin/isoWeek';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isToday from 'dayjs/plugin/isToday';
import QuarterOfYear from 'dayjs/plugin/quarterOfYear';
import relativeTime from 'dayjs/plugin/relativeTime';
import UTC from 'dayjs/plugin/utc';

import 'dayjs/locale/en-gb';

dayjs.locale('en-gb');
dayjs.extend(advancedFormat);
dayjs.extend(relativeTime);
dayjs.extend(UTC);
dayjs.extend(calendar);
dayjs.extend(QuarterOfYear);
dayjs.extend(isoWeek);
dayjs.extend(isToday);
dayjs.extend(isSameOrAfter);

export default dayjs;
