/* eslint-disable import/prefer-default-export */

export const VRM_CHECK_CODES = Object.freeze({
  BAD_REQUEST: 'bad_request',
  COMPLIANCE_SERVICE_ERROR: 'compliance_service_error',
  EXCEEDED: 'exceeded',
  INVALID_VRM: 'invalid_vrm',
  NOT_AVAILABLE: 'not_available',
  TOO_MANY_REQUESTS: 'too_many_requests',
  VEHICLE_ALREADY_ADDED: 'vehicle_already_added',
  VEHICLE_GENERATION_FAILURE: 'vehicle_generation_failure',
  VEHICLE_LOOKUP_FAILED: 'vehicle_lookup_failed',
});
