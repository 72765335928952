import { useEffect } from 'react';

import { getCookieValue, removeCookie, setCookie } from 'Utilities';
import { snowplowEvent } from 'Utilities/analytics';

export const useCookieCheck = () => {
  useEffect(() => {
    const cookieName = 'cookieSupportCheck';
    const { requestIdleCallback } = globalThis;

    const cookieCheck = () => {
      setCookie(cookieName, 'true', 1);
      const value = getCookieValue(cookieName);
      removeCookie(cookieName);

      if (!value) { // Safari will return '' if cookies are disabled
        snowplowEvent({
          data: {
            cookie_enabled_flag: navigator.cookieEnabled,
            no_cookies: true,
          },
          schema: 'cookies_disabled',
        });
      }
    };

    const timer = requestIdleCallback
      ? requestIdleCallback(cookieCheck, { timeout: 1000 })
      : setTimeout(cookieCheck, 0);

    return () => (requestIdleCallback
      ? cancelIdleCallback(timer)
      : clearTimeout(timer));
  }, []);
};
