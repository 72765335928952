/* eslint-disable no-console */
// https://stackoverflow.com/a/14555361
const storage = (name: string) => ({
  getItem: (key: string, defVal?: any) => {
    let value = defVal;

    try {
      value = JSON.parse(window[`${name}Storage` as keyof Window].getItem(key));
    } catch (e) {
      console.error(e);
    }

    return value;
  },
  removeItem: (key: string) => {
    try {
      window[`${name}Storage` as keyof Window].removeItem(key);
    } catch (e) {
      console.error(e);
    }
  },

  setItem: (key: string, value: any) => {
    try {
      window[`${name}Storage` as keyof Window].setItem(key, JSON.stringify(value));
    } catch (e) {
      console.error(e);
    }
  },
});

export const safeLocalStorage = storage('local');
export const safeSessionStorage = storage('session');
