import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import T from 'i18n-react';

import { Content, LogoAffiliate } from '@motorway/motorway-storybook-cra';

import texts from '../../../texts.json';

import styles from './AffiliateHeader.scss';

T.setTexts(texts);

const AffiliateHeader = ({
  affiliate,
}) => (
  <Content
    className={styles.component}
    wrapper={{ className: styles.wrapper }}
  >
    <div>{T.translate('components.affiliateHeader.title')}</div>
    <LogoAffiliate
      affiliate={affiliate}
      className={cx(styles.affiliateLogo, styles[affiliate])}
    />
  </Content>
);

AffiliateHeader.propTypes = {
  affiliate: PropTypes.string.isRequired,
};

export default AffiliateHeader;
