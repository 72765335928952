import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';

import { ConfigContext } from 'Context/config';

const useBrandName = () => {
  const params = useParams();
  const [brandName, setBrandName] = useState();
  const { configState: { brandData = {} } } = useContext(ConfigContext);

  useEffect(() => {
    const { brand, brands } = brandData || {};

    if (brand) {
      const { brand: { display_name: name } } = brandData;
      setBrandName(name);
    } else if (brands?.length && params.brand) {
      const { display_name: name } = brands.find(({ slug }) => slug === params.brand);
      setBrandName(name);
    }
  }, [brandData, params.brand]);

  return brandName;
};

export default useBrandName;
