import { useEffect } from 'react';

import { SNOWPLOW_TRIGGER } from 'Utilities/analytics';

import {
  useBroadcast,
  useCookieCheck,
  useGA,
  useOptimizely,
  useSnowplow,
  useUTM,
} from './initHooks';

const Init = () => {
  useBroadcast();
  useCookieCheck();
  useGA();
  useOptimizely();
  useSnowplow();
  useUTM();

  // Must be last
  useEffect(() => {
    SNOWPLOW_TRIGGER('trackPageView');
  }, []);

  return (null);
};

export default Init;
