import { useEffect } from 'react';

import { GA_VARIABLES } from 'Utilities/analytics';
import { BUYER_ID_LOOKUP, BUYER_SLUGS, ctbEligibility } from 'Utilities/buyers';

export const checkMarketPlaceEligibility = (data) => {
  if (typeof data?.marketplaceEligible?.eligible === 'boolean' && Number.isInteger(data?.enquiry?.mileage)) {
    if (data?.marketplaceEligible?.eligible) {
      GA_VARIABLES.partnerId = BUYER_ID_LOOKUP[BUYER_SLUGS.motorway];
    } else if (ctbEligibility(data)) {
      GA_VARIABLES.partnerId = BUYER_ID_LOOKUP[BUYER_SLUGS.ctb];
    } else {
      GA_VARIABLES.partnerId = BUYER_ID_LOOKUP[BUYER_SLUGS.tcbg];
    }
  }
};

export const useSetGaMarketplaceEligible = (vehicleLookupState) => useEffect(() => {
  checkMarketPlaceEligibility(vehicleLookupState);
}, [vehicleLookupState?.marketplaceEligible?.eligible, vehicleLookupState?.enquiry?.mileage, vehicleLookupState]);
