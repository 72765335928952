import { VRM_REGEX } from 'Utilities/helpers';

import { VehicleLookupState } from '../vehicles/@types';

interface SNOWPLOW_CONTEXTS_INTERFACE {
  enquiry: {},
  user_customer: { // eslint-disable-line camelcase
    data: {
      login_persisted: Boolean | undefined // eslint-disable-line camelcase
    }
  },
  vehicle: {}
}

const SNOWPLOW_CONTEXTS: SNOWPLOW_CONTEXTS_INTERFACE = {
  enquiry: {},
  user_customer: { data: { login_persisted: undefined } },
  vehicle: {},
};

const VALUE_NOT_FOUND = 'N/A';

export const SNOWPLOW_USER_CUSTOMER_CONTEXT = (user: any) => {
  window?.snowplow?.('removeGlobalContexts', [SNOWPLOW_CONTEXTS.user_customer]);

  const loginPersisted = () => {
    if (SNOWPLOW_CONTEXTS.user_customer?.data?.login_persisted) {
      return SNOWPLOW_CONTEXTS.user_customer?.data?.login_persisted;
    }

    return !user.login_persisted;
  };

  if (!user) {
    return;
  }

  const data = {
    data: {
      logged_in: true,
      login_persisted: loginPersisted(),
      login_type: 'token',
      marketing_consent: user.emailCrmTier,
      user_customer_id: user.id,
    },
    schema: 'iglu:uk.co.motorway/user_customer/jsonschema/1-0-0',
  };

  window?.snowplow?.('addGlobalContexts', [data]);

  SNOWPLOW_CONTEXTS.user_customer = data;
};

// Only send vehicle context on vehicle pages
const snowplowVehicleContextFilter = () => globalThis.location.pathname.split('/').some((fragment) => [/^mileage$/, VRM_REGEX].some((path) => (path.test(fragment))));

export const SNOWPLOW_VEHICLE_CONTEXT = (vehicle: VehicleLookupState) => {
  window?.snowplow?.('removeGlobalContexts', [[snowplowVehicleContextFilter, SNOWPLOW_CONTEXTS.vehicle]]);

  if (!vehicle?.id) {
    return;
  }

  const data = {
    data: {
      body_type: vehicle.body,
      colour: vehicle.colour,
      eligible_for_motorway: vehicle?.enquiry?.marketplaceEligible?.eligible,
      fuel_type: vehicle.fuel,
      make: vehicle?.make?.slug,
      mileage: vehicle?.enquiry?.mileage,
      model: vehicle.model,
      model_year: vehicle.year,
      plt_vehicle_id: vehicle.id,
      vin: VALUE_NOT_FOUND,
      vrm: vehicle.vrm,
    },
    schema: 'iglu:uk.co.motorway/vehicle/jsonschema/1-0-1',
  };

  window?.snowplow?.('addGlobalContexts', [[snowplowVehicleContextFilter, data]]);

  SNOWPLOW_CONTEXTS.vehicle = data;
};

export const SNOWPLOW_ENQUIRY_CONTEXT = (vehicle: VehicleLookupState) => {
  window?.snowplow?.('removeGlobalContexts', [[snowplowVehicleContextFilter, SNOWPLOW_CONTEXTS.enquiry]]);

  if (!vehicle?.enquiry?.id) {
    return;
  }

  const { enquiry } = vehicle;

  const data = {
    data: {
      dp_enquiry_id: enquiry.dpEnquiryId,
      dp_enquiry_status: enquiry.dpStateSlug,
      plt_enquiry_id: enquiry.id,
      plt_enquiry_status: enquiry.status,
    },
    schema: 'iglu:uk.co.motorway/enquiry/jsonschema/1-0-0',
  };

  window?.snowplow?.('addGlobalContexts', [[snowplowVehicleContextFilter, data]]);

  SNOWPLOW_CONTEXTS.enquiry = data;
};
