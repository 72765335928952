import { GENERIC_ANALYTICS_EVENTS } from 'Utilities/analytics/analyticsGenericEvents';

export const LANDING_PAGE_SECTIONS_NAMES = {
  ADVERT: 'ad',
  DEALER_LOGOS: 'dealer_logos',
  FAQ: 'faq',
  GUIDES: 'guides',
  HERO: 'hero',
  HOW_IT_WORKS: 'how_it_works',
  RECENT_SALES: 'recent_sales',
  RECOMMENDED_BY: 'recommended_by',
  RELATED_GUIDES: 'related_guides',
  SEO: 'seo_content_blocks',
  TRUSTPILOT_WIDGET: 'trustpilot',
  USP: 'usp',
  VRM_INPUT: 'vrm_input',
} as const;

export const LANDING_PAGE_CATEGORY = 'landing_page';

type sectionLoadProps = {
  label?: string,
  name: typeof LANDING_PAGE_SECTIONS_NAMES[keyof typeof LANDING_PAGE_SECTIONS_NAMES],
};

export const LANDING_PAGE_EVENTS = {
  HERO_VRM_INPUT_FOCUSED: () => GENERIC_ANALYTICS_EVENTS.CLICK_EVENT({
    category: LANDING_PAGE_CATEGORY,
    name: LANDING_PAGE_SECTIONS_NAMES.VRM_INPUT,
  }),

  SECTION_LOAD: ({ label, name }: sectionLoadProps) =>
    GENERIC_ANALYTICS_EVENTS.SECTION_LOAD_EVENT({
      category: LANDING_PAGE_CATEGORY,
      label,
      name,
    }),
} as const;
