import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import { MDText } from 'i18n-react';

import { Content, LogoMotorway } from '@motorway/motorway-storybook-cra';
import { Button } from '@motorway/mw-highway-code';
import { Hyperlink } from '@motorway/mw-highway-code/alpha';
import { BUTTON_AS_TYPE, SIZE, VARIANT } from '@motorway/mw-highway-code/enums';

import cypressIds from 'CypressId';

import { GA_TAGS } from 'Utilities/analytics';
import { applyCypressData } from 'Utilities/index';
import { PRO_URL } from 'Utilities/urls';
import useInView from 'Utilities/useInView';

import Texts from '../../../../../texts.json';
import useShowNewHomepage from '../../../homepage/Hooks/useShowNewHomepage';
import LogoPress from '../LogoPress/LogoPress';

import { aboutRoutes, ancillaryRoutes, applicationRoutes, pressRoutes, siteRoutes, socialRoutes, toolsRoutes } from './routes';

import styles from './FooterLinks.scss';

const LocalT = new MDText(Texts);

const claimsLink = (<Hyperlink
  data-thc-hyperlink
  as={BUTTON_AS_TYPE.ANCHOR}
  href="/claims"
  label='motorway.co.uk/claims'
  size={SIZE.SMALL}
  target="_blank"
  variant={VARIANT.SECONDARY}
/>);

const texts = {
  ctaPro: LocalT.translate('footerView.pro.button') as string,

  disclaimerDetail: LocalT.translate('footerView.company.disclaimer.detail') as string,
  disclaimerTerms: LocalT.translate('footerView.company.disclaimer.title', { link: claimsLink }) as string,
  disclaimerTermsDoubleAsterisk: LocalT.translate('footerView.company.disclaimer.doubleAsterisk', { link: claimsLink }) as string,
  disclaimerTrackerDetail: LocalT.translate('footerView.company.disclaimer.trackerDetail') as string,
  footerAbout: LocalT.translate('footerView.siteLinks.about') as string,
  footerPressTitle: LocalT.translate('footerView.press.title') as string,
  footerSelling: LocalT.translate('footerView.siteLinks.selling') as string,
  footerTools: LocalT.translate('footerView.siteLinks.tools') as string,
};

type Props = {
  showDisclaimer: boolean;
  showDisclaimerDetail: boolean;
  showTrackerDisclaimer: boolean;
};

type ContentType = {
  external?: boolean;
  isSellMyElectric?: boolean;
  link: string;
  title: string;
}

const getLink = (
  page: {
    external?: boolean;
    link: string;
    newNavigation?: boolean;
    title: string;
  },
  {
    ahrefClassName = '',
    container: Container = React.Fragment,
    containerClassName = '',
  } = {} as {
    ahrefClassName: string;
    container: string | React.ComponentType;
    containerClassName: string;
  },
) => {
  const titleProps = typeof Container === 'string' ? { className: cx(containerClassName) } : {};
  const title = (<Container { ...titleProps }>{page.title}</Container>);

  return page?.external
    ? (
      <a className={cx(ahrefClassName)} href={page.link} rel="noreferrer noopener" target="_blank" title={page.title}>
        {title}
      </a>
    ) : (
      <Link className={cx(ahrefClassName)} to={page.link}>
        {title}
      </Link>
    );
};

const FooterLinks = ({ showDisclaimer, showDisclaimerDetail, showTrackerDisclaimer }: Props) => {
  const [loadImages, setLoadImages] = useState<Boolean>(false);
  const $container = useRef();
  const { showNewHomepage } = useShowNewHomepage();

  const visible = useInView($container, {
    disconnectOnVisible: true,
    rootMargin: '400px',
  });

  const siteAndAboutRoutes = [
    {
      content: aboutRoutes as ContentType[],
      title: texts.footerAbout,
    },
    {
      content: siteRoutes as ContentType[],
      title: texts.footerSelling,
    },
    {
      content: toolsRoutes as ContentType[],
      title: texts.footerTools,
    },
  ];

  useEffect(() => {
    if (visible) {
      setLoadImages(true);
    }
  }, [visible]);

  return (
    <div className={cx(styles.footer)} {...applyCypressData(cypressIds.sections.footerLinks)}>
      <Content
        ref={$container}
        center
        className={styles.press}
        element="section"
        wrapper={{ className: cx(styles.component, styles.pressBackground) }}
      >
        <h4>
          <span data-nosnippet>
            { texts.footerPressTitle }
          </span>
        </h4>

        <ul>
          {pressRoutes.map((p) => (
            <li key={p.name}>
              <LogoPress publication={loadImages ? p.name : ''} />
            </li>
          ))}
        </ul>
      </Content>

      <Content
        className={styles.siteLinks}
        element="section"
        wrapper={{ className: cx(styles.component, styles.siteLinksWrapper) }}
      >
        <div className={styles.social}>
          <div className={styles.flexColumn}>
            <LogoMotorway className={styles.logo} />
            <ul className={styles.socialLinks}>
              {socialRoutes.map(({ icon: IconComponent, link, name, title }) => (
                <li key={name}>
                  <a
                    className={name}
                    href={link}
                    onClick={() => GA_TAGS.FOOTER_SOCIAL_LINK_CLICKED()}
                    rel="noopener"
                    title={title}
                  > {<IconComponent />}
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <div className={styles.flexRowColumn}>
            <ul className={styles.appStoreLinks}>
              {
                applicationRoutes.map(({ description, icon, link, name }) => (
                  <li key={name}>
                    <a className={styles.link} href={link} target='_blank'>
                      <img alt={description} height={icon.height} loading='lazy' src={icon.src} width={icon.width}/>
                    </a>
                  </li>
                ))}
            </ul>
          </div>
        </div>
        {
          siteAndAboutRoutes
            .map((section) => (
              <div key={section.title} className={cx(
                { [styles.siteAndAboutLinks]: section.title === texts.footerAbout },
              )}>
                <h4 className={styles.headingFeature}>
                  <span data-nosnippet>
                    { section.title }
                  </span>
                </h4>
                <ul className={cx({ [styles.aboutRoutes]: section.title === texts.footerAbout })}>
                  {section.content
                    .map((c: ContentType) => (
                      <li key={c.title}>
                        { getLink(c, { ahrefClassName: 'mw-i', container: 'span', containerClassName: 'mw-link' }) }
                      </li>
                    ))}
                </ul>
              </div>
            ))
        }
      </Content>

      <Content
        center
        className={styles.extraLinks}
        element="section"
        wrapper={{ className: styles.component }}
      >
        <div data-theme="dark">
          <Button
            data-thc-button
            fullWidth
            as="a"
            href={PRO_URL}
            icon="chevron"
            label= {texts.ctaPro}
            onClick={() => GA_TAGS.FOOTER_DEALER_BUTTON_CLICKED()}
            target="_blank"
            variant="secondary"
          />
        </div>
        <ul className={styles.ancillaryPages}>
          {
            ancillaryRoutes
              .map((a) => (
                <li key={a.title}>
                  { getLink(a) }
                </li>
              ))
          }
        </ul>
        <p className={styles.copyright}>
          {LocalT.translate('footerView.company.copyrightMessage', {
            year: new Date().getFullYear(),
          })}
        </p>
        { showDisclaimer && <p className={styles.disclaimer}>{ texts.disclaimerTerms }</p> }
        { showDisclaimer && showNewHomepage
          && <p className={styles.disclaimer}>{ texts.disclaimerTermsDoubleAsterisk }</p>
        }
        { showDisclaimerDetail && <p className={styles.detail}>{ texts.disclaimerDetail }</p> }
        { showTrackerDisclaimer && <p className={styles.detail}>{ texts.disclaimerTrackerDetail }</p> }
      </Content>
    </div>
  );
};

export default FooterLinks;
