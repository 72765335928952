import routing from 'Utilities/routing';
import { VALUATION_TRACKER_ROUTES } from 'Utilities/wordpress';

export const CUSTOMER_HUB_VALUATION_ROUTE = VALUATION_TRACKER_ROUTES[0];
export const CUSTOMER_HUB_MILEAGE_ROUTE = `${CUSTOMER_HUB_VALUATION_ROUTE}/mileage`;
export const CUSTOMER_HUB_RESULT_ROUTE = `${CUSTOMER_HUB_VALUATION_ROUTE}${routing.vrmPath}`;
export const CUSTOMER_HUB_SIGN_IN = `${CUSTOMER_HUB_VALUATION_ROUTE}/sign-in`;

export const CUSTOMER_HUB_PATHS = [
  CUSTOMER_HUB_VALUATION_ROUTE,
  CUSTOMER_HUB_MILEAGE_ROUTE,
  CUSTOMER_HUB_RESULT_ROUTE,
  CUSTOMER_HUB_SIGN_IN,
];

export const VALUATION_DURATIONS = [
  {
    label: '3M',
    month: 3,
  },
  {
    label: '6M',
    month: 6,
  },
  {
    label: '1Y',
    month: 12,
  },
  {
    isDefault: true,
    isHiddenOnTiny: true,
    label: '2Y',
    month: 24,
  },
  {
    label: 'ALL',
    month: 0,
  },
];

export const MAX_VEHICLE_DISPLAY_ACCOUNT = 10;

export const SIMILAR_VEHICLES_TO_DISPLAY = 3;

export const API_INTENTS = Object.freeze({
  ERROR: 'fail',
  SUCCESS: 'success',
});

export const SUBSCRIPTION_INTENTS = Object.freeze({
  REJECT: 'reject',
  SUBSCRIBE: 'subscribe',
});

export const SUBSCRIPTION_BANNER_KEY = 'hideSubscriptionBanner';

export const API_ERRORS = {
  GET_USER_ERROR: {
    VRM_NOT_FOUND: 'vrm_not_found_after_refetch',
  },
  POST_VALUATION_ERROR: {
    VEHICLE_PRICING_FAILED: 'vehicle_pricing_failed',
  },
};

export const REFER_BANNER_REDIRECT_LINK = '/refer?utm_source=mentionme&utm_medium=affiliate&utm_campaign=referafriend_cvtbanner&situation=cvtbanner';
