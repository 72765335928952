import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { classNamePropType } from 'Utilities/propTypes';

import SvgAnimationBox from '../svgAnimationBox/SvgAnimationBox';

import styles from './Steps.less';

const StepsAnimation = ({
  className, config, showImages = false, startAnimation = false,
}) => (
  <div className={cx(styles.steps, className)}>
    {config.map((item) => {
      const {
        animationRepeatTime,
        animationStartTime,
        id,
        svg,
        text,
        time,
      } = item;

      return (
        <div key={time} className={styles.stepsCard}>
          <SvgAnimationBox
            animate={startAnimation}
            className={styles.innerSvg}
            repeatTime={`${animationRepeatTime}s`}
            showSvg={showImages}
            startTime={`${animationStartTime}s`}
            {...{ id, svg }}
          />
          <h3>{time}</h3>
          <p>{text}</p>
        </div>
      );
    })}
  </div>
);

StepsAnimation.propTypes = {
  className: classNamePropType,
  config: PropTypes.arrayOf(PropTypes.object).isRequired,
  showImages: PropTypes.bool,
  startAnimation: PropTypes.bool,
};

export default StepsAnimation;
