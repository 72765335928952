import React from 'react';
import cx from 'classnames';

import styles from './RecentSaleImage.scss';

const RecentSaleImage = ({ className, dimensions, src, vehicleModel }) => {
  const { height, width } = dimensions;

  const imgOpts = { h: 8 * 17, w: 8 * 17 };
  const imgixUrl = `${src}&h=${imgOpts.h}&w=${imgOpts.w}&auto=format`;

  return (
  <div className={cx(styles.image, className)}>
    <img
      alt={ vehicleModel }
      height={height}
      loading="lazy"
      src={imgixUrl}
      srcSet={[2, 3]
        .map(
          (dpr) => `${imgixUrl}&dpr=${dpr} ${dpr}x`,
        )
        .join(', ')}
      width={width}
    />
  </div>);
};

export default RecentSaleImage;
