import React, { useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useLocation } from 'react-router';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { MDText } from 'i18n-react';

import { Input } from '@motorway/motorway-storybook-cra';
import { Button } from '@motorway/mw-highway-code';
import { BUTTON_TYPE, SIZE, VARIANT } from '@motorway/mw-highway-code/enums';

import cypressIds from 'CypressId';

import { vehicleType } from 'Utilities/helpers';
import { applyCypressData } from 'Utilities/index';

import useHeroVrmInput from '../hooks/useHeroVrmInput';

import LocalTexts from './VrmInputTexts.json';

import styles from './VrmInputVRM.scss';

const LocalT = new MDText(LocalTexts);

// TODO - Move these over to RFF such as '../mileageInput/MileageInput.jsx'
const VrmInputVRM = ({
  analyticsEvents = {},
  loadingHandler = () => { },
  // Stop TS complaining about the unused parameters till converted to tsx
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  onError = (vrm, errorCode, setWrongVrm) => { },
  onSuccess = () => { },
  required = true,
  setShowMileage = () => { },
  valuationHub = false,
  vrm = '',
}) => {
  const [html5validation, setHtml5validation] = useState(true);

  const location = useLocation();

  const {
    getErrorMsg,
    hasApiError: isApiError,
    loading,
    onFormSubmit,
    onInputBlur,
    onInputChange,
    onInputFocus,
    showIntent,
    vrmValue,
    wrongVRM,
  } = useHeroVrmInput({
    loadingHandler,
    onError,
    onSuccess,
    setShowMileage,
    valuationHub,
    vrm,
  });

  const placeholder = LocalT.translate('vrmChecker.placeholder');
  const ctaNonErrorLabel = LocalT.translate(valuationHub
    ? 'cta.startTracking'
    : 'cta.valueCar', { vehicle: vehicleType(location.pathname) });

  const ctaLabel = (isApiError || wrongVRM)
    ? LocalT.translate('cta.again')
    : ctaNonErrorLabel;

  const commonInputAttributes = {
    ...applyCypressData(cypressIds.fields.vrmInputField),
    onBlur: onInputBlur,
    onChange: ({ currentTarget: { value } }) => onInputChange(value),
    onFocus: () => {
      analyticsEvents?.onInputFocus?.();
      analyticsEvents?.onLandingPageInputFocus?.();
      onInputFocus();
    },
    placeholder,
  };

  useEffect(() => {
    setHtml5validation(false);
  }, []);

  return (
    <>
      <Form
        onSubmit={() => onFormSubmit()}
        render={({ handleSubmit }) => (
          <form
            className={styles.component}
            data-cy="vrmInputForm"
            noValidate={!html5validation}
            onSubmit={handleSubmit}
          >
            <Field
              id='vrm-input'
              name='vrm-input'
            >
              { ({ id }) => (
                <Input
                  large
                  noIcon
                  vrm
                  className={ cx(styles.input, {
                    [styles.error]: isApiError,
                  }) }
                  data-cy="invalidVrm"
                  feedbackTooltip={(!wrongVRM && {
                    content: LocalT.translate(`vrmChecker.${getErrorMsg()}`),
                    placement: 'top',
                  }) || undefined}
                  inputProps={{
                    id,
                    input: {
                      ...commonInputAttributes,
                      'aria-label': 'vrm-input',
                      autoCapitalize: 'characters',
                      autoComplete: 'off',
                      maxLength: 10,
                      name: id,
                      readOnly: loading,
                      required,
                      type: 'text',
                      value: vrmValue,
                    },
                  }}
                  intent={showIntent()}
                />)}
            </Field>
            <Button
              data-thc-button
              fullWidth
              icon="chevron"
              label= {ctaLabel}
              loading={loading}
              onClick={() => analyticsEvents?.onButtonClick?.()}
              size={ SIZE.LARGE }
              type={BUTTON_TYPE.SUBMIT}
              variant={VARIANT.PRIMARY}
              {...applyCypressData(cypressIds.buttons.valueCarButton)}
            />
          </form>
        )}
      />
    </>
  );
};

VrmInputVRM.propTypes = {
  analyticsEvents: PropTypes.shape(),
  isHomePageRedesign: PropTypes.bool,
  loadingHandler: PropTypes.func,
  onError: PropTypes.func,
  onSuccess: PropTypes.func,
  setShowMileage: PropTypes.func,
  valuationHub: PropTypes.bool,
  vrm: PropTypes.string,
};

export default VrmInputVRM;
