import { snowplowEvent } from 'Utilities/analytics/analytics';

type NAME_AND_CATEGORY = {
  category: string;
  name: string;
};

type API_TRIGGERED_EVENT_PAYLOAD = {
  action: string;
  // eslint-disable-next-line camelcase
  backend_entity: string;
  // eslint-disable-next-line camelcase
  backend_service: string;
  success: boolean;
  value?: boolean | number | string | null;
}

export type CTA_CLICK_EVENT_PAYLOAD = NAME_AND_CATEGORY & {
  // eslint-disable-next-line camelcase
  button_label: string;
  label?: string | null;
  success?: boolean | null;
  url: string;
};

type CLICK_EVENT_PAYLOAD = NAME_AND_CATEGORY & {
  label?: string | null;
  url?: string | null;
};

type HOVER_EVENT_PAYLOAD = NAME_AND_CATEGORY & {
  message: string | null;
};

type LOAD_EVENT_PAYLOAD = NAME_AND_CATEGORY & {
  label?: string | null;
};

type TOGGLE_CLICK_EVENT_PAYLOAD = NAME_AND_CATEGORY & {
  label?: string | null;
  value: boolean;
};

export const GENERIC_ANALYTICS_EVENTS = {
  API_TRIGGERED: (payload: API_TRIGGERED_EVENT_PAYLOAD) => snowplowEvent({
    data: payload,
    schema: 'backend_trigger',
    version: '1-0-1',
  }),
  CLICK_EVENT: (payload: CLICK_EVENT_PAYLOAD) => snowplowEvent({
    data: payload,
    schema: 'click',
    version: '2-0-0',
  }),
  CTA_CLICK: (payload: CTA_CLICK_EVENT_PAYLOAD) => snowplowEvent({
    data: payload,
    schema: 'cta_clicked',
    version: '5-0-1',
  }),
  HOVER_EVENT: (payload: HOVER_EVENT_PAYLOAD) => snowplowEvent({
    data: payload,
    schema: 'hover',
    version: '2-0-1',
  }),
  POPUP_LOAD_EVENT: (payload: LOAD_EVENT_PAYLOAD) => snowplowEvent({
    data: payload,
    schema: 'popup_appear',
  }),
  SECTION_LOAD_EVENT: (payload: LOAD_EVENT_PAYLOAD) => snowplowEvent({
    data: payload,
    schema: 'load',
  }),
  TOGGLE_CLICK_EVENT: (payload: TOGGLE_CLICK_EVENT_PAYLOAD) => snowplowEvent({
    data: payload,
    schema: 'toggle_click',
    version: '2-0-1',
  }),
} as const;
