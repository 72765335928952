import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { LoadingSpinner } from '@motorway/motorway-storybook-cra';

import styles from './Loader.less';

const Loader = ({ className, text }) => (
  <div className={cx(styles.loader, className)} data-testid="loader">
    <LoadingSpinner size="large" />
    { text && (
      <h4 className={cx(styles.loaderText, 'mw-title-title5')}>
        {text}
      </h4>
    )}
  </div>
);

Loader.propTypes = {
  className: PropTypes.string,
  text: PropTypes.node,
};

export default Loader;
