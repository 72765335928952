import React from 'react';
import PropTypes from 'prop-types';

import ThemeContext from './ThemeContext';
import ThemeDark from './ThemeDark';
import ThemeDefault from './ThemeDefault';

import './ThemeBase.less';

class Theme extends React.Component {
  constructor(props) {
    super(props);

    this.renderWrappedChildren = this.renderWrappedChildren.bind(this);

    this.themes = ['default', 'dark'];

    if (props.theme === 'dark') {
      this.themes.reverse();
    }

    this.state = {
      theme: this.themes[0],
    };
  }

  changeTheme() {
    this.setState({
      theme: this.themes.reverse()[0],
    });
  }

  renderWrappedChildren(children) {
    return React.Children.map(children, (child) => {
      // This is support for non-node elements (eg. pure text), they have no props
      if (!child || !child.props) {
        return child;
      }

      if (child.props.children) {
        const props = {
          children: this.renderWrappedChildren(child.props.children),
        };

        if (typeof child.type !== 'string') {
          props.changeTheme = this.changeTheme.bind(this);
        }

        return React.cloneElement(child, props);
      }

      if (typeof child.type === 'string') {
        return child;
      }

      return React.cloneElement(child, {
        changeTheme: this.changeTheme.bind(this),
      });
    });
  }

  render() {
    const { children, doNotAlterChildren = true, ...p } = this.props;
    const { theme } = this.state;
    const Element = (theme === 'dark') ? ThemeDark : ThemeDefault;
    /**
     * NOTE: Seller webapp does not like it's children altered, atm changing theme is not a priority,
     * Post lauch this should be fixed. 02/04/2019 / Rob
     */
    const content = (doNotAlterChildren) ? children : this.renderWrappedChildren(children);

    return (
      <ThemeContext.Provider value={{
        theme,
      }}
      >
        <Element {...p}>
          { content }
        </Element>
      </ThemeContext.Provider>
    );
  }
}

Theme.propTypes = {
  children: PropTypes.node.isRequired,
  doNotAlterChildren: PropTypes.bool,
  theme: PropTypes.string,
};

export default Theme;
