import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from './Theme.less';

const ThemeDefault = (props) => {
  const {
    children = [], className, ...p
  } = props; // eslint-disable-line no-unused-vars
  return (
    <div className={cx(styles.theme, className)} {...p}>
      {children}
    </div>
  );
};

ThemeDefault.propTypes = {
  children: PropTypes.shape(),
  className: PropTypes.func,
};

export default ThemeDefault;
