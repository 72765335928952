import React from 'react';

import { MDText } from 'i18n-react';

import { Content } from '@motorway/motorway-storybook-cra';
import { Hyperlink } from '@motorway/mw-highway-code/alpha';
import { VARIANT } from '@motorway/mw-highway-code/enums';
import { MotorwayLogoWordmarkIcon } from '@THC/components/Icons/MotorwayLogoWordmark';

import { SP_HEADER_EVENTS } from 'Utilities/analytics/analyticsHeader';

import { AccountLinks } from './AccountLinks';
import { HeaderLinks } from './HeaderLinks';
import LocalTexts from './HeaderTexts.json';

import styles from './Header.scss';

const LocalT = new MDText(LocalTexts);
const t = (key: string) => LocalT.translate(key) as string;

export const Header = () => (
  <>
    <div className={styles.headerBackground} />
    <header className={styles.header} id="app-header">
      <Content className={styles.header}>
        <Hyperlink
          data-thc-link-logo
          aria-label={t('logoText')}
          as='a'
          href='/'
          label={<MotorwayLogoWordmarkIcon />}
          onClick={() => SP_HEADER_EVENTS.HEADER_LOGO_CLICKED()}
          variant={VARIANT.SECONDARY}
        />
        <div className={styles.menu}>
          <HeaderLinks />
          <AccountLinks />
        </div>
      </Content>
    </header>
  </>
);
