import React, {
  useEffect, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { MDText } from 'i18n-react';

import { classNamePropType } from 'Utilities/propTypes';

import { useBreakpoint } from '../misc/Breakpoints/Breakpoints';
import Slider from '../slider/Slider';

import Texts from './text/howTo.json';
import Steps from './Steps';
import StepsAnimation from './StepsAnimation';

import styles from './StepsSection.less';

const T = new MDText(Texts);

const StepsSection = ({
  analyticsConfig,
  config,
  isAffiliate = false,
  sliderClassName,
  stepsClassName,
  title = T.translate('title'),
  withAnimation = false,
}) => {
  const breakpoints = useBreakpoint();
  // 1040 content space + (40px * 2) padding
  const containerRef = useRef();
  const [showImages, setShowImages] = useState(false);
  const [startAnimation, setStartAnimation] = useState(false);

  const contentBreakpoint = breakpoints.minWidth.breakpointContent;

  const Component = withAnimation ? StepsAnimation : Steps;

  const setVisibleandPos = (el) => {
    const { intersectionRatio, isIntersecting } = el;
    setStartAnimation(intersectionRatio >= 0.7 && isIntersecting);

    if (isIntersecting) {
      setShowImages(true);
    }
  };

  useEffect(() => {
    let observer;
    if (('IntersectionObserver' in window) && withAnimation) {
      observer = new IntersectionObserver((entries) => {
        entries.forEach((el) => {
          setVisibleandPos(el);
        });
      }, {
        rootMargin: '20% 0px',
        threshold: [0, 0.7],
      });
      observer.observe(containerRef.current);
    }

    return () => {
      observer?.disconnect();
    };
  }, [containerRef, withAnimation]);

  const renderContent = () => {
    if (contentBreakpoint) {
      return (
        <Component
          className={stepsClassName}
          config={config}
          {...{ showImages, startAnimation }}
        />
      );
    }

    return (
      <Slider
        className={cx(sliderClassName, {
          [stepsClassName]: isAffiliate,
        })}
        config={config}
        title={title}
        {...{ analyticsConfig, showImages, startAnimation, withAnimation }}
      />
    );
  };

  return (
    <div ref={containerRef} className={styles.stepsSection}>
      <h2 className={cx('mw-title-title2', styles.title)}>
        {title}
      </h2>
      {renderContent()}
    </div>
  );
};

StepsSection.propTypes = {
  analyticsConfig: PropTypes.objectOf(PropTypes.string),
  config: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isAffiliate: PropTypes.bool,
  sliderClassName: classNamePropType,
  stepsClassName: classNamePropType,
  title: PropTypes.string,
  withAnimation: PropTypes.bool,
};

export default StepsSection;
