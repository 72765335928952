import { GENERIC_ANALYTICS_EVENTS } from 'Utilities/analytics/analyticsGenericEvents';

interface checkEventBasicProps {
  category: string;
  label?: string;
  name: typeof CHECKER_TOOLS_PAGE_SECTIONS_LIST[keyof typeof CHECKER_TOOLS_PAGE_SECTIONS_LIST];
}

interface clickEventProps extends Omit<checkEventBasicProps, 'name'> {
  name: typeof CHECKER_TOOLS_PAGE_SECTIONS_LIST[keyof typeof CHECKER_TOOLS_PAGE_SECTIONS_LIST] | string;
  url?: string;
}

interface checkPerformedProps {
  category: string;
  serviceType: string;
  status: boolean;
  value?: string;
  vrm: string;
}

interface ctaClickProps extends Omit<checkEventBasicProps, 'name'> {
  buttonLabel: string;
  name: typeof CHECKER_TOOLS_PAGE_SECTIONS_LIST[keyof typeof CHECKER_TOOLS_PAGE_SECTIONS_LIST] | string;
  success?: boolean;
  url: string;
}

interface signUpSubmitProps {
  id?: number;
  success: boolean;
  value: Record<string, unknown>;
}

interface popupEventProps extends Omit<checkEventBasicProps, 'name'>{
  name: typeof CHECKER_TOOLS_PAGE_SECTIONS_LIST[keyof typeof CHECKER_TOOLS_PAGE_SECTIONS_LIST] | string;
}

export enum CHECKER_TOOLS_PAGE_SECTIONS_LIST {
  'CHARGES' = 'charges_blocks',
  'FAQ' = 'faq',
  'GUIDE' = 'guide',
  'MORE_WAYS' = 'more_ways_to_sell',
  'MOT_GUIDE' = 'mot_guide',
  'REFER_FRIENDS' = 'refer_friends',
  'RESULTS' = 'value_car',
  'SEO' = 'seo_blocks',
  'TOOLS_TILE' = 'whats_new_tile',
  'USP' = 'usp',
}

export enum CHECK_TOOLS_CATEGORIES_LIST {
  'motCheck' = 'mot_check',
  'motCheckResults' = 'mot_check_results',
  'taxCheck' = 'tax_check',
  'taxCheckResults' = 'tax_check_results',
}

export const MOT_CHECK_NOTIFICATIONS_NAME = 'mot_notifications';
export const TAX_CHECK_NOTIFICATIONS_NAME = 'tax_notifications';

export const CHECK_PERFORMED_SERVICE_TYPE = {
  MOT: 'mot_history',
  TAX: 'tax_check',
};

export const CHECKER_TOOLS_EVENTS = {
  CHECK_PERFORMED: ({ category, serviceType, status, value, vrm }: checkPerformedProps) =>
    GENERIC_ANALYTICS_EVENTS.API_TRIGGERED({
      action: category,
      backend_entity: vrm,
      backend_service: serviceType,
      success: status,
      value,
    }),
  CLICKED: ({ category, label, name, url }: clickEventProps) =>
    GENERIC_ANALYTICS_EVENTS.CLICK_EVENT({
      category,
      label,
      name,
      url,
    }),
  CTA_CLICKED: ({ buttonLabel, category, label, name, success, url }: ctaClickProps) =>
    GENERIC_ANALYTICS_EVENTS.CTA_CLICK({
      button_label: buttonLabel,
      category,
      label,
      name,
      success,
      url,
    }),
  POPUP_LOADED: ({ category, label, name }: popupEventProps) =>
    GENERIC_ANALYTICS_EVENTS.POPUP_LOAD_EVENT({
      category,
      label,
      name,
    }),
  SECTION_LOADED: ({ category, label, name }: checkEventBasicProps) =>
    GENERIC_ANALYTICS_EVENTS.SECTION_LOAD_EVENT({
      category,
      label,
      name,
    }),
  SIGN_UP_SUBMITTED: ({ id, success, value }: signUpSubmitProps) =>
    GENERIC_ANALYTICS_EVENTS.API_TRIGGERED({
      action: 'create',
      backend_entity: id?.toString() ?? 'N/A',
      backend_service: 'plt_customer',
      success,
      value: JSON.stringify({
        ...value,
        isCarValueTracker: false,
      }),
    }),
};
