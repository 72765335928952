import {
  getUserLogOut,
  getUserSession,
  postCreateNewUser,
  postUserUpdateInfo,
} from 'PrivateAPI';

import { ConfigStateStatic } from 'Context/config';
import { establishCognitoSession, UserDispatch, UserStateStatic } from 'Context/user';

import { postBroadcast } from 'Utilities/broadcastChannel';

const updateUser = (user) => {
  const { vehicles, ...seller } = user;
  UserDispatch.actions.update(seller);
};

const setCrmPreferences = (consent) => (consent ? 'marketing' : 'service');

export const updateUserInfoAction = async (payload) => {
  const { state: { featureFlags } } = ConfigStateStatic;

  const { showCognitoLogin } = featureFlags || {};

  const useCognito = showCognitoLogin && UserStateStatic.state.cognitoId !== null;

  const {
    changed,
    code,
    customer,
    error: apiError,
    message,
  } = await postUserUpdateInfo(payload)
    .catch((error) => ({ error }));

  const result = {
    changed,
    customer,
    error: apiError || code,
    message,
  };

  if (result.error) { // API Error
    window?.Sentry?.captureException?.(new Error(apiError));
    return result;
  }

  if (Number.isInteger(customer?.id)) {
    updateUser(customer);
  }

  if (['auth_token', 'login_token', 'email'].some((val) => result.changed?.[val])) {
    await getUserLogOut();
  }

  if (useCognito) {
    const { error: loginError, success: loginSuccess } = await establishCognitoSession(
      UserStateStatic.state.cognitoTokens,
    );
    if (loginSuccess) {
      postBroadcast();
    } else {
      const error = loginError || new Error('Cognito Login unsuccessful');
      result.error = error;
      window?.Sentry?.captureException?.(new Error(error));
    }
    return result;
  }

  const { error: sessionError } = await getUserSession({ token: customer.login_token })
    .catch((error) => ({ error: error?.message || error }));

  if (sessionError) {
    result.message = sessionError?.toString?.() || '';
    result.error = sessionError;

    window?.Sentry?.captureException?.(new Error(sessionError));
  } else {
    postBroadcast();
  }

  return result;
};

export const createNonEnquirySignUpAction = async ({
  consent,
  email,
  name,
  phone,
}) => {
  const {
    data: customer, error: apiError, status, statusMessage,
  } = await postCreateNewUser({
    consent,
    email,
    emailCrmTier: setCrmPreferences(consent?.consent),
    name,
    phone,
  }).catch((error) => ({ error }));
  const result = {
    customer,
    error: (status === 'failure' || apiError?.message) && { message: statusMessage ?? apiError.message },
    status,
    statusMessage,
  };
  if (result.error) {
    return result;
  }

  if (customer) {
    updateUser(customer);
    await getUserSession({ token: customer.login_token });
  }

  return result;
};

export const removeUser = async () => {
  await getUserLogOut()
    .catch((err) => {
      throw new Error(err);
    });

  window.location.href = '/'; // Force a page load
};
