import { logger } from 'logger';

import { LOGGING_LEVELS, timeoutFetchSentryGuard } from './fetch';

export const userAPIErrorHandler = async (err: any) => {
  const { captureException } = globalThis?.Sentry || {};
  const errorLevel = err?.message === 'No token provided.' ? LOGGING_LEVELS.WARN : LOGGING_LEVELS.ERROR;

  const errorDetails = ['apiEndpointError', 'User Endpoint', { error: err?.message }];

  if (captureException) {
    timeoutFetchSentryGuard(err, () => {
      captureException(new Error('Error in User API'), {
        extra: {
          error: err?.message,
        },
        level: errorLevel,
        tags: {
          fetch: 'user endpoint error',
        },
      });
    });
  } else if (logger?.[errorLevel]) {
    logger[errorLevel](...errorDetails);
  } else {
    console.error(...errorDetails); // eslint-disable-line no-console
  }

  return Promise.reject(err);
};
