import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import VrmChecker from '../VrmChecker/VrmChecker';
import VrmUlezChecker from '../VrmUlezChecker/VrmUlezChecker';

const VrmCheckerFooter = forwardRef(({ useUlezVrmChecker = false, ...props }, ref) => (
  useUlezVrmChecker
    ? <VrmUlezChecker ref={ref} hideErrors inFooter />
    : <VrmChecker ref={ref} {...props} />
));

VrmCheckerFooter.propTypes = {
  useUlezVrmChecker: PropTypes.bool,
};

export default VrmCheckerFooter;
