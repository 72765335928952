export const AUTHENTICATION_URLS = Object.freeze({
  FORGOT_PASSWORD: '/forgot-password',
  PASSWORD_RESET: '/forgot-password/reset',
});

export const formFieldToolTip = (content: string) => ({
  content,
  placementFlipped: 'bottom',
  zIndex: 9,
}) as const;

export const EMAIL_PROVIDERS = Object.freeze({
  AOL: ['AOL', 'https://mail.aol.co.uk/', null],
  BT: ['BT Internet', 'https://www.bt.com/email', null],
  GMAIL: ['Gmail', 'https://mail.google.com/mail/u/0/#inbox', 'googlegmail://'],
  OUTLOOK: ['Outlook', 'https://outlook.live.com/mail/#/inbox', 'ms-outlook://'],
  SKY: ['Sky', 'https://skyid.sky.com/signin/email', null],
  YAHOO: ['Yahoo', 'https://mail.yahoo.com/', 'ymail://'],
});

export const LOGIN_EMAIL_API_RESPONSES = Object.freeze({
  SENT_LOGIN_EMAIL: 'Sent login email',
});

export type PageTitleProps = {
  title: string;
  strap: string;
};

export const PASSWORD_RESET_ERRORS = {
  INVALID_EMAIL: 'Username/client id combination not found.',
};

export const PASSWORD_RESET_CONFIRM_ERRORS = {
  INVALID_EMAIL: 'Username/client id combination not found.',
  INVALID_PASSWORD: 'Password does not conform to policy: Password not long enough',
  INVALID_VERIFICATION_CODE_REQUEST_NEW: 'Invalid code provided, please request a code again.',
  INVALID_VERIFICATION_CODE_TRY_AGAIN: 'Invalid verification code provided, please try again.',
};

export const COGNITO_SYSTEM_ERRORS = {
  ATTEMPT_LIMIT_EXCEEDED: 'Attempt limit exceeded, please try after some time.',
};
