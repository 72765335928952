import { GAEvent, PAGES, snowplowEvent } from './analytics';
import { GENERIC_ANALYTICS_EVENTS } from './analyticsGenericEvents';
import GA_VARIABLES from './variables';

export const getAdditionalParams = (data = {}) => {
  const { vrm } = GA_VARIABLES || {};
  const VALUE_NOT_FOUND = 'N/A';

  return {
    data: {
      vin: VALUE_NOT_FOUND,
      vrm,
      ...data,
    },
  };
};

// eslint-disable-next-line import/prefer-default-export
export const VALUATIONS_GA_TAGS = Object.freeze({
  CONFIRM_MILEAGE_MODAL: (data) => snowplowEvent({
    ...getAdditionalParams(data),
    schema: 'smv_upperfunnel_mileagecheck_arrival',
  }),

  CONFIRM_MILEAGE_MODAL_CANCELLED: (vrm) => snowplowEvent({
    ...getAdditionalParams({ vrm }),
    schema: 'smv_upperfunnel_mileagecheck_cancel_click',
  }),

  CONFIRM_MILEAGE_MODAL_SUBMIT: (data) => snowplowEvent({
    ...getAdditionalParams(data),
    schema: 'smv_upperfunnel_mileagecheck_submit',
    version: '1-0-1',
  }),

  CONFIRM_MILEAGE_MODAL_SUBMITTED: (data) => snowplowEvent({
    ...getAdditionalParams(data),
    schema: 'smv_upperfunnel_mileagecheck_submit_success',
  }),

  ELIGIBILITY_SET: ({ eligible, type, ...data }) => {
    GAEvent({
      eventAction: 'Eligibility set',
      eventCategory: PAGES.ENQUIRY,
      eventLabel: type,
    });

    snowplowEvent({
      ...getAdditionalParams({
        eligible_for_motorway: eligible,
        plt_buyer_id: type,
        ...data,
      }),
      schema: 'smv_upperfunnel_eligibilitycheck_success',
    });
  },

  ELIGIBLITY_SET_FAILURE: (data) => snowplowEvent({
    ...getAdditionalParams(data),
    schema: 'smv_upperfunnel_eligibilitycheck_error',
  }),

  ENQUIRY_CREATED: (enquiry = {}) => {
    GAEvent({
      event: 'enquiry_created',
      eventAction: 'Enquiry Created (valuation catalogue)',
      eventCategory: PAGES.ENQUIRY,
      eventLabel: enquiry.id,
    });
    snowplowEvent({
      ...getAdditionalParams({
        eligible_for_motorway: enquiry?.marketplaceEligible?.eligible,
        login_type: 'token',
        mileage: enquiry.mileage,
        plt_buyer_id: GA_VARIABLES.partnerId,
        plt_enquiry_id: enquiry.id,
        user_customer_id: enquiry.customerId,
      }),
      schema: 'smv_upperfunnel_plt_enquiry_created',
      version: '3-0-0',
    });
  },

  HERO_ESTIMATED_MILEAGE_TOOLTIP_SHOWN: () => GAEvent({
    eventAction: 'Estimated mileage tooltip opened',
    eventCategory: PAGES.HERO,
  }),

  HERO_INVALID_VRM_WARNING_SHOWN: (vrm) => {
    GAEvent({
      eventAction: 'Invalid VRM warning displayed',
      eventCategory: PAGES.HERO,
    });
    snowplowEvent({
      data: { vrm },
      schema: 'smv_upperfunnel_vrm_submit_error',
    });
  },

  HERO_MILEAGE_BUTTON_CLICKED: () => GAEvent({
    eventAction: 'Mileage button clicked',
    eventCategory: PAGES.HERO,
  }),

  HERO_MILEAGE_BUTTON_CLICKED_SNOWPLOW: (data) => snowplowEvent({
    ...getAdditionalParams(data),
    schema: 'smv_upperfunnel_mileage_submit',
    version: '1-0-1',
  }),

  HERO_MILEAGE_INPUT_FOCUSED: () => GAEvent({
    eventAction: 'Mileage input focussed',
    eventCategory: PAGES.HERO,
  }),

  HERO_MILEAGE_NOT_MY_CAR_CLICKED: () => {
    GAEvent({
      eventAction: 'That\'s not my car clicked',
      eventCategory: PAGES.HERO,
    });
  },

  HERO_MILEAGE_NOT_MY_CAR_CLICKED_SNOWPLOW: (vrm) => {
    snowplowEvent({
      ...getAdditionalParams({ vrm }),
      schema: 'smv_upperfunnel_vrmlookup_success_notmyvehicle',
    });
    snowplowEvent({
      ...getAdditionalParams({ vrm }),
      schema: 'smv_upperfunnel_mileage_notmyvehicle_click',
    });
  },

  HERO_MILEAGE_SHOWN: (vrm) => {
    GAEvent({
      eventAction: 'Mileage step displayed',
      eventCategory: PAGES.HERO,
    });
    snowplowEvent({
      ...getAdditionalParams({ vrm }),
      schema: 'smv_upperfunnel_mileage_arrival',
    });
  },

  HERO_MILEAGE_SKIPPED: () => GAEvent({
    eventAction: 'Mileage step skipped',
    eventCategory: PAGES.HERO,
  }),

  HERO_MILEAGE_SUBMITTED: (data) => {
    GAEvent({
      eventAction: 'Mileage submitted successfully',
      eventCategory: PAGES.HERO,
    });
    snowplowEvent({
      ...getAdditionalParams(data),
      schema: 'smv_upperfunnel_mileage_submit_success',
    });
  },

  HERO_VEHICLE_LOOKUP_COMPLETE: () => {
    GAEvent({
      eventAction: 'VRM submitted successfully',
      eventCategory: PAGES.HERO,
    });
  },

  HERO_VEHICLE_LOOKUP_FAILED: (error = {}) => GAEvent({
    eventAction: 'Vehicle lookup failed',
    eventCategory: PAGES.HERO,
    eventLabel: error?.msg,
  }),

  HERO_VEHICLE_LOOKUP_FORM_SUCCESS: (vrm) => {
    snowplowEvent({
      data: { vrm },
      schema: 'smv_upperfunnel_vrm_submit_success',
    });
  },

  HERO_VRM_BUTTON_CLICKED: () => GAEvent({
    eventAction: 'VRM button clicked',
    eventCategory: PAGES.HERO,
  }),

  HERO_VRM_BUTTON_CLICKED_SNOWPLOW: (vrm) => snowplowEvent({
    data: { vrm },
    schema: 'smv_upperfunnel_vrm_submit',
  }),

  HERO_VRM_INPUT_FOCUSED: () => GAEvent({
    eventAction: 'VRM input focussed',
    eventCategory: PAGES.HERO,
  }),

  MARKETPLACE_START_BUTTON_CLICKED: ({ gaEventLabel, ...snowplowPayload }) => {
    GAEvent({
      eventAction: 'Start your profile clicked',
      eventCategory: PAGES.MARKETPLACE,
      eventLabel: gaEventLabel,
    });
    GENERIC_ANALYTICS_EVENTS.CTA_CLICK(snowplowPayload);
  },

  MILEAGE_ERROR_TOOLTIP: (data) => snowplowEvent({
    ...getAdditionalParams(data),
    schema: 'smv_upperfunnel_mileage_submit_error',
    version: '1-0-1',
  }),

  MILEAGE_MODAL_ERROR_TOOLTIP: ({ mileage }) => snowplowEvent({
    ...getAdditionalParams({ mileage }),
    schema: 'smv_upperfunnel_mileagecheck_submit_error',
    version: '1-0-1',
  }),

  VALUATION_ERROR: (data = {}) => snowplowEvent({
    ...getAdditionalParams({
      eligible_for_motorway: data.enquiry?.marketplaceEligible?.eligible,
      login_type: 'token',
      mileage: data.enquiry?.mileage,
      plt_buyer_id: data.buyer?.id,
      user_customer_id: GA_VARIABLES.userId,
    }),
    schema: 'smv_upperfunnel_valuation_error',
    version: '3-0-0',
  }),

  VALUATION_RETURNED: () => GAEvent({ event: 'valuation_returned' }),

  VALUATION_SHOWN: (data) => snowplowEvent({
    ...getAdditionalParams({
      eligible_for_motorway: data.eligible,
      login_type: 'token',
      mileage: data.mileage,
      plt_buyer_id: data.buyerId,
      user_customer_id: GA_VARIABLES.userId,
    }),
    schema: 'smv_upperfunnel_valuation_arrival',
    version: '3-0-0',
  }),

  VALUATION_SUCCESS: (data) => snowplowEvent({
    ...getAdditionalParams({
      eligible_for_motorway: data.eligible,
      login_type: 'token',
      mileage: data.mileage,
      plt_buyer_id: data.buyerId,
      user_customer_id: GA_VARIABLES.userId,
    }),
    schema: 'smv_upperfunnel_valuation_success',
    version: '2-0-0',
  }),

  VEHICLE_LOOKUP_COMPLETE: (vrm) => {
    GAEvent({ event: 'vehicle_lookup_complete' });
    snowplowEvent({
      ...getAdditionalParams({ vrm }),
      schema: 'smv_upperfunnel_vrmlookup_success',
    });
  },

  VEHICLE_LOOKUP_FAILED: (error = {}) => {
    GAEvent({
      event: 'vehicle_lookup_failed',
      eventLabel: error?.msg,
    });
    snowplowEvent({
      data: { ...error },
      schema: 'smv_upperfunnel_vrmlookup_error',
    });
  },
});
