import { gatewayGet, gatewayGetWithStatusCB, gatewayPost, gatewayPostWithStatusCB } from 'Fetch';

import { DPEnquiry } from 'Utilities/vehicles/@types';

import { PremiumOffer } from '../utilities/profiling/@types';

import { valuatePayload, valuateResponse } from './api.types';
import { platformPost } from './platformEndpoints';

// Platform top level endpoints (https://github.com/motorway/motorway-platform)
export const postValuate = async (payload: valuatePayload): valuateResponse => platformPost('/valuate', payload);

// EDA Vehicle Checker endpoints
export const getMOTVehicleCheck = async (vrm: string, env: 'prod' | 'stage', updateStatusCB?: (status?: number) => void, abortController?: AbortController) => gatewayGetWithStatusCB(`/vehicle-checker/${env}/mot/checker/${vrm}`, undefined, updateStatusCB, { abortController });

export const postMotNotifications = async (payload: { dpSellerId?: number; vrm?: string}, env: 'prod' | 'stage', updateStatusCB?: (status?: number) => void, abortController?: AbortController) => gatewayPostWithStatusCB(`/vehicle-checker/${env}/mot/notifications`, payload, undefined, updateStatusCB, { abortController });

// Dealership Platform endpoints (https://github.com/motorway/motorway-dealership-platform)
// Dealership Platform v3 endpoints
export const getEnquiryData = async (enquiryId: number, payload?: any, query = 'state,seller,vehicle,vehicleDetails'): Promise<DPEnquiry> => gatewayGet(`/premium-v3/enquiries/${enquiryId}`, { ...payload, ...(query.length && { include: query }) });
export const updateEnquiryData = async (enquiryId: number, payload: Partial<PremiumOffer>): Promise<DPEnquiry> => gatewayPost(`/premium-v3/enquiries/${enquiryId}/update`, { ...payload });

// Transaction Core endpoints (https://api-docs.motorway.co.uk/mw-transaction-core)
export const getShortUrl = async (enquiryId: number, payload?: {field: string; path: string}, abortController?: AbortController): Promise<Record<string, string>> => gatewayPost(`/transaction/${enquiryId}/generateShortUrl`, payload, undefined, { abortController });
