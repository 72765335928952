import { matchPath } from 'react-router';
import Cookies from 'js-cookie';

import T from 'i18n-react';

import Texts from '../texts.json';

import { URLQueryToString } from './URL';

T.setTexts(Texts);

export const blogPadUrl = (url, query) => {
  const status = {
    redirect: false,
    url: null,
  };

  const match = matchPath(url, '/blog/:year?/:month?/:day?/:post?', { exact: true });

  if (!match) {
    return status;
  }

  const singleDigitMMDD = [match.params.month, match.params.day].filter((val) => (val && val.length === 1));

  if (singleDigitMMDD.length > 0) {
    const p = { ...query };

    let paddedURL = Object.values(match.params).filter((val) => (val)).map((val) => ((!Number.isNaN(val)) ? val.padStart(2, 0) : val)).join('/');

    paddedURL = (Object.values(p).length > 0) ? `${paddedURL}?${URLQueryToString(p)}` : paddedURL;

    status.redirect = true;
    status.url = paddedURL;
  }

  return status;
};

export const canUseWebP = () => {
  const elem = document.createElement('canvas');

  if (elem && elem.getContext && elem.getContext('2d')) {
    // was able or not to get WebP representation
    return elem.toDataURL('image/webp').indexOf('data:image/webp') === 0;
  }

  // very old browser like IE 8, canvas not supported
  return false;
};

export const stripStringAndCamelCase = (s = '') => s.toLowerCase().replace(/[^a-z']/g, ' ').trim().split(/[ ]+/)
  .map((string) => string.charAt(0).toUpperCase() + string.substring(1))
  .join('');

export const setCookie = (name, value, days) => {
  const params = {
    path: '/',
  };

  if (days) {
    params.expires = days;
  }

  Cookies.set(name, value, params);
};

export const getCookieValue = (name) => Cookies.get(name) || '';

export const removeCookie = (name) => Cookies.remove(name);

export const titleCaseFirstLetter = (s) => s.charAt(0).toUpperCase() + s.substring(1);

export const wordpressImgToImgixURL = (content) => {
  const imgRegEx = /https?:\/\/(blog.motorway.co.uk|motorway.wpengine.com)\/wp-content\/uploads\/.*?\.(?:png|jpg|jpeg|gif|svg)/g;
  const wordpressImgPath = 'https://blog.motorway.co.uk/wp-content/uploads/';
  const wpEngineImgPath = /https?:\/\/motorway.wpengine.com\/wp-content\/uploads\//g;
  const imgixImgPath = 'https://motorway-blog.imgix.net/';

  let match;
  const imgURLs = [];

  // eslint-disable-next-line no-cond-assign
  while ((match = imgRegEx.exec(content)) !== null) {
    imgURLs.push(match[0]);
  }

  imgURLs.forEach((url) => {
    content = content.replace(url, `${url}?auto=compress,format`);
    content = content.replace(url, url.replace(wordpressImgPath, imgixImgPath));
    content = content.replace(url, url.replace(wpEngineImgPath, imgixImgPath));
  });

  return content;
};

export const sanitiseLinks = (content) => content?.replace(/href=("|')https?:\/\/(motorway.wpengine.com|blog.motorway.co.uk)\/?/g, 'href=$1/') || content;

export const applyCypressData = (name) => ({ 'data-cy': name });
