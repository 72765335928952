import { useContext, useEffect } from 'react';

import { OptimizelyContext } from '@optimizely/react-sdk';

import { onDecision, onTrack } from 'Utilities/analytics/optimizely/optimizely';
import { captureExceptionWithTeam } from 'Utilities/captureException/captureExceptionWithTeam';
import { TEAM } from 'Utilities/constants';

export const useOptimizely = () => {
  const { optimizely } = useContext(OptimizelyContext);

  useEffect(() => {
    // Setup listeners for Optimizely events
    const isOptimizelyInstanceValid = () => optimizely.getOptimizelyConfig() !== null;

    optimizely?.onReady().then(() => {
      if (isOptimizelyInstanceValid()) {
        [
          ['DECISION:type, userId, attributes, decisionInfo', onDecision],
          ['TRACK:event_key, user_id, attributes, event_tags, event', onTrack],
        ].forEach((e) => {
          optimizely.notificationCenter.addNotificationListener(...e);
        });
      }
    }).catch(() => {
      captureExceptionWithTeam(new Error('Optimizely instance was not ready'), TEAM.TURBO_CHARGE_FE, {
        level: 'warning',
        tags: {
          feature: 'optimizely',
        },
      });
    });
  }, [optimizely]);
};
