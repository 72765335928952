import { useEffect, useRef, useState } from 'react';

const useInView = ($ref, opts = {}) => {
  const [isElementIntersecting, setElementIntersecting] = useState(false);
  const options = useRef(opts);

  useEffect(() => {
    const { current: $element } = $ref;

    if ('IntersectionObserver' in window) {
      const { current: { disconnectOnVisible, ...observerOptions } } = options;

      const observer = new IntersectionObserver(
        ([entry]) => {
          const { isIntersecting } = entry;

          setElementIntersecting(isIntersecting);

          if (disconnectOnVisible && isIntersecting) {
            observer.disconnect($element);
          }
        },
        observerOptions,
      );

      if ($element) {
        observer.observe($element);
      }

      return () => observer.disconnect($element);
    }

    setElementIntersecting(true);

    return () => {};
  }, []);

  return isElementIntersecting;
};

export default useInView;
