import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { MDText } from 'i18n-react';

import {
  ChartIcon,
  Content,
  HomeIcon,
  PriceTagIcon,
  SmileyFaceIcon,
} from '@motorway/motorway-storybook-cra';

import { childrenPropType, classNamePropType } from 'Utilities/propTypes';

import { usePageRefEvents } from '../../../common/Hooks/usePageRefEvents';
import useBrandName from '../../hooks/useBrandName';

import LocalTexts from './UspTexts.json';

import styles from './Usp.scss';

const LocalT = new MDText(LocalTexts);

const USP = ({ children = null, className, onLoad = () => {} }) => {
  const brandName = useBrandName();
  const vehicleEnding = LocalT.translate('usp.point1.ending');

  const { setRef } = usePageRefEvents({ onRefTrigger: onLoad });

  const renderVehicleCopy = () => {
    if (brandName?.length) {
      const lastChar = brandName[brandName.length - 1];

      return lastChar === 's' ? `${brandName}’` : `${brandName}${vehicleEnding}`;
    }

    return `${LocalT.translate('usp.point1.car')}${vehicleEnding}`;
  };

  return (
    <Content
      center
      className={cx(className, styles.component)}
      wrapper={{ className: styles.componentWrapper }}
    >
      {children}
      <ul
        ref={(ref) => setRef(ref)}
        className={ styles.usp }
        data-testid='USP'
      >
        <li>
          <h4>{LocalT.translate('usp.point1.title')}</h4>
          <p>{LocalT.translate('usp.point1.detail', { vehicle: renderVehicleCopy() })}</p>
          <div className={styles.icon}>
            <ChartIcon />
          </div>
        </li>
        <li>
          <h4>{LocalT.translate('usp.point2.title')}</h4>
          <p>{LocalT.translate('usp.point2.detail')}</p>
          <div className={styles.icon}>
            <PriceTagIcon />
          </div>
        </li>
        <li>
          <h4>
            {LocalT.translate('usp.point3.title')}
          </h4>
          <p>{LocalT.translate('usp.point3.detail')}</p>
          <div className={styles.icon}>
            <HomeIcon />
          </div>
        </li>
        <li>
          <h4>{LocalT.translate('usp.point4.title')}</h4>
          <p>{LocalT.translate('usp.point4.detail')}</p>
          <div className={styles.icon}>
            <SmileyFaceIcon />
          </div>
        </li>
      </ul>
    </Content>
  );
};

USP.propTypes = {
  children: childrenPropType,
  className: classNamePropType,
  onLoad: PropTypes.func,
};

export default USP;
