import React, { createContext, useCallback, useEffect, useReducer } from 'react';

import { LOGGING_LEVELS } from 'Fetch';

import { Enquiry, Vehicle } from 'Utilities/vehicles/@types';
import { mapEligibilityToVehicles } from 'Utilities/vehicles/mapEligibilityToVehicle';
import { sortByEnquiryUpdatedAt } from 'Utilities/vehicles/sortBy';

type VehicleState = Vehicle[]

enum VehicleActionType {
  ADD_VEHICLE = 'ADD_VEHICLE',
  ADD_VEHICLE_WITH_ENQUIRY = 'ADD_VEHICLE_WITH_ENQUIRY',
  REMOVE_VEHICLE = 'REMOVE_VEHICLE',
  REPLACE_VEHICLES = 'REPLACE_VEHICLES',
  UPDATE_VEHICLE = 'UPDATE_VEHICLE'
}

type VehiclePayload = {
  ADD_VEHICLE: Vehicle;
  ADD_VEHICLE_WITH_ENQUIRY: {
    enquiry: Enquiry;
    vehicle: Vehicle;
  };
  REMOVE_VEHICLE: number;
  REPLACE_VEHICLES: Vehicle[];
  UPDATE_VEHICLE: Vehicle;
}

type VehicleAction = {
  payload:
  VehiclePayload['ADD_VEHICLE'] |
  VehiclePayload['ADD_VEHICLE_WITH_ENQUIRY'] |
  VehiclePayload['REMOVE_VEHICLE'] |
  VehiclePayload['REPLACE_VEHICLES'] |
  VehiclePayload['UPDATE_VEHICLE'];
  type: VehicleActionType;
}

type VehicleContextProps = {
  vehicleActions: {
    add: (payload: VehiclePayload['ADD_VEHICLE']) => void;
    addWithEnquiry: (payload: VehiclePayload['ADD_VEHICLE_WITH_ENQUIRY']) => void;
    remove: (payload: VehiclePayload['REMOVE_VEHICLE']) => void;
    replace: (payload: VehiclePayload['REPLACE_VEHICLES']) => void;
    updateVehicle: (payload: VehiclePayload['UPDATE_VEHICLE']) => void;
  };
  vehicleState: VehicleState;
}

type VehicleProviderProps = {
  children: React.ReactNode;
  value?: VehicleState;
}

const initialState: VehicleState = [];

const initialActions = {
  add: () => {},
  addWithEnquiry: () => {},
  remove: () => {},
  replace: () => {},
  updateVehicle: () => {},
};

const VehicleContext = createContext<VehicleContextProps>(
  { vehicleActions: initialActions, vehicleState: initialState },
);

// Export dispatch available outside of React
const VehicleDispatch: { actions: Partial<VehicleContextProps['vehicleActions']> } = {
  actions: initialActions,
};

const reducer = (state: VehicleState, { payload, type }: VehicleAction) => {
  const vehicleProviderError = (invalidPayload: any) => {
    const log = globalThis?.Sentry?.captureException || console.error; // eslint-disable-line no-console

    log(new Error('Invalid payload for VehicleProvider'), {
      extra: { invalidPayload },
      level: LOGGING_LEVELS.ERROR,
      tags: {
        store: 'vehicle provider error',
      },
    });
  };

  switch (type) {
    case VehicleActionType.ADD_VEHICLE: {
      const vehicle = payload as VehiclePayload['ADD_VEHICLE'];
      const otherVehicles = state.filter(({ id }) => (id !== vehicle.id));
      return [vehicle, ...otherVehicles];
    }
    case VehicleActionType.ADD_VEHICLE_WITH_ENQUIRY: {
      const { enquiry, vehicle } = payload as VehiclePayload['ADD_VEHICLE_WITH_ENQUIRY'];
      vehicle.enquiry = enquiry;
      const otherVehicles = state.filter(({ id }) => (id !== vehicle.id));
      return [vehicle, ...otherVehicles];
    }
    case VehicleActionType.REMOVE_VEHICLE: {
      const vehicleId = payload as VehiclePayload['REMOVE_VEHICLE'];
      return state.filter((vehicle) => (vehicle.id !== vehicleId));
    }
    case VehicleActionType.REPLACE_VEHICLES: {
      const validPayload = Array.isArray(payload) && payload.every((vehicle) => (vehicle.id));
      const replacementVehicles = validPayload ? payload : state as VehiclePayload['REPLACE_VEHICLES'];

      if (!validPayload) {
        vehicleProviderError(payload);
      }

      return [...(replacementVehicles || [])];
    }
    case VehicleActionType.UPDATE_VEHICLE: {
      const updatedVehicle = payload as VehiclePayload['UPDATE_VEHICLE'];
      const otherVehicles = state.filter((vehicle) => (vehicle.vrm !== updatedVehicle.vrm));
      return [updatedVehicle, ...otherVehicles];
    }
    default:
      window.Sentry?.captureException(new Error('Unexpected use of default switch case'), {
        // @ts-ignore
        level: 'warning',
      });
      return state;
  }
};

const VehicleProvider = ({ children, value }: VehicleProviderProps) => {
  const state = [...value || initialState];
  sortByEnquiryUpdatedAt(state);
  const formattedVehicles = mapEligibilityToVehicles(state);
  const [vehicleState, dispatch] = useReducer(reducer, formattedVehicles);

  useEffect(() => {
    if (window.Sentry) {
      window.Sentry.configureScope((scope: { getUser: Function; setUser: Function }) => {
        scope.setUser({
          ...(scope.getUser?.() || {}),
          vehicles: vehicleState.map(({ id, vrm }) => ({ id, vrm })),
        });
      });
    }
  }, [vehicleState]);

  const vehicleActions = {
    add: (payload: VehiclePayload['ADD_VEHICLE']) => {
      dispatch({ payload, type: VehicleActionType.ADD_VEHICLE });
    },
    addWithEnquiry: useCallback((payload: VehiclePayload['ADD_VEHICLE_WITH_ENQUIRY']) => {
      dispatch({ payload, type: VehicleActionType.ADD_VEHICLE_WITH_ENQUIRY });
    }, []),
    remove: useCallback((payload: VehiclePayload['REMOVE_VEHICLE']) => {
      dispatch({ payload, type: VehicleActionType.REMOVE_VEHICLE });
    }, []),
    replace: useCallback((payload: VehiclePayload['REPLACE_VEHICLES']) => {
      dispatch({ payload, type: VehicleActionType.REPLACE_VEHICLES });
    }, []),
    updateVehicle: useCallback((payload: VehiclePayload['UPDATE_VEHICLE']) => {
      dispatch({ payload, type: VehicleActionType.UPDATE_VEHICLE });
    }, []),
  };

  // Expose dispatch and state from outside React,
  VehicleDispatch.actions = Object.freeze(vehicleActions);

  return (
    <VehicleContext.Provider
      value={{
        vehicleActions,
        vehicleState,
      }}
    >
      {children}
    </VehicleContext.Provider>
  );
};

// Deprecated, to be removed when StoreProvider is migrated away from.
export const withVehicleContext = (Component: any) => (
  React.forwardRef((props, ref) => (
    <VehicleContext.Consumer>
      {(context) => <Component ref={ref} vehicleContext={context} {...props} />}
    </VehicleContext.Consumer>
  ))
);

export {
  VehicleContext,
  VehicleDispatch,
  VehicleProvider,
};
