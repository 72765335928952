import { useCallback, useContext, useState } from 'react';

import { postRefreshTokens } from 'PrivateAPI';

import { establishCognitoSession, UserContext } from 'Context/user';

import { REFRESH_TOKENS } from '../../constants';
import { logOutUser } from '../logOutUser';

let sessionInterval: NodeJS.Timer;
export const refreshTokenInterval = 60 * 1000 * REFRESH_TOKENS.INTERVAL_MINUTES;

const onRefreshTokenExpired = async (error: any) => {
  await logOutUser(error);
};

const onMaxRefreshTokenAttempts = async (error: any) => {
  await logOutUser(error);
};

export const useRefreshAccessToken = () => {
  const { userState } = useContext(UserContext);
  const [numberOfConsecutiveFailedAttempts, setNumberOfConsecutiveFailedAttempts] = useState(0);

  const refreshAccessTokens = useCallback(async () => {
    const refreshToken = userState?.cognitoTokens?.refreshToken;
    const username = userState?.email;

    const response = await postRefreshTokens({ refreshToken, username })
      .catch(async (error) => {
        if (error.message === REFRESH_TOKENS.ERROR.EXPIRED) {
          await onRefreshTokenExpired(error);
        } else {
          setNumberOfConsecutiveFailedAttempts(numberOfConsecutiveFailedAttempts + 1);
          const isFailedMaxAttempts = numberOfConsecutiveFailedAttempts === REFRESH_TOKENS.MAX_FAILED_ATTEMPTS;
          if (isFailedMaxAttempts) {
            await onMaxRefreshTokenAttempts(error);
          } else {
            window?.Sentry?.captureException?.(new Error(error));
          }
        }
      });

    if (response?.data) {
      setNumberOfConsecutiveFailedAttempts(0);
      await establishCognitoSession(response.data);
    }
  }, [userState?.cognitoTokens?.refreshToken, userState?.email, numberOfConsecutiveFailedAttempts]);

  const setCognitoRefreshTokenInterval = useCallback(async () => {
    clearInterval(sessionInterval);
    sessionInterval = setInterval(() => {
      refreshAccessTokens();
    }, refreshTokenInterval);
  }, [refreshAccessTokens]);

  return { setCognitoRefreshTokenInterval };
};
