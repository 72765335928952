import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import cx from 'classnames';

import { MDText } from 'i18n-react';

import { LogoMake } from '@motorway/motorway-storybook-cra';

import { getBrand } from 'PublicAPI';

import { ConfigContext } from 'Context/config';

import { ComponentContent, CONTENT_SIZES } from 'Layout';

import LocalTexts from './HomeVRMTexts.json';

import styles from './HomeVRM.scss';

const LocalT = new MDText(LocalTexts);

const heroTitleSuffix = (<span className={styles.noWrap}>{LocalT.translate('hero.brand.titleSuffix')}</span>);

const HomeVRMBrand = () => {
  const params = useParams();
  const { configActions: { update }, configState: { brandData } } = useContext(ConfigContext);
  const { display_name: displayName, slug } = brandData?.brand || {};
  const [loading, setLoading] = useState(!slug);
  const text = {
    heroTitle: (slug
      ? LocalT.translate('hero.brand.title', { displayName, suffix: heroTitleSuffix })
      : LocalT.translate('hero.home.title')),
  };

  useEffect(() => {
    if (!slug) {
      getBrand(params.brand).then((data) => {
        update({ brandData: data });
        setLoading(false);
      }).catch((err) => {
        setLoading(false);
        window?.Sentry?.captureException(new Error(err));
      });
    }
  }, [slug, params.brand, update]);

  return (
    <>
      <LogoMake
        className={cx(styles.brandLogo, { [styles.hideLogo]: !slug && !loading })}
        element="span"
        make={slug || ''}
      />
      <ComponentContent maxWidth={CONTENT_SIZES.content} paddings={true}>
        <h1 className={cx(styles.heroTitle, styles.brandPage, { [styles.hideTitle]: loading })}>
          {text.heroTitle}
        </h1>
      </ComponentContent>

      <ComponentContent maxWidth={ CONTENT_SIZES.large } paddings={ true }>
        <h3 className={ styles.heroStrap}>
          { LocalT.translate('hero.brand.strapline', { displayName }) }
        </h3>
      </ComponentContent>
    </>
  );
};

export default HomeVRMBrand;
