import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { MDText } from 'i18n-react';

import { VehicleLookupContext } from 'Context/vehicleLookup';

import {
  REVIEW_AUTHOR_MAX_LENGTH,
  REVIEW_BODY_MAX_LENGTH,
  REVIEW_TITLE_MAX_LENGTH,
  truncate,
} from 'Utilities/helpers';

import LocalTexts from '../trustpilotCommon/TrustpilotTexts.json';
import useTrustpilotCardHighlight from '../trustpilotCommon/useTrustpilotCardHighlight';
import useTrustpilotModal from '../trustpilotCommon/useTrustpilotModal';

import styles from './TrustpilotCardBespoke.less';

const LocalT = new MDText(LocalTexts);

const TrustpilotCardBespoke = ({
  brand = null,
  cardTitleTruncationLength = REVIEW_TITLE_MAX_LENGTH,
  cardTruncationLength = REVIEW_BODY_MAX_LENGTH,
  review,
}) => {
  const { vehicleLookupState: vehicle } = useContext(VehicleLookupContext);
  const { display_name: displayName } = brand || vehicle?.make || {};

  const { author, body } = review;

  const data = {
    displayName,
    review,
  };

  const { HighlightedBody, HighlightedTitle } = useTrustpilotCardHighlight({
    cardTitleTruncationLength,
    cardTruncationLength,
    ...data,
  });

  const { modalWrapper, triggerModalOpen } = useTrustpilotModal(data);

  return (
    <div className={styles.component}>
      <div className={styles.starRating}>{LocalT.translate('rating')}</div>
      <h3 className={styles.title}>{ HighlightedTitle }</h3>
      <p>
        { HighlightedBody }
        { ' ' }
        { body.length > cardTruncationLength ? (
          <a
            href="/"
            onClick={(e) => {
              e.preventDefault();
              triggerModalOpen();
            }}
          >
            {LocalT.translate('showMore')}
          </a>
        ) : null}
        { modalWrapper }
      </p>
      <div className={styles.cardFooter}>
        <p>{truncate(author, REVIEW_AUTHOR_MAX_LENGTH)}</p>
      </div>
    </div>
  );
};

TrustpilotCardBespoke.propTypes = {
  brand: PropTypes.shape({
    display_name: PropTypes.string,
    slug: PropTypes.string,
  }),
  cardTitleTruncationLength: PropTypes.number,
  cardTruncationLength: PropTypes.number,
  review: PropTypes.shape({
    author: PropTypes.string,
    body: PropTypes.string,
    make: PropTypes.string,
    rating: PropTypes.number,
    timestamp: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
};

export default TrustpilotCardBespoke;
