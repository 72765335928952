import { useCallback } from 'react';

import { SLUGS } from '@motorway/mw-enquiry-states/static';

import { timeoutFetchSentryGuard } from 'Fetch';
import { getEnquiryData } from 'PrivateApis';

import { useFeatureFlags } from '../../hooks';
import { RECYCLE_ENQUIRY_STATES, REDIRECT_TO_TIMELINE_STATUSES } from '../../restricted';
import { DPEnquiry, Vehicle, VehicleLookupState } from '../@types';

/**
 * Find an existing DP enquiry for user's vehicle, check if it is in selling state.
 */
const useFindVehicleEnquiry = () => {
  const { isFetchingEnquiryState, isSkippingCreateEnquiryForSellingStates } = useFeatureFlags();

  const getDealershipEnquiry = useCallback(async (vehicle?: Vehicle | VehicleLookupState) => {
    if (vehicle?.enquiry?.dpEnquiryId && isFetchingEnquiryState) {
      return getEnquiryData(vehicle.enquiry?.dpEnquiryId).catch(
        (err) => {
          timeoutFetchSentryGuard(err, () => window?.Sentry?.captureException(new Error(err), {
            extra: {
              enquiry: {
                dpEnquiryId: vehicle.enquiry?.dpEnquiryId,
                id: vehicle.enquiry?.id,
              },
              vehicle: {
                id: vehicle.id,
                mileage: vehicle?.enquiry?.mileage,
                vrm: vehicle.vrm,
              },
            },
            tags: {
              fetch: 'Fetch DP enquiry data to determine whether to recycle enquiry',
            },
          }));
          return undefined;
        },
      );
    }
    return undefined;
  }, [isFetchingEnquiryState]);

  const isEnquiryInSellingState = useCallback((vehicle: Vehicle | VehicleLookupState, dpEnquiry?: DPEnquiry) => {
    if ((RECYCLE_ENQUIRY_STATES as (string|undefined)[]).includes(dpEnquiry?.state?.slug)) {
      window?.Sentry?.captureException(new Error(`Enquiry is in ${dpEnquiry?.state?.slug} state, so create-enquiry endpoint was not run (if isSkippingCreateEnquiryForSellingStates is true), and vehicle is directed to post QA page`), {
        extra: {
          dpEnquiry: {
            id: dpEnquiry?.id,
            sellerId: dpEnquiry?.sellerId,
            state: dpEnquiry?.state,
          },
          enquiry: {
            id: vehicle.enquiry?.id,
          },
          flags: {
            isFetchingEnquiryState,
            isSkippingCreateEnquiryForSellingStates,
          },
          vehicle: {
            id: vehicle.id,
            mileage: vehicle.enquiry?.mileage,
            vrm: vehicle.vrm,
          },
        },
        level: 'info',
        tags: {
          dpEnquiryId: dpEnquiry?.id,
          platformEnquiryId: vehicle.enquiry?.id,
          sellerId: dpEnquiry?.sellerId,
          stateSlug: dpEnquiry?.state?.slug,
          vrm: vehicle.vrm,
        },
      });
      return true;
    }
    return false;
  }, [isFetchingEnquiryState, isSkippingCreateEnquiryForSellingStates]);

  const getRedirectValueForSellingState = (vehicle: Vehicle | VehicleLookupState, stateSlug: string) => {
    if (!(RECYCLE_ENQUIRY_STATES as string[]).includes(stateSlug)) {
      return undefined;
    }
    const isStatusTimeline = (REDIRECT_TO_TIMELINE_STATUSES as string[]).includes(stateSlug);
    switch (stateSlug) {
      case SLUGS.UNDER_OFFER:
        return `/${vehicle.vrm}/accept-offer`;
      case SLUGS.SOLD_WAIT:
        return `/${vehicle.vrm}/confirm-sale?valuation_ref=${vehicle.enquiry?.dpEnquiryId}`;
      case SLUGS.SOLD_CONFIRMED:
        return `/${vehicle.vrm}/confirm-sale?valuation_ref=${vehicle.enquiry?.dpEnquiryId}`;
      case SLUGS.SOLD_DOCUMENTS_REVIEWED:
        return `/${vehicle.vrm}/review-status`;
      default:
        return isStatusTimeline ? `/${vehicle.vrm}/profile/timeline` : `/${vehicle.vrm}`;
    }
  };

  return { getDealershipEnquiry, getRedirectValueForSellingState, isEnquiryInSellingState };
};

export default useFindVehicleEnquiry;
