import { GENERIC_ANALYTICS_EVENTS } from 'Utilities/analytics/analyticsGenericEvents';

import { SELL_MY_CAR_PATH } from '../wordpress';

export const FOOTER_CATEGORY = 'footer';

type VrmCheckProps = {
  buttonLabel: string;
  label: string;
};

type VrmCheckLoadProps = {
  label: string;
};

export const SP_FOOTER_EVENTS = {
  FOOTER_VRM_CHECKER_LOADED: ({ label }: VrmCheckLoadProps) => GENERIC_ANALYTICS_EVENTS.SECTION_LOAD_EVENT({
    category: FOOTER_CATEGORY,
    label,
    name: 'sell_my_car',
  }),
  FOOTER_VRM_CHECKER_SUBMITTED: ({ buttonLabel, label }: VrmCheckProps) => GENERIC_ANALYTICS_EVENTS.CTA_CLICK({
    button_label: buttonLabel,
    category: FOOTER_CATEGORY,
    label,
    name: 'sell_my_car',
    url: SELL_MY_CAR_PATH,
  }),
};
