const objStr = {}.toString();

// Grabbed the existing GTM variables, now we can set them at a global level
// Instead of having to rely on callbacks everywhere
const gaVariables = [
  'email',
  'enquiryId',
  'offerAmount',
  'partnerId',
  'phone_number',
  'postcode',
  'userId',
  'utm_campaign',
  'utm_medium',
  'utm_source',
].reduce((acc: { [key: string]: number | string | undefined }, value: string) => {
  acc[value] = undefined;
  return acc;
}, {});

const GA_VARIABLES = new Proxy(gaVariables, {
  set: (obj, prop, value) => {
    obj[prop as string] = value;

    let dataValue = value?.toString?.() || value;

    if (dataValue === objStr) { // Stop `[object Object]`
      dataValue = value;
    }

    window?.dataLayer?.push?.({ [prop]: dataValue });

    return true;
  },
});

export default GA_VARIABLES;
