import React, { useEffect, useRef } from 'react';
import { withRouter } from 'react-router';
import ReactRouterPropTypes from 'react-router-prop-types';
import cx from 'classnames';

import { MDText } from 'i18n-react';

import { Content } from '@motorway/motorway-storybook-cra';
import { Hyperlink } from '@motorway/mw-highway-code/alpha';
import { BUTTON_AS_TYPE, SIZE, VARIANT } from '@motorway/mw-highway-code/enums';

import { getCookieValue, setCookie } from 'Utilities';
import { useFullState } from 'Utilities/hooks';

import LocalTexts from './CookieWarningTexts.json';

import styles from './CookieWarning.scss';

const LocalT = new MDText(LocalTexts);

const CookieWarning = ({ history }) => {
  const [state, setState] = useFullState({
    dismissed: false,
    loaded: false,
  });

  const historyRef = useRef(history);
  const historyUnlistenRef = useRef();

  const dismiss = useRef(() => {
    const { hasCookie } = state;

    setState({
      dismissed: true,
    });

    if (!hasCookie) {
      setCookie('cookieConsent', 'true', 365);
    }

    historyUnlistenRef.current?.();
  });

  useEffect(() => {
    const hasCookie = (getCookieValue('cookieConsent') === 'true');

    setState({
      dismissed: hasCookie,
      hasCookie,
      loaded: true,
    });

    if (hasCookie) {
      return () => {};
    }

    historyUnlistenRef.current = historyRef.current.listen(() => {
      dismiss.current();
      historyUnlistenRef.current();
    });

    return () => historyUnlistenRef.current();
  }, [setState]);

  const { dismissed, loaded } = state;

  if (dismissed || !loaded) {
    return null;
  }

  return (
    <Content center className={styles.component} element="section" theme="dark" wrapper={{ className: cx(styles.wrapper) }}>
      <div data-testid='cookie-warning'>
        {LocalT.translate('text', {
          link:
            <Hyperlink
              data-thc-hyperlink
              as={BUTTON_AS_TYPE.ANCHOR}
              data-testid='cookie-link'
              href="/cookies"
              label={LocalT.translate('cookiePolicy')}
              size={SIZE.MEDIUM}
              target="_blank"
              variant={VARIANT.TERTIARY}
            />,
        })}
      </div>
      <button aria-label='Dismiss cookie warning' className={styles.dismiss} onClick={dismiss.current} type="button"/>
    </Content>
  );
};

CookieWarning.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
};

export default withRouter(CookieWarning);
