import { useContext, useEffect, useRef, useState } from 'react';

import { ConfigContext } from 'Context/config';
import { UserContext } from 'Context/user';

import { toggleZendeskVisibility } from 'Utilities/zendeskWidget';

import { getZendeskTokenAndCreateSession } from 'Services/privateApi';

import { getSnowplowIds } from 'Valuations/Hooks/useCreateEnquiryAction';

import { zeKey, zendeskRetry, zendeskSessionFieldId, zendeskSnowplowSessionFieldId } from './ZendeskWidget.helpers';

export const ZENDESK_EVENT = 'zendeskWidgetLoad';

const getSnowplowSessionId = () => getSnowplowIds()?.snowplowSessionId;

const ZendeskWidget = () => {
  const { configState: { zendeskWidgetState } } = useContext(ConfigContext);

  const { userState: user } = useContext(UserContext);

  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const prevZendeskWidgetState = useRef(zendeskWidgetState.show ?? false);

  const zendeskFieldsSet = useRef(false);
  const zendeskTimerCount = useRef(0);
  const zendeskTimerRef = useRef(null);
  const isUserValid = Number.isInteger(user?.id);

  useEffect(() => {
    if (loaded && !isUserValid) {
      globalThis?.zE('messenger', 'logoutUser');
    }
  }, [loaded, isUserValid]);

  const handleZendeskLogout = () => {
    globalThis?.zE('messenger', 'logoutUser');
  };

  useEffect(() => {
    const setZendeskSnowplowSessionField = () => {
      if (zendeskFieldsSet.current) {
        return;
      }

      const snowplowSessionId = getSnowplowSessionId();

      if (!snowplowSessionId && zendeskTimerCount.current < 120) {
        zendeskTimerRef.current = zendeskRetry(setZendeskSnowplowSessionField);
        zendeskTimerCount.current += 1;
        return;
      }

      if (typeof snowplowSessionId !== 'string') {
        return;
      }

      globalThis?.zE('messenger:set', 'conversationFields', [{
        id: zendeskSnowplowSessionFieldId, value: snowplowSessionId,
      }]);

      zendeskFieldsSet.current = true;
    };

    const setZendeskSessionIdField = async (sessionToken) => {
      globalThis?.zE('messenger:set', 'conversationFields', [{
        id: zendeskSessionFieldId, value: sessionToken,
      }]);
    };

    const handleZendeskLogin = async () => {
      if (!isUserValid) {
        return;
      }
      globalThis?.zE('messenger', 'loginUser', async (callback) => {
        try {
          const { sessionId, token } = await getZendeskTokenAndCreateSession();
          setZendeskSessionIdField(sessionId);
          callback(token);
        } catch (error) {
          window?.Sentry?.captureException(new Error(error?.message ?? 'Failed to login user to Zendesk'));
          globalThis?.zE('messenger', 'logoutUser');
        }
      });
    };

    if (loaded) {
      globalThis?.zE('messenger:on', 'open', handleZendeskLogin);
      globalThis?.zE('messenger:on', 'close', handleZendeskLogout);
      setZendeskSnowplowSessionField();
    }
  }, [loaded, zendeskFieldsSet, isUserValid]);

  useEffect(() => () => clearTimeout(zendeskTimerRef.current), []);

  useEffect(() => {
    const handleError = (err) => {
      setLoading(false);
      window?.Sentry?.captureException(new Error(err), {
        level: 'warning',
      });
      console.error(err); // eslint-disable-line no-console
    };

    if (!loading && zendeskWidgetState.show) {
      setLoading(true);

      const s = document.createElement('script');
      s.id = 'ze-snippet';
      s.src = `https://static.zdassets.com/ekr/snippet.js?key=${zeKey}`;
      s.type = 'text/javascript';
      s.onload = () => {
        setLoaded(true);

        const event = new Event(ZENDESK_EVENT);
        document.dispatchEvent(event);
      };
      s.onerror = () => handleError('Zendesk widget script has failed to load');

      document.head.append(s);
    }
  }, [loading, zendeskWidgetState.show]);

  useEffect(() => {
    const listenZendesk = () => {
      toggleZendeskVisibility(zendeskWidgetState);
    };

    if (!globalThis?.zE) {
      document.addEventListener(ZENDESK_EVENT, listenZendesk, { once: true });
    }

    return () => document.removeEventListener(ZENDESK_EVENT, listenZendesk, { once: true });
  }, [zendeskWidgetState]);

  useEffect(() => {
    const { show } = zendeskWidgetState;
    const { current: prevShow } = prevZendeskWidgetState;

    if (show !== prevShow) {
      toggleZendeskVisibility(zendeskWidgetState);
    }

    prevZendeskWidgetState.current = zendeskWidgetState.show;
  }, [zendeskWidgetState]);

  return (null);
};

export default ZendeskWidget;
