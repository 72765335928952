import { Enquiry } from './vehicles/@types';

const ctb = 'CarTakeBack';
const motorway = 'Motorway Marketplace';
const tcbg = 'The Car Buying Group';

const CTB_AGE_LOWER_THRESHOLD = 28;
const CTB_MILEAGE_LOWER_THRESHOLD = 280_000;

export const BUYER_SLUGS = {
  ctb,
  motorway,
  tcbg,
} as const;

export const BUYER_SLUG_LOOKUP = {
  [ctb]: 'CarTakeBack',
  [motorway]: 'Marketplace',
  [tcbg]: 'The Car Buying Group',
} as const;

export const BUYER_ID_LOOKUP = {
  [ctb]: 7,
  [motorway]: 9,
  [tcbg]: 1,
} as const;

export const TP_TCBG_OPTIONS = {
  businessUnitId: '5a229d4f0000ff0005b22978',
  microStarId: '5419b732fbfb950b10de65e5',
  microTrustScoreId: '5419b637fa0340045cd0c936',
  url: 'https://uk.trustpilot.com/review/thecarbuyinggroup.co.uk',
} as const;

type ctbEligibilityProps = {
  enquiry?: Pick<Enquiry, 'mileage'>;
  year: number;
}

export const ctbEligibility = ({ enquiry, year }: ctbEligibilityProps) => {
  const mileage = enquiry?.mileage ?? 0;
  const ageOfCar = new Date().getFullYear() - year;
  return ageOfCar >= CTB_AGE_LOWER_THRESHOLD && mileage > CTB_MILEAGE_LOWER_THRESHOLD;
};
