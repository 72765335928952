/* eslint-disable import/prefer-default-export */
import { logger } from 'logger';

export const captureExceptionWithTeam = (error, teamName, opts = {}) => {
  const { captureException } = globalThis?.Sentry || {};

  if (captureException) {
    captureException(new Error(error), {
      level: 'warning',
      ...opts,
      tags: { ...opts?.tags, team: teamName },
    });
  } else if (logger?.warn) {
    logger.warn('captureExceptionWithTeam', error, { opts, teamName });
  } else {
    console.warn('captureExceptionWithTeam', error, { opts, teamName }); // eslint-disable-line no-console
  }
};
