export const MOTORWAY_URL = 'https://motorway.co.uk';
export const PRO_URL = 'https://pro.motorway.co.uk';
export const STAGE_PRO_URL = 'https://stage-pro.motorway.co.uk';
export const HELP_URL = 'https://help.motorway.co.uk/hc/en-gb';
export const CAREERS_URL = 'https://careers.motorway.co.uk';
export const CONTACT_URL = `${HELP_URL}/p/contact`;
export const YOUTUBE_EMBED_URL = 'https://www.youtube-nocookie.com/embed/zfwJxCIDfHw?autoplay=1';
export const MENTION_ME_SIGN_UP_SCRIPT_BASE_URL = 'https://tag.mention-me.com/api/v2/signup/mm191789f5';
export const MENTION_ME_REFER_BASE_URL = 'https://tag.mention-me.com/api/v2/referreroffer/mm191789f5';
export const WORDPRESS_URL = process.env.WORDPRESS_URL
|| [
  'https://motorway.wpenginepowered.com', // wpengine "advanced networking", basically a CDN
  'https://blog.motorway.co.uk',
  'https://motorwaystg.wpengine.com',
][0];
export const APP_URL = 'https://motorway.onelink.me/gYoi/2jk6739s';
