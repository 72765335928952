import { Vehicle, VehicleLookupState } from './@types';

type MappableVehicleStates = Vehicle | VehicleLookupState;

export const mapEligibilityToVehicle = <T extends MappableVehicleStates>(vehicle: T) => {
  const { marketplaceEligible } = vehicle?.enquiry ?? {};
  return !marketplaceEligible ? vehicle : { ...vehicle, marketplaceEligible };
};

export const mapEligibilityToVehicles = (vehicles: Vehicle[]) => (
  vehicles.map((vehicle) => mapEligibilityToVehicle<Vehicle>(vehicle))
);
