import React from 'react';
import universal from 'react-universal-component';

import { MDText } from 'i18n-react';

import { LoadingSpinner } from '@motorway/motorway-storybook-cra';

import { defaultLazyLoadOptions } from 'Utilities/constants';
import { vehicleType } from 'Utilities/helpers';

import Texts from '../text/howTo.json';

const lazyLoadConfig = {
  ...defaultLazyLoadOptions,
  loading: <LoadingSpinner />,
};

const Step1 = universal(() => import('../../../../client/assets_seller/howitworks/homepage/step_1'), lazyLoadConfig);

const Step2 = universal(() => import('../../../../client/assets_seller/howitworks/homepage/step_2'), lazyLoadConfig);

const Step3 = universal(() => import('../../../../client/assets_seller/howitworks/homepage/step_3'), lazyLoadConfig);

const Step4 = universal(() => import('../../../../client/assets_seller/howitworks/homepage/step_4'), lazyLoadConfig);

const T = new MDText(Texts);

const stepsTitle = T.translate('title');

const stepsConfig = (brand = null, path = '') => {
  const vehicleText = vehicleType(path);
  const isVanPath = vehicleText === 'van';

  return [
    {
      animationRepeatTime: 13.5,
      animationStartTime: 0,
      id: 'anHomeLetterStart',
      svg: Step1,
      text: isVanPath ? T.translate('step1.detailVan') : T.translate('step1.detail'),
      time: T.translate('step1.title', { vehicle: brand || vehicleText }),
    },
    {
      animationRepeatTime: 19.2,
      animationStartTime: 9,
      id: 'anHomeSoundWaveStart',
      svg: Step2,
      text: T.translate('step2.detail', {
        vanSuffix: isVanPath ? T.translate('vanSuffix') : '',
        vehicle: brand || vehicleText,
      }),
      time: T.translate('step2.title'),
    },
    {
      animationRepeatTime: 17.1,
      animationStartTime: 12.3,
      id: 'anHomeBubbleUpStart',
      svg: Step3,
      text: T.translate('step3.detail', { vehicle: vehicleText }),
      time: T.translate('step3.title'),
    },
    {
      animationRepeatTime: 20.7,
      animationStartTime: 17.7,
      id: 'anHomeEyeOpenStart',
      svg: Step4,
      text: T.translate('step4.detail', { vehicle: brand || vehicleText }),
      time: T.translate('step4.title'),
    },
  ];
};

export { stepsConfig, stepsTitle };
