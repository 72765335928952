import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const HERO_ANIMATION_STATES = Object.freeze({
  mileage: 'mileage',
  none: '',
  vrm: 'vrm',
});

const usePageTransitionState = () => {
  const history = useHistory();
  return history?.location?.state || {};
};

const useStopAnimationFallback = (trigger, stopCallBack, delay = 1000) => {
  useEffect(() => {
    if (!trigger) {
      return;
    }
    const timer = setTimeout(() => stopCallBack(), delay);
    // eslint-disable-next-line consistent-return
    return () => clearTimeout(timer);
  }, [trigger, stopCallBack, delay]);
};

export { HERO_ANIMATION_STATES, usePageTransitionState, useStopAnimationFallback };
