import React from 'react';
import cx from 'classnames';

import LoadingSpinner from '../../forms/LoadingSpinner/LoadingSpinner';

import styles from './DialogLoader.scss';

type DialogLoaderProps = {
  readonly className?: string;
  readonly hideLabel?: boolean;
  readonly message?: string;
  readonly panel?: boolean;
}

const DialogLoader = ({ className = '', hideLabel = false, message = '', panel = false }: DialogLoaderProps) => (
  <div className={cx(styles.component, { [styles.extraBottomPadding]: panel })} data-testid='dialog-loader'>
    <div className={cx(styles.dialogWrapper, { [styles.panel]: panel, [className]: className })}>
      {/* @ts-expect-error */}
      <LoadingSpinner branded />
      { hideLabel || !message
        ? null
        : <h2>{message}</h2>}
    </div>
  </div>
);

export default DialogLoader;
