import React from 'react';
import cx from 'classnames';

import { MDText } from 'i18n-react';

import { Content } from '@motorway/motorway-storybook-cra';

import LocalTexts from '../../../texts.json';

import styles from './PageLoaderError.scss';

const LocalT = new MDText(LocalTexts);

const Texts = {
  content: LocalT.translate('components.pageLoaderError.content', {
    refresh: (
      <a href="#" onClick={() => window?.location?.reload()}>
        { LocalT.translate('components.pageLoaderError.action') }
      </a>
    ),
  }),
  title: LocalT.translate('components.pageLoaderError.title'),
} as Record<string, string>;

const content = (
  <>
    <h1>{Texts.title}</h1>
    <p>{Texts.content}</p>
  </>
);

const PageLoaderError = ({ withContentWrapper = true }: {withContentWrapper?: boolean}) => (
  withContentWrapper
    ? (
      <Content className={cx(styles.container, {
        [styles.embedded]: withContentWrapper,
      })}>{content}</Content>
    ) : (
      <div className={styles.container}>
        {content}
      </div>
    )
);

export default PageLoaderError;
