import React from 'react';
import { Form } from 'react-final-form';
import PropTypes from 'prop-types';

import { MDText } from 'i18n-react';

import { ConfirmModal } from '@motorway/motorway-storybook-cra';

import { VALUATIONS_GA_TAGS } from 'Utilities/analytics';
import { stringNumberToInt } from 'Utilities/formatter';

import Texts from '../../../../texts.json';

import MileageInput from './MileageInput';

const LocalT = new MDText(Texts);

const MileageInputHigh = ({
  confirmMileage,
  isCarValueTracker,
  mileage,
  modalClassName,
}) => (
  <Form
    initialValues={{
      mileage: stringNumberToInt(mileage) || '', // TODO - when adding back `,` numbers add `toLocaleString`
    }}
    onSubmit={({ mileage: newMileage }) => {
      confirmMileage.onConfirm({ mileage: stringNumberToInt(newMileage) });
    }}
    render={({ handleSubmit, ...state }) => (
      <ConfirmModal
        {...{ modalClassName }}
        onConfirm={ () => {
          if (!isCarValueTracker) {
            VALUATIONS_GA_TAGS.CONFIRM_MILEAGE_MODAL_SUBMIT({
              mileage: state?.values?.mileage,
            });
          }
          handleSubmit();
        }}
        onReject={confirmMileage.onReject}
      >
        <div>
          <h2>{LocalT.translate('components.mileageField.warnings.tooHigh.title')}</h2>
          <h3>{LocalT.translate('components.mileageField.warnings.tooHigh.detail')}</h3>
        </div>
        <form
          noValidate
          onSubmit={handleSubmit}
        >
          <MileageInput
            isHighMileageModal
            noLabel
            validateOnInit
            {...{ isCarValueTracker, mileage }}
          />
        </form>
      </ConfirmModal>
    )}
  />
);

MileageInputHigh.propTypes = {
  confirmMileage: PropTypes.shape({
    onConfirm: PropTypes.func.isRequired,
    onReject: PropTypes.func.isRequired,
  }).isRequired,
  isCarValueTracker: PropTypes.bool,
  mileage: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  modalClassName: PropTypes.string,
};

export default MileageInputHigh;
