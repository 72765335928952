import React from 'react';

const ZoomOutSVG = (props) => (
  <svg height={24} props={props} viewBox="0 0 24 24" width={24} xmlns="http://www.w3.org/2000/svg">
    <defs>
      <path
        className="motorway-svg-fill"
        d="M12 20a8 8 0 1 0 0-16 8 8 0 0 0 0 16Zm0 2a10 10 0 1 1 0-20 10 10 0 0 1 0 20Zm-3.5-9a1 1 0 0 1 0-2h7a1 1 0 0 1 0 2h-7Z"
        fillRule="nonzero"
        id="a"
      />

    </defs>
    <g fill="none" fillRule="evenodd">
      <mask fill="#fff" id="b"><use xlinkHref="#a" /></mask>
      <use fill="#000" fillRule="nonzero" xlinkHref="#a" />
      <g fill="#1D1D1B" mask="url(#b)"><path d="M0 0h24v24H0z" /></g>
    </g>
  </svg>
);

export default ZoomOutSVG;
