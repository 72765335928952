/* eslint-disable import/prefer-default-export */

export type FastlaneInitialState = Omit<typeof window.__INITIAL_STATE__, 'gateway' | 'user'> & {
  featureFlags: Record<string, unknown>;
  gatewayApi: string;
  overrideFlags?: Record<string, boolean>;
  overrideMocks?: Record<string, boolean>;
}

// This is a terrible idea, but it isolates the fastlane variables,
// so we know exactly what we need in the future and can ditch what we don't
// and don't have to unpick everything and can tidy up and replace at will...
// "Act In Haste, Repent At Leisure"
export const fastlaneFormatIntState = (initialState: typeof window.__INITIAL_STATE__): FastlaneInitialState => {
  const {
    affiliate,
    appClipUrl,
    appName,
    appVersion,
    brandData,
    customerVehicleLimit,
    experimentDecisions,
    featureFlags,
    gateway: gatewayApi,
    guideData,
    guidesPreviewData,
    hideAnnouncement,
    isNewDesign,
    landingPageData,
    optimizelySdk,
    photosAppUrl,
    photoUploader,
    providersVersions,
    recentSales,
    reviewsApi,
    reviewsData,
    wordpressApi,
    wordpressData,
    youtubeEmbedURL,
  } = initialState;

  return {
    affiliate,
    appClipUrl,
    appName,
    appVersion,
    brandData,
    customerVehicleLimit,
    experimentDecisions,
    featureFlags,
    gatewayApi,
    guideData,
    guidesPreviewData,
    hideAnnouncement,
    isNewDesign,
    landingPageData,
    optimizelySdk,
    photosAppUrl,
    photoUploader,
    providersVersions,
    recentSales,
    reviewsApi,
    reviewsData,
    wordpressApi,
    wordpressData,
    youtubeEmbedURL,
  };
};
