import { useCallback, useContext } from 'react';

import { OptimizelyContext } from '@optimizely/react-sdk';

import { timeoutFetchSentryGuard } from 'Fetch';
import { getUserSession, postCreateEnquiry } from 'PrivateAPI';

import { ConfigContext } from 'Context/config';
import { UserContext } from 'Context/user';
import { checkMarketPlaceEligibility as checkMarketPlaceEligibilityPartnerId } from 'Context/useSetGaMarketplaceEligible';
import { VehicleLookupContext } from 'Context/vehicleLookup';
import { VehicleContext } from 'Context/vehicles';

import { GA_VARIABLES, SNOWPLOW_GET_TRACKERS, VALUATIONS_GA_TAGS } from 'Utilities/analytics';
import { EVENT } from 'Utilities/analytics/optimizely/constants';
import { getStrippedUserData } from 'Utilities/helpers';
import { User } from 'Utilities/user/@types';
import { Enquiry, Vehicle } from 'Utilities/vehicles/@types';

declare global {
  // eslint-disable-next-line vars-on-top, no-var
  var dataLayer: Record<string, any>[];
}

type CreateEnquiryType = {
  consent?: 'true' | 'false';
  email?: string;
  mileage: number;
  name?: string;
  phone?: string;
  postcode?: string;
  sale_timeframe?: string; // eslint-disable-line camelcase
  timeframe?: string;
  vrm: string;
};

type CreateEnquiryResponse = {
  code?: string;
  customer?: User | undefined;
  enquiry?: Enquiry;
  error?: string;
  message?: string;
  vehicle?: Vehicle;
};

export const getSnowplowIds = () => {
  const snowplowNetworkUserId = document.cookie.split('; ').find((cookie) => cookie.startsWith('sp='))?.split('=')[1];

  let snowplowDomainUserId;
  let snowplowSessionId;
  SNOWPLOW_GET_TRACKERS(function getTrackers(this:any) {
    const firstTracker = Object.values(this)[0] as any;
    [, snowplowDomainUserId, , , , , snowplowSessionId] = firstTracker?.getDomainUserInfo() || [];
  });

  return { snowplowDomainUserId, snowplowNetworkUserId, snowplowSessionId };
};

export const useCreateEnquiryAction = () => {
  const { configState: { affiliate } } = useContext(ConfigContext);
  const { userActions: { update: updateUser } } = useContext(UserContext);
  const {
    vehicleActions: {
      addWithEnquiry: addVehicleWithEnquiry,
      replace: updateVehicles,
    },
  } = useContext(VehicleContext);
  const { vehicleLookupActions: { add: addVehicleLookup } } = useContext(VehicleLookupContext);
  const { optimizely } = useContext(OptimizelyContext);

  const createEnquiryAction = useCallback(async ({
    consent,
    email,
    mileage,
    name,
    phone,
    postcode,
    sale_timeframe, // eslint-disable-line camelcase
    timeframe,
    vrm,
  }: CreateEnquiryType) => {
    const {
      code,
      customer,
      enquiry,
      error: apiError,
      message,
      vehicle,
    }: CreateEnquiryResponse = await postCreateEnquiry({
      affiliate: affiliate?.slug || null,
      app_variant: {},
      consent,
      email,
      mileage,
      name,
      phone,
      postcode,
      sale_timeframe: sale_timeframe || timeframe, // eslint-disable-line camelcase
      source: 'seller-webapp',
      vrm,
      ...getSnowplowIds(),
    }, { include: 'tracker' }).catch((error) => {
      timeoutFetchSentryGuard(error, () => window?.Sentry?.captureException(new Error(error), {
        extra: {
          vrm,
        },
        tags: {
          fetch: 'Create enquiry error',
        },
      }));
      return error;
    });

    const result:CreateEnquiryResponse = {
      customer,
      enquiry,
      error: apiError || code || message,
      vehicle,
    };

    if (result.error) { // API Error
      return result as CreateEnquiryResponse;
    }

    if (vehicle) {
      const payload = {
        enquiry,
        vehicle,
      };

      // @ts-ignore
      addVehicleWithEnquiry(payload);
      addVehicleLookup(payload);

      if (enquiry?.id) {
        GA_VARIABLES.enquiryId = enquiry.id;
        GA_VARIABLES.userId = customer?.id;
        GA_VARIABLES.vin = 'N/A';
        GA_VARIABLES.vrm = vehicle?.vrm;
        checkMarketPlaceEligibilityPartnerId({ enquiry, marketplaceEligible: enquiry?.marketplaceEligible });
        VALUATIONS_GA_TAGS.ENQUIRY_CREATED(enquiry);

        optimizely?.track(EVENT.ENQUIRY_CREATED);

        const strippedDataLayer = [...globalThis?.dataLayer || []].reduce((acc, val) => {
          const pii = ['phone_number', 'email', 'postcode'];

          if (typeof val === 'object' && !pii.includes(Object.keys(val)[0])) {
            acc.push(val);
          }

          return acc;
        }, []);

        if (!strippedDataLayer.some((v:string) => Object.keys(v)[0] === 'enquiryId')) {
          window?.Sentry?.captureException?.(new Error('createEnquiryAction dataLayer is missing an enquiryID'), {
            extra: {
              customer: getStrippedUserData(customer),
              enquiry,
              strippedDataLayer,
              vehicle,
            },
            level: 'warning',
          });
        }
      }
    }

    if (Number.isInteger(customer?.id)) {
      updateUser(customer as Partial<User>);
      updateVehicles(customer?.vehicles as Vehicle[]);
      await getUserSession({ token: customer?.login_token });
    } else {
      window?.Sentry?.captureException?.(new Error('createEnquiryAction seller ID is not an integer'), {
        extra: {
          customer: getStrippedUserData(customer),
        },
      });
    }

    return result as CreateEnquiryResponse;
  }, [addVehicleLookup, addVehicleWithEnquiry, affiliate?.slug, updateUser, updateVehicles]);

  return { createEnquiryAction };
};
