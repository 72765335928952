/* eslint-disable dot-notation */
/* eslint-disable no-cond-assign */
/* eslint-disable prefer-object-spread */

import { matchPath } from 'react-router';

import dayjs from 'dayjs';

/* Localisation strings */
import { MDText } from 'i18n-react';

import { NO_PAGE_TITLE_SUFFIX_PATHS } from 'Utilities/helpers';

import Texts from '../texts.json';

const T = new MDText(Texts);

export default {
  client: (typeof document === 'object'),
  descriptionTag: null,
  formatDescription(description) {
    if (this.client && description) {
      if (!this.descriptionTag) {
        this.descriptionTag = document.querySelector('meta[name="description"]');
      }

      this.descriptionTag.setAttribute('content', description);
    }

    return description;
  },
  formatTitle(path, title) {
    if (!path || !title) {
      return title;
    }

    if (title.length === 0) {
      title = `${T.translate('appName')}`;
    } else if (NO_PAGE_TITLE_SUFFIX_PATHS.includes(path)) {
      title = `${title}`;
    } else if (/^\/blog\/[0-9]{4}/.test(path) && !this.isBlog(path).params.post) {
      const dates = path.split('/').splice(2, 4);
      const format = ['Do', 'MMMM', 'YYYY'];

      // eslint-disable-next-line no-restricted-globals
      let dayjsDate = format.map((_, i) => ((!isNaN(dates[i])) ? dates[i].padStart(2, '0') : '01'));

      dayjsDate = dayjs(dayjsDate.join('-')).format(format.join(' '));
      dayjsDate = dayjsDate.split(' ').reverse().filter((_, i) => dates[i]).reverse()
        .join(' ');

      title = `${T.translate('pageTitle.blog.title', {
        date: dayjsDate,
      })}`;
    } else {
      title = `${title}${T.translate('pageTitle.suffix')}`;
    }

    if (this.client) {
      document.title = title;
    }

    return title;
  },
  isBlog(path) {
    const isBlog = matchPath(path, { path: '/:blog?/:year?/:month?/:day?/:post?' });
    isBlog.isSection = (isBlog.params.blog === 'blog');
    isBlog.params = (isBlog.isSection) ? isBlog.params : {};
    return isBlog;
  },
  isGuide(path) {
    const isGuide = matchPath(path, { path: '/:guides?/:guide?' });
    isGuide.isSection = (isGuide.params.guides === 'guides');
    isGuide.params = (isGuide.isSection) ? isGuide.params : {};
    return isGuide;
  },
};
