import dayjs from 'dayjs';

import { UserVehicleTracker, Vehicle } from './@types';

export const sortByEnquiryUpdatedAt = (vehicles: Vehicle[] = []) => {
  vehicles.sort((a, b) => (
    dayjs(b.enquiry?.updatedAt).isAfter(dayjs(a.enquiry?.updatedAt)) ? 1 : -1
  ));
};

export const sortValuationsByCreatedAt = (valuations: UserVehicleTracker['trackerValuations']) => {
  valuations.sort((item1, item2) => (
    (dayjs(item1.createdAt).isAfter(dayjs(item2.createdAt))) ? 1 : -1
  ));
};
