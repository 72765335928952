import { GENERIC_ANALYTICS_EVENTS } from 'Utilities/analytics/analyticsGenericEvents';
import { MOTORWAY_URL } from 'Utilities/urls';

export const HEADER_CATEGORY = 'header';

type LinkClickProps = {
  label?: string;
  name: string;
  url?: string;
};

type LinkHoverProps = {
  message: string;
  name: string;
};

export const SP_HEADER_EVENTS = {
  HEADER_LINK_CLICKED: ({ label, name, url }:LinkClickProps) => GENERIC_ANALYTICS_EVENTS.CLICK_EVENT({
    category: HEADER_CATEGORY,
    label,
    name,
    url,
  }),
  HEADER_LINK_HOVERED: ({ message, name }:LinkHoverProps) => GENERIC_ANALYTICS_EVENTS.HOVER_EVENT({
    category: HEADER_CATEGORY,
    message,
    name,
  }),
  HEADER_LOGO_CLICKED: () => GENERIC_ANALYTICS_EVENTS.CLICK_EVENT({
    category: HEADER_CATEGORY,
    name: 'logo',
    url: MOTORWAY_URL,
  }),
};
