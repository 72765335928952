import fetch from 'cross-fetch';
import qs from 'query-string';

import { LOGGING_LEVELS, processResponse, timeoutFetch } from 'Fetch';

import { CONFIG_ENGINE_FEATURE_FLAGS, CUSTOMER_CHANNEL_KEY, CUSTOMER_CHANNELS, PROVIDERS_VERSIONS_KEY } from 'Utilities/constants';
import { URLQueryToString } from 'Utilities/URL';

let config = {
  gateway: null,
  json: true,
  method: 'GET',
  restAuth: null,
  reviewsApi: null,
  wordpressApi: null,
};

const returnJsonResult = async (res) => {
  const json = await res.json();
  if (!res.ok) {
    throw json;
  }
  return json;
};

export const getAffiliates = async () => {
  const res = await timeoutFetch(`${config.gateway}/platform/affiliates`);
  return returnJsonResult(res);
};

export const getBrands = async (slug) => {
  const params = qs.stringify({ slug });
  const res = await timeoutFetch(`${config.gateway}/platform/makes?${params}`);
  return returnJsonResult(res);
};

export const getMotorwayReviews = async (count) => {
  const getPartnerReviews = async (slug) => {
    const reviews = await timeoutFetch(`${config.reviewsApi}?slug=${slug}`)
      .then((res) => res.json());
    return reviews;
  };

  let data = await getPartnerReviews('motorway.co.uk');
  const providerSlug = Object.keys(data).pop();
  data = data[providerSlug];
  data.reviews = data.reviews.slice(0, count || 12);
  data.rating = data.rating.toFixed(2);
  return data;
};

export const getCustomer = async (user, query, providersVersions, isCognitoLogin) => {
  const providers = providersVersions ? { [PROVIDERS_VERSIONS_KEY]: providersVersions } : {};

  const { auth_token: authToken, email: username, refreshToken } = user || {};

  const tokens = {};
  let updatedTokens = {};
  const showCognitoLogin = isCognitoLogin && username;

  if (showCognitoLogin) {
    const response = await returnJsonResult(
      await timeoutFetch(`${config.gateway}/platform/v3/customers/refresh-tokens`, {
        body: JSON.stringify({ refreshToken, username }),
        headers: {
          'Content-Type': 'application/json',
          ...providers,
        },
        method: 'POST',
      }),
    ).catch((err) => {
      // If the user has an expired refresh token then they will need to login again to get a new one,
      // so we can reject the promise to fetch the customer, and the user will be null
      if (err?.statusCode === 424) {
        throw new Error(err.statusMessage);
      }
    });

    const { accessToken, idToken } = response?.data || {};

    if (accessToken && idToken) {
      tokens.authorization = accessToken;
      tokens['id-token'] = idToken;
    } else if (authToken) {
      tokens['x-access-token'] = authToken;
    }

    updatedTokens = {
      accessToken,
      idToken,
      refreshToken,
    };
  } else {
    tokens['x-access-token'] = user.auth_token;
  }

  const queryString = query ? `?${URLQueryToString(query)}` : '';

  const response = await timeoutFetch(`${config.gateway}/platform/user${queryString}`, {
    headers: {
      ...tokens,
      ...providers,
    },
    loggerLevel: LOGGING_LEVELS.ERROR,
    method: 'GET',
  });

  const result = await returnJsonResult(response);
  const channel = response?.headers?.get(CUSTOMER_CHANNEL_KEY) || CUSTOMER_CHANNELS.RETAIL;

  return {
    ...(showCognitoLogin ? { cognitoTokens: updatedTokens } : {}),
    ...result,
    channel,
  };
};

export const getFeatureFlags = async () => {
  const configEngineKeys = [CONFIG_ENGINE_FEATURE_FLAGS.WEBAPP_PUBLIC,
    CONFIG_ENGINE_FEATURE_FLAGS.PROFILING_PUBLIC].join();

  const res = await timeoutFetch(`${config.gateway}/content/contentItems/public?names=${configEngineKeys}`, {
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'GET',
  });
  return returnJsonResult(res);
};

export const sendSms = (enquiryId, user) => { // eslint-disable-line
  return fetch(`${config.gateway}/platform/send-sms`, {
    body: JSON.stringify({ enquiryId }),
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': user.auth_token,
    },
    method: 'POST',
  }).then(processResponse);
};

export const init = (params) => {
  config = Object.assign(config, params);
};
