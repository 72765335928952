import { useCallback, useContext } from 'react';

import { timeoutFetchSentryGuard } from 'Fetch';
import { getUser } from 'PrivateAPI';
import { postVrmCheck } from 'PublicAPI';

import { UserContext } from 'Context/user';
import { VehicleLookupContext } from 'Context/vehicleLookup';
import { VehicleContext } from 'Context/vehicles';

import { getStrippedUserData } from 'Utilities/helpers';
import { User } from 'Utilities/user/@types';
import { Enquiry, ExpiredEnquiry, Vehicle } from 'Utilities/vehicles/@types';
import { VRM_CHECK_CODES } from 'Utilities/vrm';

interface VrmCheckAction {
  isRefreshLookupVehicleAfterFetch?: boolean;
  isValuationHub?: boolean;
  vrmValue: string;
}

export interface VRMCheckResponseType {
  customer?: User | {};
  enquiry?: Enquiry & ExpiredEnquiry;
  error?: string;
  vehicle?: Vehicle;
}

const refetchUser = async (user: User | {}) => {
  if (!Number.isInteger((user as User)?.id)) {
    return user;
  }

  return getUser({ include: 'tracker' })
    .catch((err) => {
      timeoutFetchSentryGuard(err, () => window?.Sentry?.captureException?.(new Error(err), {
        extra: {
          user: getStrippedUserData(user),
        },
        tags: {
          fetch: 'Get user and their vehicles',
        },
      }));

      return user;
    });
};

export const useVrmCheckAction = () => {
  const { userActions: { update: updateUser } } = useContext(UserContext);
  const { vehicleActions: { replace: replaceVehicles } } = useContext(VehicleContext);
  const { vehicleLookupActions: { add: addVehicleLookup } } = useContext(VehicleLookupContext);

  const vrmCheckAction = useCallback(async ({
    isRefreshLookupVehicleAfterFetch = true,
    isValuationHub = false,
    vrmValue,
  }: VrmCheckAction) => {
    const {
      code,
      customer,
      enquiry,
      error: apiError,
      vehicle,
    }: VRMCheckResponseType & { code: string; error: Error } = await postVrmCheck(vrmValue)
      .catch((error) => ({ error }));

    const result: VRMCheckResponseType = {
      customer,
      enquiry,
      error: apiError || code,
      vehicle,
    };

    if (result.error) {
      // @ts-ignore
      const errorMsg = result.error?.code || result.error?.message;
      const msg: string = Object.values(VRM_CHECK_CODES).includes(errorMsg)
        ? errorMsg
        : VRM_CHECK_CODES.BAD_REQUEST;
      result.error = msg as string;
      addVehicleLookup({ resultError: { msg, vrm: vrmValue } });

      return result as VRMCheckResponseType;
    }

    if (vehicle) {
      addVehicleLookup({
        enquiry,
        vehicle,
      });
    }

    const nonValuationHub = async () => {
      if (customer && !isValuationHub) {
        const { vehicles } = await refetchUser(customer) ?? {};

        if (Array.isArray(vehicles)) {
          replaceVehicles(vehicles);

          if (isRefreshLookupVehicleAfterFetch && !(enquiry)?.expired) {
            const updatedVehicleWithEnquiry = (vehicles)
              ?.find(({ id }: { id: number }) => id === vehicle?.id);

            if (updatedVehicleWithEnquiry) {
              addVehicleLookup({
                enquiry: updatedVehicleWithEnquiry?.enquiry,
                vehicle: updatedVehicleWithEnquiry,
              });
            }
          }
        }
      }
    };

    if (customer) {
      updateUser(customer);
      nonValuationHub();
    }

    return result as VRMCheckResponseType;
  }, [addVehicleLookup, replaceVehicles, updateUser]);

  return {
    vrmCheckAction,
  };
};
