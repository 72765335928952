import React, { useRef } from 'react';
import cx, { Argument as ClassNameType } from 'classnames';

import { MDText } from 'i18n-react';

import { LoadingSpinner } from '@motorway/motorway-storybook-cra';

import cypressIds from 'CypressId';

import useTrustPilotWidgetLoader from 'Utilities/hooks/useTrustPilotWidgetLoader';
import { applyCypressData } from 'Utilities/index';

import LocalTexts from './TrustpilotReviewsCarouselTexts.json';

import styles from './TrustpilotReviewsCarousel.scss';

const LocalT = new MDText(LocalTexts);

const t = (key: string, args?: Record<string, string>) =>
  `${LocalT.translate(key, args)}`;

const texts = {
  readMore: t('readMore'),
  title: t('title'),
} as const;

type TrustpilotReviewsCarouselHTMLProps = {
  className?: ClassNameType | string;
  failedLoad?: boolean;
  largeTitle?: boolean;
  onLoad?: () => void;
  setFailedLoad?: (value: boolean) => void;
  showReadMoreReviews?: boolean;
  subTitle?: string | React.ReactNode;
  title?: string | React.ReactNode;
};

const TrustpilotReviewsCarouselHTML = React.memo(React.forwardRef<HTMLDivElement, TrustpilotReviewsCarouselHTMLProps>(
  ({
    className,
    failedLoad,
    largeTitle,
    showReadMoreReviews,
    subTitle,
    title = texts.title,
  }: TrustpilotReviewsCarouselHTMLProps, ref) => {
    if (failedLoad) {
      return null;
    }

    return (
      <div className={cx(styles.component, className)}>
        <h2 className={cx({ [styles.largeTitle]: largeTitle })}>{title}</h2>
        { subTitle && <p>{subTitle}</p> }
        <div
          ref={ref}
          className="trustpilot-widget"
          data-businessunit-id="59f1a7fc0000ff0005aeb29e"
          data-locale="en-GB"
          data-review-languages="en"
          data-stars="5"
          data-style-height="240px"
          data-style-width="100%"
          data-template-id="54ad5defc6454f065c28af8b"
          data-testid="trustpilot-widget-reviews"
          data-theme="light"
          id="trustpilot-widget-reviews"
          {...applyCypressData(cypressIds.sections.trustPilotReviews)}
        >
          <LoadingSpinner />
        </div>
        {
          showReadMoreReviews
          && <a
            className="mw-link mw-link-blue"
            href="https://uk.trustpilot.com/review/motorway.co.uk"
            rel="noopener noreferrer"
            target="_blank"
          >
            {texts.readMore}
          </a>
        }
      </div>
    );
  },
), ({ failedLoad: prevValue }, { failedLoad: newValue }) => prevValue === newValue);

const TrustpilotReviewsCarousel = ({ onLoad, setFailedLoad, ...props }: TrustpilotReviewsCarouselHTMLProps) => {
  const $container = useRef<HTMLDivElement>(null);
  const observerSettings = {
    disconnectOnVisible: true,
    rootMargin: '500px',
  };

  useTrustPilotWidgetLoader({ $container, observerSettings, onLoad, setFailedLoad });

  return (<TrustpilotReviewsCarouselHTML {...props} ref={$container} />);
};

export default TrustpilotReviewsCarousel;
