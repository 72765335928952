import { DependencyList, RefObject, useEffect, useState } from 'react';

export type ObserverEntryContext = {
  readonly freeze: () => void;
};

const useObserver = (
  refs: RefObject<readonly Element[]>,
  options?: IntersectionObserverInit,
  onChange?: (entry: IntersectionObserverEntry, context: ObserverEntryContext) => void,
  deps: DependencyList = [],
) => {
  const [entryMap, setEntryMap] = useState<Map<Element, IntersectionObserverEntry>>(new Map());
  const [frozenNodes, setFrozenNodes] = useState<readonly Element[]>([]);

  useEffect(() => {
    const observables = refs.current?.filter(
      (node) => !frozenNodes.some((frozen) => frozen.isEqualNode(node)),
    );

    if (!window?.IntersectionObserver) {
      return () => {};
    }

    const observer = new IntersectionObserver((entries) => {
      setEntryMap(
        (current) =>
          new Map([
            ...current.entries(),
            ...entries.map((entry) => [entry.target, entry] as const),
          ]),
      );

      entries.forEach((entry) =>
        onChange?.(entry, {
          freeze: () => setFrozenNodes((curr) => [...curr, entry.target]),
        }));
    }, options);

    observables?.forEach((ref) => {
      observer.observe(ref);
    });

    return () => observer.disconnect();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refs, frozenNodes.length, ...deps]);

  return refs.current?.map((ref) => entryMap.get(ref));
};

export { useObserver };
