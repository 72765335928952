import React, { useMemo } from 'react';

import {
  preparedDisplayNames,
  REVIEW_BODY_MAX_LENGTH,
  REVIEW_TITLE_MAX_LENGTH,
  truncate,
} from 'Utilities/helpers';

import HighlightWord from './HighlightWord';

const useTrustpilotCardHighlight = ({
  cardTitleTruncationLength,
  cardTruncationLength,
  displayName,
  review,
}) => {
  const { body, title } = review;

  const titleMaxLength = cardTitleTruncationLength || REVIEW_TITLE_MAX_LENGTH;
  const bodyMaxLength = cardTruncationLength || REVIEW_BODY_MAX_LENGTH;

  const HighlightedTitle = useMemo(() => (
    <HighlightWord words={preparedDisplayNames(displayName)}>
      {truncate(title, titleMaxLength)}
    </HighlightWord>
  ), [displayName, title, titleMaxLength]);

  const HighlightedBody = useMemo(() => (
    <HighlightWord words={preparedDisplayNames(displayName)}>
      {truncate(body, bodyMaxLength)}
    </HighlightWord>
  ), [displayName, body, bodyMaxLength]);

  return { HighlightedBody, HighlightedTitle };
};

export default useTrustpilotCardHighlight;
