import React from 'react';
import PropTypes from 'prop-types';

import AffiliateHeader from './AffiliateHeader';

import { connect } from '../../../store';

const AffiliateHeaderContainer = React.memo(({
  context: {
    state: { affiliate },
  },
}) => (
  <>
    {
      (affiliate?.showBranding)
        ? (<AffiliateHeader affiliate={affiliate.slug} />)
        : (null)
    }
  </>
), () => true);

AffiliateHeaderContainer.propTypes = {
  context: PropTypes.shape({
    state: PropTypes.shape({
      affiliate: PropTypes.shape({
        showBranding: PropTypes.bool,
        slug: PropTypes.string,
      }),
    }),
  }).isRequired,
};

export default connect(AffiliateHeaderContainer);
