import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import dayjs from 'dayjs';

import { MDText } from 'i18n-react';

import { Content, LoadingSpinner } from '@motorway/motorway-storybook-cra';

import { timeoutFetchSentryGuard } from 'Fetch';
import { getRecentSales } from 'PublicAPI';

import { ConfigContext } from 'Context/config';

import { classNamePropType } from 'Utilities/propTypes';

import { useBreakpoint } from '../../../../components/misc/Breakpoints/Breakpoints';
import { usePageRefEvents } from '../../common/Hooks/usePageRefEvents';

import Carousel from './Carousel';
import RecentSaleImage from './RecentSaleImage';
import LocalTexts from './RecentSalesText.json';
import RecentSaleTimeStamp from './RecentSaleTimeStamp';

import styles from './RecentSales.scss';

const LocalT = new MDText(LocalTexts);

const mapData = (data) => data.map(({ sale, seller, vehicle }) => ({
  id: `${seller?.firstName}-${vehicle?.make}-${vehicle?.model}-${sale?.soldPrice}`.replace(
    /\s/g,
    '',
  ),
  img: { url: vehicle?.leadPhoto?.url },
  model: `${vehicle?.make} ${vehicle?.model}`,
  name: seller?.firstName,
  price: sale?.soldPrice,
  timestamp: dayjs(sale?.soldDate),
})).sort((item1, item2) => item2.timestamp - item1.timestamp);

const RecentSales = ({ carouselClassName, className, onLoad = () => {}, showTitle = true }) => {
  const { configState: { recentSales } } = useContext(ConfigContext);

  const configRecentSalesData = Array.isArray(recentSales) ? mapData(recentSales) : [];
  const [recentSalesData, setRecentSalesData] = useState(configRecentSalesData);
  const [loading, setLoading] = useState(recentSalesData?.length === 0);
  const breakpoints = useBreakpoint();
  const { breakpointTabletMobile } = breakpoints.maxWidth;

  const { setRef } = usePageRefEvents({ onRefTrigger: onLoad });

  useEffect(() => {
    const loadRecentSales = async () => {
      if (process.env.NODE_ENV === 'production') {
        try {
          const recentSalesResponse = await getRecentSales();

          setLoading(false);
          if (Array.isArray(recentSalesResponse)) {
            setRecentSalesData(mapData(recentSalesResponse));
          } else {
            throw new Error(`Server response is not an array: ${recentSalesResponse}`);
          }
        } catch (err) {
          setLoading(false);
          timeoutFetchSentryGuard(err, () => {
            window?.Sentry?.captureException?.(new Error(err), {
              level: 'warning',
              tags: {
                fetch: 'recent sales',
              },
            });
          });
        }
      } else {
        import('./mockRecentSales').then(({ default: recentSalesMock }) => {
          setLoading(false);
          setRecentSalesData(mapData(recentSalesMock));
        });
      }
    };

    if (loading) {
      loadRecentSales();
    }
  }, [loading]);

  return (
    <div className={cx(className, carouselClassName, styles.component)}>
      {showTitle && <Content className={styles.container}>
        <h2>{LocalT.translate('title')}</h2>
      </Content>}
      <div
        ref={(ref) => setRef(ref)}
        className={ cx(styles.container, styles.carousel) }
        data-testid="recentSales">
        { loading && (
          <div className={styles.loader}>
            <LoadingSpinner size="large" />
            <h4 className={cx(styles.loadingText, 'mw-title-title5')}>
              {LocalT.translate('loading')}
            </h4>
          </div>
        )}
        <Carousel
          slideInterval={[1000, 2000]}
          vertical={breakpointTabletMobile}
          wrapperClassName={carouselClassName}
        >
          {recentSalesData.map((recentSale) => {
            let modelName = recentSale.model.trim();
            const imageSize = { height: 124, width: 137 };

            if (modelName.length >= 32) {
              modelName = modelName.substring(0, 32).split(' ');
              modelName.pop();
              modelName = `${modelName.join(' ')}…`;
            }

            return (
              <div key={recentSale.id} className={styles.item}>
                <div className={styles.content}>
                  <div className={styles.name}>
                    {`Sold by ${recentSale.name}`}
                  </div>
                  <div className={styles.description}>
                    {`${modelName}, sold for £${recentSale.price.toLocaleString()}.`}
                  </div>
                  <RecentSaleTimeStamp timestamp={ dayjs(recentSale.timestamp).fromNow() } />
                  { recentSale?.img?.url && (
                    <RecentSaleImage
                      dimensions={ imageSize }
                      src={ recentSale.img.url }
                      vehicleModel={ recentSale.model } />)
                  }
                </div>
              </div>
            );
          })}
        </Carousel>
      </div>
    </div>
  );
};

RecentSales.propTypes = {
  carouselClassName: classNamePropType,
  className: classNamePropType,
  onLoad: PropTypes.func,
  showTitle: PropTypes.bool,
};

export default RecentSales;
