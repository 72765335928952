import React from 'react';
import { Route } from 'react-router';
import universal from 'react-universal-component';
import PropTypes from 'prop-types';

import 'Utilities/dayjs';
import 'swiper/css/bundle';
/**
 * Import any files you want on the CDN
 * - to not babelify them, add to 'ignore' in 'babel.config.js'
 * - to not include them in webpack add them to 'webpack.common.config.js' where the CDN comment is in rules
 * - add the 3rd party scripts to the .eslintignore file
 */
import '../../../copy_to_public_root/scripts/mw-sp-v3.24.4';
import '../../../copy_to_public_root/scripts/mw-sp-v3.24.4.js.map';
import '../../../copy_to_public_root/scripts/datadog-slim-v5.28.1.eu1';

import { Toaster } from '@motorway/mw-highway-code/pre-alpha';

import Theme from '../../components/legacy/theme/Theme';
import Announcement, { TYPES } from '../../components/misc/Announcement/Announcement';
import { BreakpointProvider } from '../../components/misc/Breakpoints/Breakpoints';
import CookieWarning from '../../components/misc/CookieWarning/CookieWarning';

import AffiliateHeaderContainer from './affiliate/AffiliateHeaderContainer';
import { Header } from './header/Header';
import Init from './init/Init';
import Routes from './routes/Routes';
import ScrollToTop from './ScrollToTop';

import '@motorway/motorway-storybook-cra/dist/themeLight.css';
import '@motorway/motorway-storybook-cra/dist/main.css';
import '@motorway/mw-highway-code/src/styles/theme/light.scss';
import './DarkTheme.scss';
import 'Storybook/styles/light.scss'; // Local Storybook
import './App.less';

const DevelopersWidget = process.env.APPLICATION_ENVIRONMENT !== 'production'
  ? universal(() => import('./developersWidget/DevelopersWidget'), { ignoreBabelRename: true })
  : () => null;

const App = ({ hasError }) => (
  <>
    <Init />
    <Toaster />
    <Theme doNotAlterChildren>
      <BreakpointProvider>
        <DevelopersWidget />
        <Announcement type={TYPES.BUSY_DAYS} />
        <AffiliateHeaderContainer />
        <Route render={() => (<ScrollToTop />)} />
        <Header />
        <Routes {...{ hasError }} />
        <CookieWarning />
        <div id="modal-root" />
      </BreakpointProvider>
    </Theme>
  </>
);

App.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  hasError: PropTypes.any, // TODO - Create proper propType for hasError
};

export default App;
