import { GENERIC_ANALYTICS_EVENTS } from 'Utilities/analytics/analyticsGenericEvents';

export type signUpSubmitProps = {
  consent: boolean;
  id?: number;
  isCarValueTracker?: boolean;
  success: boolean;
};

export const SIGN_UP_PAGE_EVENTS = {
  SIGN_UP_LOADED: (isCarValueTracker: boolean) =>
    GENERIC_ANALYTICS_EVENTS.SECTION_LOAD_EVENT({
      category: isCarValueTracker ? 'cvt' : 'smv_val',
      name: 'signup_section',
    }),
  SIGN_UP_SUBMITTED: ({ consent, id, isCarValueTracker = false, success }: signUpSubmitProps) =>
    GENERIC_ANALYTICS_EVENTS.API_TRIGGERED({
      action: 'create',
      backend_entity: id?.toString() ?? 'N/A',
      backend_service: 'plt_customer',
      success,
      value: JSON.stringify({
        isCarValueTracker,
        marketingConsent: consent,
      }),
    }),
};
