import { Vehicle, VehicleLookupState } from './vehicles/@types';

type GetMileageProps = {
  mileage?: number;
  vehicle?: Vehicle | VehicleLookupState;
}

export const getMileage = ({
  mileage,
  vehicle,
}: GetMileageProps) => (
  mileage
  || vehicle?.enquiry?.mileage
  || vehicle?.userVehicleTracker?.trackerValuations?.slice(-1)[0]?.mileage
  || vehicle?.mileageEst
);
