/* eslint-disable import/prefer-default-export */
import { VRM_CHECK_CODES } from 'Utilities/vrm';

export const captureVrmError = (
  error: Error,
  vrm: string,
  fingerprints: string[] = [],
) => {
  if (error.message === VRM_CHECK_CODES.EXCEEDED) {
    return;
  }
  // @ts-ignore
  window?.Sentry?.captureException?.(new Error(error), {
    extra: { vrm },
    fingerprint: ['{{ default }}', error.message, ...fingerprints],
    // @ts-ignore
    level: error.message === VRM_CHECK_CODES.VEHICLE_LOOKUP_FAILED ? 'info' : 'error',
  });
};
