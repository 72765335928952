import React, { useContext } from 'react';
import cx from 'classnames';

import { ConfigContext } from 'Context/config';

import { ReactComponent as TpLogo } from '../../../../../client/assets_seller/trustpilot/trustpilot-logo-original-2022.svg';
import { ReactComponent as TpStars } from '../../../../../client/assets_seller/trustpilot/trustpilot-stars-4.5-2022.svg';

import styles from './TrustPilotImage.scss';

const DEFAULT_TOTAL_REVIEWS = '7500';

const TrustPilotImage = ({ className }: { className?: string }) => {
  const { configState: { reviewsData } } = useContext(ConfigContext);
  const totalReviews: string = reviewsData?.totalReviews ?? DEFAULT_TOTAL_REVIEWS;
  const reviewString = `${(Math.floor(parseInt(totalReviews) / 250) * 250).toLocaleString()}+ reviews`;

  return (
    <div className={cx(styles.trustpilot, className)}>
      <TpLogo aria-label='Trustpilot' height='33' role='img' width='128' />
      <TpStars aria-label='Trustpilot star rating' height='24' role='img' width='128' />
      <span>{reviewString}</span>
    </div>
  );
};

export default TrustPilotImage;
