import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from './RecentSaleTimeStamp.scss';

const RecentSaleTimeStamp = ({ className, timestamp }) => {
  const [show, setShow] = useState(false);

  useEffect(() => setShow(true), []);

  return (
    <div className={ cx(styles.date, className) }>
      {(show && timestamp) || '\u00A0'}
    </div>
  );
};

RecentSaleTimeStamp.propTypes = {
  className: PropTypes.string,
  timestamp: PropTypes.string.isRequired,
};
export default RecentSaleTimeStamp;
