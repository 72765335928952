import React from 'react';
import PropTypes from 'prop-types';

import { MDText } from 'i18n-react';

import Theme from '../../../components/legacy/theme/Theme';
import Texts from '../../../texts.json';

import Error from './Error';

const LocalT = new MDText(Texts);

const reloadHandler = (e) => {
  e.preventDefault();
  window.location.reload();
};

const Error500 = ({ showSign = true }) => (
  <Theme doNotAlterChildren>
    <Error
      buttonLabel="500.buttonLabel"
      buttonProps={{
        element: 'a',
        onClick: reloadHandler,
      }}
      detail={LocalT.translate('500.detail', { contact: <a href="https://help.motorway.co.uk/hc/en-gb/p/contact">{LocalT.translate('500.contactUs')}</a> })}
      number="500"
      showSign={showSign}
      title="500.title"
    />
  </Theme>
);

Error500.propTypes = {
  showSign: PropTypes.bool,
};

export default Error500;
