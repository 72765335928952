import React, { createContext, useEffect, useMemo, useReducer } from 'react';
import _merge from 'lodash-es/merge';

import { GA_VARIABLES, SNOWPLOW_ENQUIRY_CONTEXT, SNOWPLOW_VEHICLE_CONTEXT } from 'Utilities/analytics';
import { Enquiry, ExpiredEnquiry, MarketplaceEligible, Vehicle, VehicleLookupState } from 'Utilities/vehicles/@types';
import { mapEligibilityToVehicle } from 'Utilities/vehicles/mapEligibilityToVehicle';

import { Slug } from '../utilities/profiling/@types';

import { useSetGaMarketplaceEligible } from './useSetGaMarketplaceEligible';

enum VehicleLookupActionType {
  LOOKUP_ADD = 'LOOKUP_ADD',
  LOOKUP_REMOVE = 'LOOKUP_REMOVE',
  LOOKUP_UPDATE = 'LOOKUP_UPDATE',
  LOOKUP_UPDATE_DP_STATE_SLUG = 'LOOKUP_UPDATE_DP_STATE_SLUG',
}

type VehicleLookupPayload = {
  LOOKUP_ADD: {
    enquiry?: null | Enquiry | ExpiredEnquiry;
    resultError?: VehicleLookupState['resultError'];
    vehicle?: Partial<Vehicle>;
  };
  LOOKUP_UPDATE: {
    enquiry?: Partial<Enquiry>;
    marketplaceEligible?: Partial<MarketplaceEligible>;
    owned?: VehicleLookupState['owned'];
    showTrackerSignUp?: VehicleLookupState['showTrackerSignUp'];
    trackerMileage?: VehicleLookupState['trackerMileage'];
    vehicle?: Partial<Vehicle>;
  };
  LOOKUP_UPDATE_DP_STATE_SLUG: {
    dpEnquiryId?: number | null;
    dpStateSlug?: Slug | null;
  };
}

type VehicleLookupAction = {
  payload: VehicleLookupPayload['LOOKUP_ADD'] | VehicleLookupPayload['LOOKUP_UPDATE'] | VehicleLookupPayload['LOOKUP_UPDATE_DP_STATE_SLUG'];
  type: VehicleLookupActionType;
}

type VehicleLookupContextProps = {
  vehicleLookupActions: {
    add: (payload: VehicleLookupPayload['LOOKUP_ADD']) => void;
    remove: () => void;
    update: (payload: VehicleLookupPayload['LOOKUP_UPDATE']) => void;
    updateDpStateSlug: (payload: VehicleLookupPayload['LOOKUP_UPDATE_DP_STATE_SLUG']) => void;
  };
  vehicleLookupState: VehicleLookupState;
}

type VehicleLookupProviderProps = {
  children: React.ReactNode;
  value?: VehicleLookupState;
}

const initialState: VehicleLookupState = {};

export const initialActions = {
  add: () => {},
  remove: () => {},
  update: () => {},
  updateDpStateSlug: () => {},
};

const VehicleLookupContext = createContext<VehicleLookupContextProps>(
  { vehicleLookupActions: initialActions, vehicleLookupState: initialState },
);

const VehicleLookupDispatch: { actions: Partial<VehicleLookupContextProps['vehicleLookupActions']> } = {
  actions: initialActions,
};
const VehicleLookupStateStatic = { state: {} };

/**
 * Some endpoints split out the payload into `{ vehicle : {...}, enquiry: {...}}` etc.
 * The /user API does not do the same, it's all in the vehicle object, so let's make it consistent.
 */
const splitOutPayload = (payload: VehicleLookupAction['payload']) => {
  const { vehicle, ...payloadWithoutVehicle } = payload as { [key: string]: any; vehicle: Vehicle };
  const { marketplaceEligible } = vehicle?.enquiry ?? payloadWithoutVehicle?.enquiry ?? {};
  return { marketplaceEligible, payloadWithoutVehicle, vehicle };
};

const reducer = (state: VehicleLookupState, { payload, type }: VehicleLookupAction): VehicleLookupState => {
  const oldState = state || {};
  const { marketplaceEligible, payloadWithoutVehicle, vehicle } = splitOutPayload(payload);

  switch (type) {
    // LOOKUP_ADD replaces the entire state
    case VehicleLookupActionType.LOOKUP_ADD: {
      return _merge({}, vehicle, { marketplaceEligible }, payloadWithoutVehicle);
    }
    // LOOKUP_UPDATE merges the new payload into the existing state.
    case VehicleLookupActionType.LOOKUP_UPDATE: {
      if (!oldState?.id) {
        window.Sentry?.captureException(new Error('No vehicle to update in `vehicleLookup` store'), {
          level: 'warning',
        });
      }
      return _merge({}, oldState, vehicle, { marketplaceEligible }, payloadWithoutVehicle);
    }
    case VehicleLookupActionType.LOOKUP_REMOVE: {
      return {};
    }
    case VehicleLookupActionType.LOOKUP_UPDATE_DP_STATE_SLUG: {
      const { dpEnquiryId, dpStateSlug = null } = payload as VehicleLookupPayload['LOOKUP_UPDATE_DP_STATE_SLUG'] || {};

      if (oldState.enquiry) {
        const { dpEnquiryId: oldDpEnquiryId, dpStateSlug: oldDpStateSlug } = oldState.enquiry;

        if (dpEnquiryId === oldDpEnquiryId && dpStateSlug !== oldDpStateSlug) {
          return { ...oldState, enquiry: { ...oldState.enquiry, dpStateSlug } };
        }
      }

      return oldState;
    }
    default:
      window.Sentry?.captureException(new Error('Unexpected use of default switch case'), {
        level: 'warning',
      });
      return oldState;
  }
};

const VehicleLookupProvider = ({ children, value }: VehicleLookupProviderProps) => {
  const state = value ?? initialState;
  const formattedVehicleState = state ? mapEligibilityToVehicle<VehicleLookupState>(state) : state;
  const [vehicleLookupState, dispatch] = useReducer(reducer, formattedVehicleState);

  useEffect(() => {
    GA_VARIABLES.enquiryId = vehicleLookupState?.enquiry?.id?.toString();
  }, [vehicleLookupState?.enquiry?.id]);

  useEffect(() => {
    SNOWPLOW_ENQUIRY_CONTEXT(vehicleLookupState);
    SNOWPLOW_VEHICLE_CONTEXT(vehicleLookupState);
    GA_VARIABLES.vin = 'N/A';
    GA_VARIABLES.vrm = vehicleLookupState?.vrm;
  }, [vehicleLookupState]);

  useSetGaMarketplaceEligible(vehicleLookupState);

  const vehicleLookupActions = useMemo(() => ({
    add: (payload: VehicleLookupPayload['LOOKUP_ADD']) => {
      if (payload.vehicle || payload.resultError) {
        dispatch({ payload, type: VehicleLookupActionType.LOOKUP_ADD });
      }
    },
    remove: () => {
      dispatch({ payload: {}, type: VehicleLookupActionType.LOOKUP_REMOVE });
    },
    update: (payload: VehicleLookupPayload['LOOKUP_UPDATE']) => {
      dispatch({ payload, type: VehicleLookupActionType.LOOKUP_UPDATE });
    },
    updateDpStateSlug: (payload: VehicleLookupPayload['LOOKUP_UPDATE_DP_STATE_SLUG']) => {
      const { dpEnquiryId, dpStateSlug } = payload;

      dispatch({
        payload: { dpEnquiryId, dpStateSlug },
        type: VehicleLookupActionType.LOOKUP_UPDATE_DP_STATE_SLUG,
      });
    },
  }), []);

  // Expose dispatch and state from outside React. This will be removed once apiAction is converted to hooks.
  VehicleLookupDispatch.actions = vehicleLookupActions;
  VehicleLookupStateStatic.state = vehicleLookupState;
  const contextProviderValue = useMemo(() => (
    { vehicleLookupActions, vehicleLookupState }
  ), [vehicleLookupActions, vehicleLookupState]);

  return (
    <VehicleLookupContext.Provider value={contextProviderValue}>
      {children}
    </VehicleLookupContext.Provider>
  );
};

export {
  VehicleLookupContext,
  VehicleLookupDispatch,
  VehicleLookupProvider,
  VehicleLookupStateStatic,
};
