import { useContext, useEffect } from 'react';

import { UserContext } from 'Context/user';
import { VehicleLookupContext } from 'Context/vehicleLookup';

import { GA_VARIABLES } from 'Utilities/analytics';

export const useGA = () => {
  const { userState: user } = useContext(UserContext);
  const { vehicleLookupState: vehicle } = useContext(VehicleLookupContext);

  useEffect(() => {
    // Populate GA before any child useEffect hooks call GA
    if (user?.id) {
      GA_VARIABLES.enquiryId = vehicle?.enquiry?.id;
      GA_VARIABLES.userId = user?.id;
    }
  }, [vehicle?.enquiry?.id, user?.id]);
};
