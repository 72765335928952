import { SLUGS } from '@motorway/mw-enquiry-states/static';

import { Slug } from './profiling/@types';
import { Vehicle } from './vehicles/@types';

export const READ_ONLY_WITH_CONDITION_SLUGS = Object.freeze([
  SLUGS.PART_DETAILS,
  SLUGS.PART_DETAILS_CONTACTED,
]);

export const READ_ONLY_SLUGS = Object.freeze([
  SLUGS.QUOTE_ACCEPTED,
  SLUGS.QUOTED,
  SLUGS.QUOTED_SELLER_CONTACTED,
]);

export const VEHICLE_REMOVABLE_STATES = Object.freeze([
  SLUGS.SOLD,
  SLUGS.UNSELLABLE,
  SLUGS.UNSOLD_BELOW_RESERVE_BID,
  SLUGS.UNSOLD_NO_BIDS,
]);

export const OFFER_RESTRICTED_STATES = Object.freeze([
  ...VEHICLE_REMOVABLE_STATES,
  SLUGS.QA_FAILED,
  SLUGS.WRITE_OFF,
  SLUGS.UNDER_OFFER,
  SLUGS.HPI_QUERY,
  SLUGS.FOR_SALE,
  SLUGS.QA_READY,
]);

export const VALUATION_RESTRICTED_STATES = Object.freeze([
  ...OFFER_RESTRICTED_STATES,
  SLUGS.QA_ON_HOLD,
  SLUGS.SALE_ACTIVE,
  SLUGS.FOR_SALE,
]);

export const PROFILING_RESTRICTED_STATUSES = Object.freeze([
  SLUGS.SOLD_CONFIRMED,
  SLUGS.SOLD_INVOICE_RAISED,
  SLUGS.SOLD_PURCHASE_CONFIRMED,
  SLUGS.SOLD_DOCUMENTS_REVIEWED,
  SLUGS.SOLD_INVOICE_PAID,
]);

// Includes all rejected statuses besides `customer_lost`. All these
// rejected statuses can redirect the seller to timeline without
// the need to check if the profile is confirmed or not.
export const REDIRECT_TO_TIMELINE_REJECTED_STATUSES = [
  SLUGS.UNSELLABLE,
  SLUGS.WRITE_OFF,
  SLUGS.CUSTOMER_KEEP_CAR,
  SLUGS.TOO_EXPENSIVE,
  SLUGS.CAR_GONE,
  SLUGS.DEALER_WITHDREW_BID,
  SLUGS.NOT_AS_DESCRIBED,
  SLUGS.NOT_COLLECTED_IN_TIME,
  SLUGS.OTHER_DEALER_ISSUES,
  SLUGS.OTHER_SELLER_ISSUES,
  SLUGS.UNCONTACTABLE,
  SLUGS.SOLD_ELSEWHERE,
  SLUGS.UNDER_REVIEW,
];

// Includes all statuses from QA onwards.
export const REDIRECT_TO_TIMELINE_STATUSES_POST_QA = Object.freeze([
  SLUGS.READY_FOR_SALE,
  SLUGS.SALE_ACTIVE,
  SLUGS.FOR_SALE,
  SLUGS.QA_READY,
  SLUGS.QA_ON_HOLD,
  SLUGS.QA_FAILED,
  SLUGS.UNSOLD_BELOW_RESERVE_BID,
  SLUGS.UNSOLD_NO_BIDS,
  SLUGS.UNDER_OFFER,
  SLUGS.HPI_QUERY,
]);

// Includes all statuses that will redirect the seller to timeline,
// besides `full_details` and `full_details_wait`.
export const REDIRECT_TO_TIMELINE_STATUSES = Object.freeze([
  ...REDIRECT_TO_TIMELINE_REJECTED_STATUSES,
  ...REDIRECT_TO_TIMELINE_STATUSES_POST_QA,
  SLUGS.CUSTOMER_LOST,
]);

// These statuses are related to timeline, and are required to show
// the initial timeline valuation page.
export const REDIRECT_TO_TIMELINE_STATUSES_PRE_QA = Object.freeze([
  SLUGS.FULL_DETAILS,
  SLUGS.FULL_DETAILS_WAIT,
]);

export const RESUPPLY_STATUSES = Object.freeze([
  SLUGS.FULL_DETAILS,
  SLUGS.FULL_DETAILS_WAIT,
]);

export const RECYCLE_ENQUIRY_STATES = Object.freeze([
  SLUGS.QA_READY,
  SLUGS.READY_FOR_SALE,
  SLUGS.FOR_SALE,
  SLUGS.UNDER_OFFER,
  SLUGS.SALE_ACTIVE,
  SLUGS.SOLD_INVOICE_RAISED,
  SLUGS.SOLD_INVOICE_PAID,
  SLUGS.SOLD_WAIT,
  SLUGS.SOLD_CONFIRMED,
  SLUGS.SOLD_PURCHASE_CONFIRMED,
  SLUGS.SOLD_DOCUMENTS_REVIEWED,
]);

export const INELIGIBLE_VEHICLE_CLASSES = ['HCV', 'Motorcycle'] as const;

export const isVehicleClassIneligible = (vehicleClass?: Vehicle['vehicleClass']) =>
  INELIGIBLE_VEHICLE_CLASSES.some((it) => vehicleClass?.includes(it));

export const isOfferRestrictedToModify = (slug?: Slug | null) =>
  OFFER_RESTRICTED_STATES.some((it) => slug?.includes(it));

export const isVehicleCanBeRemoved = (slug?: Slug | null) =>
  VEHICLE_REMOVABLE_STATES.some((it) => slug?.includes(it));

export const isValuationRestrictedToModify = (slug?: Slug | null) =>
  VALUATION_RESTRICTED_STATES.some((it) => slug?.includes(it));

export const isStatusTimeline = (slug?: Slug | null) =>
  REDIRECT_TO_TIMELINE_STATUSES.some((it) => slug?.includes(it));

export const isStatusPreQaTimeline = (slug?: Slug | null) =>
  REDIRECT_TO_TIMELINE_STATUSES_PRE_QA.some((it) => slug?.includes(it));

export const isStatusPostQaTimeline = (slug?: Slug | null) =>
  REDIRECT_TO_TIMELINE_STATUSES_POST_QA.some((it) => slug?.includes(it));

export const isStatusRejectedTimeline = (slug?: Slug | null) =>
  REDIRECT_TO_TIMELINE_REJECTED_STATUSES.some((it) => slug?.includes(it));

export const isResupplyAllowed = (slug?: Slug | null) =>
  RESUPPLY_STATUSES.some((it) => slug?.includes(it));

export const isProfilingRestrictedToAccess = (slug?: Slug | null) =>
  PROFILING_RESTRICTED_STATUSES.some((it) => slug?.includes(it));

export const isNotAccessible = (slug?: Slug | null, condition?: boolean) => {
  if (READ_ONLY_SLUGS.some((it) => slug?.includes(it))) {
    return true;
  }

  if (READ_ONLY_WITH_CONDITION_SLUGS.some((it) => slug?.includes(it))) {
    return !condition;
  }

  return false;
};
