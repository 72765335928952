import { GENERIC_ANALYTICS_EVENTS } from 'Utilities/analytics/analyticsGenericEvents';

export const SIGN_IN_PAGE_CATEGORY = 'sign_in_page';

export const SIGN_IN_PAGE_EVENTS = {
  EMAIL_CTA_CLICKED: (url: string) => GENERIC_ANALYTICS_EVENTS.CLICK_EVENT({
    category: SIGN_IN_PAGE_CATEGORY,
    name: 'email_link',
    url,
  }),
  EMAIL_SENT_STEP_LOADED: () => GENERIC_ANALYTICS_EVENTS.SECTION_LOAD_EVENT({
    category: SIGN_IN_PAGE_CATEGORY,
    name: 'go_to_email',
  }),
  SIGN_IN_CTA_CLICKED: ({ success }: { success: boolean }) => GENERIC_ANALYTICS_EVENTS.CTA_CLICK({
    button_label: 'Sign in',
    category: SIGN_IN_PAGE_CATEGORY,
    name: 'sign_in',
    success,
    url: SIGN_IN_PAGE_CATEGORY,
  }),
  SIGN_IN_LOADED: (isCarValueTracker: boolean) => GENERIC_ANALYTICS_EVENTS.SECTION_LOAD_EVENT({
    category: isCarValueTracker ? 'cvt' : 'smv_val',
    name: 'email_step',
  }),
};
