import {
  gatewayGet,
  gatewayPost,
} from './fetch';

// adds 'gateway' proxy to platform endpoints
export const platformPost = async (endpoint = '', payload = {}) => gatewayPost(`/platform${endpoint}`, {
  ...payload,
});

export const platformGet = async (endpoint = '', query = undefined, options = {}) => {
  const { abortController, headers, loggerLevel, ms } = options;
  return gatewayGet(`/platform${endpoint}`, query, ms, abortController, headers, loggerLevel);
};
