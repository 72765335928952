import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Content } from '@motorway/motorway-storybook-cra';

import { classNamePropType } from 'Utilities/propTypes';
import useInView from 'Utilities/useInView';

import styles from './Carousel.less';

const Carousel = ({
  children = null,
  slideInterval = [1000, 2000],
  vertical = false,
  wrapperClassName,
}) => {
  const [index, setIndex] = useState(0);
  const [mounted, setMounted] = useState();

  const $container = useRef();
  const timer = useRef();

  const visible = useInView($container, {
    rootMargin: '100px',
  });

  const animationInterval = slideInterval[(vertical) ? 0 : 1];

  const renderChildren = () => React.Children.toArray(children)
    .map((child, i) => React.cloneElement(child, {
      className: cx(child.props.className, {
        [styles.index]: i === index + 2,
        [styles.preIndex]: i === index + 1,
      }),
    }));

  const updateIndex = useCallback(() => {
    setIndex((oldIndex) => {
      const offset = (vertical) ? 14 : 8;

      if ((oldIndex + offset) === React.Children.toArray(children).length) {
        return 0;
      }

      return (oldIndex + 1);
    });
  }, [children, setIndex, vertical]);

  const triggerNewTransition = useCallback((e) => {
    e?.stopPropagation?.();

    if (e && e?.nativeEvent?.propertyName !== 'transform') {
      return;
    }

    clearTimeout(timer.current);
    timer.current = setTimeout(updateIndex, 1000);
  }, [updateIndex]);

  useEffect(() => {
    if (!mounted && visible) {
      triggerNewTransition();
      setMounted(true);
    }
  }, [mounted, triggerNewTransition, visible]);

  const calculatedTransform = (vertical)
    ? `translate3d(0, calc(((100% / ${children.length}) * ${index}) * -1), 0)`
    : `translate3d(calc(((100% / ${children.length}) * ${index + 2}) * -1), 0, 0)`;

  const finalStyle = { transform: calculatedTransform, transition: `transform ${animationInterval}ms ease-out` };

  return (
    <div className={cx(styles.overflowContainer, wrapperClassName)} style={{ '--recent-sales-carousel-items': children.length }}>
      <Content className={styles.content}>
        <div className={styles.wrapper}>
          <div
            onTransitionEnd={triggerNewTransition}
            style={(mounted && vertical) ? finalStyle : {}}
          >
            <div
              ref={$container}
              className={styles.container}
              onTransitionEnd={triggerNewTransition}
              style={(mounted && !vertical) ? finalStyle : {}}
            >
              { renderChildren() }
            </div>
          </div>
        </div>
      </Content>
    </div>
  );
};

Carousel.propTypes = {
  additionalClassNames: PropTypes.shape({
    animatingClass: classNamePropType,
    firstItemClass: classNamePropType,
    inViewClass: classNamePropType,
    lastItemClass: classNamePropType,
    notInViewClass: classNamePropType,
  }),
  children: PropTypes.node,
  slideInterval: PropTypes.arrayOf(PropTypes.number),
  vertical: PropTypes.bool,
  visibleItems: PropTypes.number,
  wrapperClassName: classNamePropType,
};

export default Carousel;
