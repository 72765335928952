import { useState } from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import { LogoMake } from '@motorway/motorway-storybook-cra';
import { ArrowDownIcon, ArrowUpIcon, Button } from '@motorway/mw-highway-code';
import { Hyperlink, VRM } from '@motorway/mw-highway-code/alpha';
import { VARIANT, WEIGHT } from '@THC/utils/enums';
import { filterDataProps } from '@THC/utils/helpers';

import { VehicleHeaderProps } from './VehicleHeader.types';
import { createGetE2EProps, createTexts, getDetailFields, getHeadingText } from './VehicleHeaderUtils';

import styles from './VehicleHeader.module.scss';

const texts = createTexts();

const getToggleIconComponent = (isExpanded: boolean) =>
  (isExpanded ? ArrowUpIcon : ArrowDownIcon);

const getToggleLabel = (isExpanded: boolean) =>
  (isExpanded ? texts.labelCollapse() : texts.labelExpand());

export const VehicleHeader = (props: VehicleHeaderProps) => {
  const [isExpanded, setExpanded] = useState(false);

  const { e2eProps, link, vehicle } = props;

  const { body, colour, enquiry, fuel, make, model, vrm_pretty: vrmPretty, year } = vehicle || {};
  const mileage = enquiry?.mileage ? `${enquiry?.mileage.toLocaleString()} miles` : undefined;

  const data = {
    body,
    colour,
    fuel,
    make: make?.display_name,
    mileage,
    model,
    year: year?.toString(),
  };

  const toggleLabel = getToggleLabel(isExpanded);
  const headingText = getHeadingText(data);
  const detailFields = getDetailFields(data);

  const ToggleIconComponent = getToggleIconComponent(isExpanded);

  const onToggleClick = () => setExpanded((current) => !current);

  const getE2EProps = createGetE2EProps(e2eProps);

  return (
    <div
      className={cx(styles.layout, styles.component, styles.animation, {
        [styles.collapsed]: !isExpanded,
        [styles.noLink]: !link,
      })}
      {...filterDataProps(props)}
    >
      <h1 className={styles.model} {...getE2EProps('heading')}>
        {headingText}
      </h1>
      <div className={styles.vrm}>
        <VRM side="front" size="small" text={vrmPretty as string} />
      </div>
      { vehicle?.make?.slug
        && <div className={styles.logo}>
          <LogoMake make={vehicle?.make?.slug} />
        </div>
      }
      <div className={styles.toggle}>
        <Button
          icon={ToggleIconComponent}
          label={toggleLabel}
          onClick={onToggleClick}
          showLabel={false}
          variant={VARIANT.TERTIARY}
        />
      </div>
      <div className={styles.detailsContainer}>
        <ol className={styles.details}>
          {detailFields.map(([key, value]) => (
            <li key={key} {...getE2EProps(key)}>{value}</li>
          ))}
        </ol>
      </div>
      {link && (
        <div className={styles.link}>
          <Hyperlink
            as={Link}
            label={link.label}
            onClick={link.onClick}
            to={link.to}
            variant={VARIANT.SECONDARY}
            weight={WEIGHT.BOLD}
          />
        </div>
      )}
    </div>
  );
};
