import React, { useEffect, useRef, useState } from 'react';
import cx from 'classnames';

import { usePrevious } from 'Utilities/hooks';

import styles from './Lock.scss';

interface LockProps {
  children?: React.ReactNode;
  className?: string;
  fadeTime?: number;
  lock?: boolean;
}

const Lock: React.FC<LockProps> = ({
  children,
  className,
  fadeTime: FADE_TIME = 950,
  lock = false,
}) => {
  const lockStatus = lock;
  const [lockDown, setLockDown] = useState<boolean>(false);

  const frozenChildren = useRef<React.ReactNode | null>(null);

  const oldLockStatus = usePrevious(lockStatus);

  useEffect(() => {
    if (!lockStatus) {
      frozenChildren.current = children;
    }
  }, [children, lockStatus]);

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (lockStatus === true && oldLockStatus === false) {
      timer = setTimeout(() => setLockDown(true), FADE_TIME);
      const activeElement = document.activeElement as HTMLElement | null;
      activeElement?.blur();
    } else if (lockStatus === false && oldLockStatus === true) {
      setLockDown(false);
    }

    return () => clearTimeout(timer);
  }, [lockStatus, oldLockStatus, FADE_TIME]);

  const processChildren = () => {
    if (lock && !lockDown) {
      return frozenChildren.current;
    } if (lock && lockDown) {
      return null;
    }

    return children;
  };

  return (
    <div className={cx(className, styles.component, { [styles.fadeOut]: lock })}>
      {processChildren()}
    </div>
  );
};

export default Lock;
