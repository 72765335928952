export const SNOWPLOW_TRIGGER = (value: string, arg: string) => {
  setTimeout(() => {
    if (arg) {
      return window?.snowplow?.(value, arg);
    }
    return window?.snowplow?.(value);
  }, 0);
};

// XXX: https://github.com/snowplow/snowplow-javascript-tracker/blob/8fa9bc58280860e5d59dedffc1bb130ca74af28c/trackers/javascript-tracker/src/in_queue.ts#L260
export const SNOWPLOW_GET_TRACKERS = (callback: () => void) => window?.snowplow?.(callback);
